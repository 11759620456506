import React from 'react'
import api from "../Api";
import { useState, useEffect } from 'react';

const Setting = () => {

    const [isTransferChecked, setIsTransferChecked] = useState(true);
    const [getSetting, setGetSetting] = useState([]);
    const [editingSetting, setEditingSetting] = useState(null);

    const handleTransferCheckboxChange = (event) => {
        setIsTransferChecked(event.target.checked);
    };


    const handleSettingSubmit = async (e) => {
        e.preventDefault();
        const transferCheckedData = {
            transferAssigned: isTransferChecked ? 1 : 0,
        };

        console.log("Submitting transferCheckedData:", transferCheckedData);

        try {
            if (editingSetting) {
                await api.put(`Setting/${editingSetting._id}`, transferCheckedData);
            } else {
                await api.post("/Setting", transferCheckedData);
            }
            // Optionally reset state or provide feedback
        } catch (error) {
            console.error("Error adding/updating transferChecked in setting:", error);
            // Optionally provide feedback on error
        }
    };



    useEffect(() => {
        const fetchSetting = async () => {
            try {
                const response = await api.get("/setting");
                //-console.log("transfer", response.data);
                setGetSetting(response.data);
                //-console.log(getSetting);
            } catch (error) {
                //-console.error("Error fetching setting:", error);
            }
        };
        fetchSetting();
    }, []);

    return (
        <React.Fragment>
            <div class="page-header">
                <h3 class="page-title">
                    <span class="page-title-icon bg-gradient-primary text-white me-2">
                        <i class="fa fa-gear"></i>
                    </span>{" "}
                    Setting
                </h3>
                <nav aria-label="breadcrumb">
                    <ul class="breadcrumb">
                        <li class="breadcrumb-item active" aria-current="page">
                            <span></span>Overview{" "}
                            <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <form className="form-inline" onSubmit={handleSettingSubmit}>
                            <div className="form-group row">
                                <label className="col-6 col-form-label" htmlFor="transferCheckbox">Auto-assign at 12:00 AM</label>
                                <div className="col-4">
                                    <div class="form-check form-check-danger">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            onChange={handleTransferCheckboxChange}
                                            checked={isTransferChecked}
                                            id="transferCheckbox"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-md-3">
                                    <button type="submit" className="btn btn-sm btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Setting;
