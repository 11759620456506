import React, { useState, useEffect, useCallback } from "react";
import api from "../Api";
import moment from "moment";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const EmployeeBased = () => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [rows, setGridRows] = useState([]);
    const [selectedReport, setSelectedReport] = useState("");
    const [getLiveMarketing, setGetLiveMarketing] = useState([]);
    const [getLeadOnline, setGetLeadOnline] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [getUser, setGetUser] = useState([]);
    const [searchEmployee, setSearchEmployee] = useState("");
    const [showClearButton, setShowClearButton] = useState(false);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
    const [getLeadCancelReason, setGetLeadCancelReason] = useState([]);
    const [selectedReason, setSelectedReason] = useState("");


    useEffect(() => {
        const fetchLeadOnline = async () => {
            try {
                const response = await api.get("/leadCreationData");
                //-console.log(response.data);
                setGetLeadOnline(response.data);
            } catch (error) {
                //-console.log(error);
            }
        };
        fetchLeadOnline();
    }, []);

    useEffect(() => {
        const fetchLeadCancelReason = async () => {
            try {
                const response = await api.get("/cancelReason");
                setGetLeadCancelReason(response.data);
            } catch (error) {
                //-console.log(error);
            }
        };
        fetchLeadCancelReason();
    }, []);



    useEffect(() => {
        const fetchLiveMarketing = async () => {
            try {
                const response = await api.get("/liveMarketing");
                setGetLiveMarketing(response.data);
                //-console.log(response.data);

            } catch (error) {
                //-console.log(error);
            }
        };
        fetchLiveMarketing()
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get("/user");
                setGetUser(response.data);
            } catch (error) {
                //-console.error("Error fetching User:", error);
            }
        };
        fetchUser();
    }, []);

    useEffect(() => {
        let temp_rows = [];

        getLiveMarketing.forEach((item, index) => {
            const quotationalHistories = item.quotationalHistories.map((product) => ({
                productType: product.productType,
                unit: product.unit,
                quantity: product.quantity,
                givenPrice: product.givenPrice,
                expectedPrice: product.expectedPrice,
            }));

            temp_rows.push(
                createData(
                    item.name?.name || "",
                    item.assignedTo?.name || "",
                    item.createdAt,
                    item.pincode,
                    quotationalHistories,
                    item
                )
            );
        });

        getLeadOnline.forEach((item, index) => {
            const quotationalHistories = item.quotationalHistories.map((product) => ({
                productType: product.productType,
                unit: product.unit,
                quantity: product.quantity,
                givenPrice: product.givenPrice,
                expectedPrice: product.expectedPrice,
            }));

            temp_rows.push(
                createData(
                    item.name?.name || "",
                    item.assignedTo?.name || "",
                    item.createdAt,
                    item.pincode,
                    quotationalHistories,
                    item
                )
            );
        });

        //-console.log("Constructed Rows:", temp_rows);
        setGridRows(temp_rows);

    }, [getLiveMarketing, getLeadOnline]);


    // Handle date changes
    const handleFromDateChange = (e) => {
        //-console.log("From date changed:", e.target.value);
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        //-console.log("To date changed:", e.target.value);
        setToDate(e.target.value);
    };

    // Handle search click
    const handleSearchClick = () => {
        //-console.log("Search clicked.");
        if (searchEmployee) {
            setSearchClicked(true);
            filterRowsByDate(fromDate, toDate);
        }
    };


    const filterRowsByDate = (fromDate, toDate) => {
        //-console.log("fromDate", fromDate);
        //-console.log("toDate", toDate);

        const filtered = rows.filter((row) => {
            const createdAt = moment(row.item.createdAt, "YYYY-MM-DD hh:mm A");
            //-console.log("createdAt", createdAt);
            const isBetween = createdAt.isBetween(
                moment(fromDate).startOf("day"),
                moment(toDate).endOf("day"),
                null,
                "[]"
            );

            return isBetween;
        });
        setFilteredRows(filtered);
    };


    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD HH:mm:ss");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };

        setDefaultFromToDates();
    }, []);

    // Handle clear filter
    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DDTHH:mm");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchEmployee("");
        setSelectedReport("FollowUp")
        setSearchClicked(false);
        filterRowsByDate(currentDateFormatted, currentDateFormatted);
    };

    useEffect(() => {
        const determineStatusFilter = () => {
            switch (selectedReport) {
                case "FollowUp":
                    return ["open", "escalation", "quotation"];
                case "Non Converted Leads":
                    return ["cancel"];
                case "Converted":
                    return ["order success"];
                default:
                    return [];
            }
        };

        const newStatusFilter = determineStatusFilter();
        setStatusFilter(newStatusFilter);
    }, [selectedReport]);

    useEffect(() => {
    }, [statusFilter]);

    const getReportFilteredRows = () => {
        if (!searchClicked) return [];

        return filteredRows.filter((row) => {

            const { leadCancelation } = row.item;
            const statusMatches = statusFilter.includes(row.item.status.leadStatus);
            const employeeMatches = selectedEmployeeId === row.item.assignedTo?._id;

            if (selectedReport === "Non Converted Leads") {
                const lastApprovalStatus =
                    leadCancelation && leadCancelation.length > 0
                        ? leadCancelation[leadCancelation.length - 1]?.status
                        : null;
                return statusMatches && employeeMatches && lastApprovalStatus === "approval";
            }
            return statusMatches && employeeMatches;
        });
    };

    const getFinalFilteredRows = () => {
        const reportFilteredRows = getReportFilteredRows();
        //-console.log("reportFilteredRows", reportFilteredRows);

        if (selectedReport === "Non Converted Leads") {
            return reportFilteredRows.filter(row => {
                if (selectedReason === "All") {
                    return true;
                }
                return row.item.cancelReason === selectedReason;
            });
        }

        return reportFilteredRows;
    };

    const renderRows = () => {
        const ReportFilteredRows = getFinalFilteredRows();
        return ReportFilteredRows.map((row) => <Row key={row.item._id} row={row} />);
    };


    const filteredEmployee = getUser.filter((employee) => {
        if (typeof searchEmployee !== "string") {
            return false;
        }

        const searchLower = searchEmployee.toLowerCase();
        const name = employee?.name?.toLowerCase() || '';
        const roleName = employee?.adminRole?.roleName?.toLowerCase() || '';
        const phoneNumber = (employee?.phoneNumber || '').toString();
        const teamName = employee?.team?.teamName?.toLowerCase() || '';

        return (
            name.includes(searchLower) ||
            roleName.includes(searchLower) ||
            phoneNumber.includes(searchLower) ||
            teamName.includes(searchLower)
        );
    });

    // Handle selection of employee
    const handleNameSelect = (employee) => {
        try {
            setSearchEmployee(
                `${employee.name} - ${employee.phoneNumber} - ${employee.team?.teamName} - ${employee.adminRole?.roleName}`
            );
            setSelectedEmployeeId(employee._id);
        } catch (error) {
            //-console.error("Error selecting employee name:", error.message);
        }
    }

    useEffect(() => {
        if (selectedEmployeeId) {
            //-console.log("employeeId", selectedEmployeeId);
        }
    }, [selectedEmployeeId]);


    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow
                    sx={{ "& > *": { borderBottom: "unset" } }}
                >
                    <TableCell component="th" scope="row">
                        {row.item && row.item.name ? row.item.name.customerCode : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.cus_name}
                    </TableCell>
                    <TableCell>
                        {row.item && row.item.leadSource ? row.item.leadSource.leadSource : "-"}
                    </TableCell>
                    <TableCell>{row.item && row.item.assignedTo ? row.item.assignedTo.name : "-"}</TableCell>
                    <TableCell>{row.lead_date}</TableCell>
                    <TableCell>{row.pincode}</TableCell>
                    <TableCell>{row.item.area}</TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Employee Based Statistics
                </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                            <span></span>Overview{" "}
                            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* Table */}
            <div className="row">
                {/* From Date */}
                <div className="col-md-4">
                    <label>From Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div className="col-md-4">
                    <label>To Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>
                <div className="col-md-4 mb-2">
                    <div className="form-group">
                        {/* Form */}
                        <label htmlFor="inlineFormInputName2">Status Category</label>
                        <select
                            className="form-select form-control"
                            value={selectedReport}
                            onChange={(e) => setSelectedReport(e.target.value)}
                            required
                        >
                            <option selected value="FollowUp">FollowUp</option>
                            <option value="Non Converted Leads">Non Converted Leads</option>
                            <option value="Converted">Converted Leads</option>
                        </select>
                    </div>
                </div>
                {selectedReport === "Non Converted Leads" && (
                    <div className="col-md-3 mb-2">
                        <div className="form-group">
                            {/* Form */}
                            <label htmlFor="inlineFormInputName2">Cancel reason</label>
                            <select
                                className="form-select form-control"
                                value={selectedReason}
                                onChange={(e) => setSelectedReason(e.target.value)}
                                required
                            >
                                <option selected value="All">All</option>
                                {getLeadCancelReason[0]?.cancelReason.map((reason, index) => (
                                    <option key={index} value={reason}>
                                        {reason}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                {/* search Employee name */}
                <div className="col-md-4">
                    <div className="d-flex gap-2">
                        <div className="form-group w-100">
                            <label>Employee Name</label>
                            <input
                                type="text"
                                placeholder="Search a employee name"
                                value={searchEmployee}
                                onChange={(e) => setSearchEmployee(e.target.value)}
                                className="form-control mb-2"
                                onClick={() => setShowClearButton(true)}
                                required
                            />
                            {searchEmployee && filteredEmployee.length > 0 && (
                                <div className="form-group">
                                    <ul>
                                        {filteredEmployee.map((employee) => (
                                            <li
                                                key={employee._id}
                                                onClick={() => handleNameSelect(employee)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {`${employee.name} - ${employee.phoneNumber} - ${employee.team?.teamName} - ${employee.adminRole?.roleName}`}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mt-2">
                    <button
                        className="btn btn-primary mt-3"
                        onClick={handleSearchClick}
                    >
                        Search Date
                    </button>
                    {searchClicked && (
                        <button
                            onClick={handleClearFilter}
                            className="btn btn-sm badge-gradient-danger mt-3"
                            aria-label="Close"
                            style={{ marginLeft: '10px' }}
                        >
                            &times;
                        </button>
                    )}
                </div>

                {/* Table display */}
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Customer Code</TableCell>
                                <TableCell>Customer Name</TableCell>
                                <TableCell>Lead Source</TableCell>
                                <TableCell>Assigned To</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Pincode</TableCell>
                                <TableCell>City</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderRows()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </React.Fragment>
    );
};

function createData(
    cus_name,
    lead_source,
    lead_date,
    pincode,
    quotationalHistories,
    item
) {
    return {
        cus_name,
        lead_source,
        lead_date,
        pincode,
        quotationalHistories,
        item,
    };
}

export default EmployeeBased;
