import React, { useState, useEffect, useRef } from "react";

import api from "../Api";
import "../MediaQuery.css";
import "react-datepicker/dist/react-datepicker.css";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loader from './Loader';



const ProductCategory = ({ adminId, adminName }) => {

  const [ProductImageSource, setProductImageSource] = useState("");
  const [getImageFile, setImageFile] = useState(null);
  const [createProductCategory, setCreateProductCategory] = useState("");
  const [getProductCategory, setGetProductCategory] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState("");
  const [createProduct, setCreateProduct] = useState("");
  const [termsAndCondition, setTermsAndCondition] = useState("");
  const [getCreateProduct, setGetProduct] = useState([]);
  const [getEntireProduct, setGetEntireProduct] = useState([]);
  const [createProductTypes, setCreateProductTypes] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [getBrand, setGetBrand] = useState([]);
  const [getEntireBrandList, setGetEntireBrandList] = useState([]);
  const [getProductTypesBrand, setGetProductTypesBrand] = useState([]);
  const [selectedProductTypesBrand, setSelectedProductTypesBrand] = useState("");
  const [getProductTypes, setGetProductTypes] = useState([]);
  const [productDescription, setProductDescription] = useState("");
  const [productCode, setProductCode] = useState("");
  const [selectedGst, setSelectedGst] = useState("");
  const [brand, setBrand] = useState("");
  const [tabValue, setTabValue] = useState('1');
  const [showProductCategoryForm, setShowProductCategoryForm] = useState(false);
  const [showProductForm, setShowProductForm] = useState(false);
  const [showBrandForm, setShowBrandForm] = useState(false);
  const [showProductTypesForm, setShowProductTypesForm] = useState(false);
  const [editingProductCategory, setEditingProductCategory] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  const [editingBrand, setEditingBrand] = useState(null);
  const [editingProductTypes, setEditingProductTypes] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [getUser, setGetUser] = useState([]);
  const [getAdminRole, setGetAdminRole] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [showUnitForm, setShowUnitForm] = useState(false);
  const [unit, setUnit] = useState('');
  const [getUnit, setGetUnit] = useState([]);
  const [editingUnit, setEditingUnit] = useState(null);


  const handleUnit = (e) => {
    setUnit(e.target.value);
  };


  const handleSubmitUnit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (editingUnit) {
        const response = await api.put(
          `/unit/${editingUnit._id}`,
          { unit }
        );
        //(response.data);
        setEditingUnit(null);
      } else {
        const response = await api.post('/unit', {
          unit
        });
        //(response.data);
      }
      //("user", response.data);
      fetchUnits();
      setIsLoading(false);
      setUnit('');
      setShowUnitForm(false);
      setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
    } catch (error) {
      //(error);
      setShowAlert({ visible: true, message: 'Error occurred while submit unit', type: 'danger' });
    }
  };

  const handleDeleteUnit = async (e) => {
    e.preventDefault();

    // Confirm TableCelle deletion action
    if (!window.confirm("Are you sure you want to delete TableCellis entry?")) {
      return;
    }

    try {
      setIsLoading(true);

      const response = await api.delete(`/unit/${editingUnit._id}`);

      if (response.status === 200) {
        setShowUnitForm(false);
        fetchUnits();
        setIsLoading(false);
        setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
      } else {
        //-console.error("Failed to delete TableCelle item. Server returned status:", response.status);

      }
    } catch (error) {
      setShowAlert({ visible: true, message: 'Error occurred while deleting unit', type: 'danger' });
    } finally {
      setIsLoading(false);
    }
  };


  const fetchUnits = async () => {
    try {
      const response = await api.get('/unit');
      setGetUnit(response.data);
    } catch (error) {
      //(error);
    }
  };

  useEffect(() => {
    fetchUnits();
  }, []);


  const fetchUser = async () => {
    try {
      const response = await api.get("/user");
      setGetUser(response.data);
    } catch (error) {
      //-console.error("Error fetching User:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchAdminRole = async () => {
      try {
        const response = await api.get("/admin");
        setGetAdminRole(response.data);
      } catch (error) {
        //-console.error("Error fetching admin roles:", error);
      }
    };

    fetchAdminRole();
  }, []);


  useEffect(() => {

    const getUserRole = (adminId) => {
      //('Searching for user wiTableCell adminId:', adminId);
      const user = getUser.find((user) => user._id === adminId);
      //-console.log('User found:', user);

      if (user) {
        //('Searching for role wiTableCell roleId:', user.adminRole._id);
        const loginuserRole = getAdminRole.find((role) => role._id === user.adminRole._id);
        //-console.log('Role found:', loginuserRole);
        setUserRole(loginuserRole);
        return loginuserRole?.roleName || null;
      } else {
        //-console.log('No user found wiTableCell TableCelle given adminId');
        return null;
      }
    };
    const role = getUserRole(adminId);

  }, [adminId, getUser, getAdminRole]);

  const handleProductCategory = (e) => {
    setCreateProductCategory(e.target.value);
  };


  const handleproduct = (e) => {
    setCreateProduct(e.target.value);
  };
  const handleTermsAndCondition = (e) => {
    setTermsAndCondition(e.target.value);
  };
  const handleProductTypes = (e) => {
    setCreateProductTypes(e.target.value);
  };

  const handleCreateProductCategory = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (editingProductCategory) {
        const response = await api.put(`/productCategory/${editingProductCategory._id}`, {
          productCategory: createProductCategory,
        });
        //-console.log(response.data);
        setEditingProductCategory(null);
      } else {
        const response = await api.post("/productCategory", {
          productCategory: createProductCategory,
        });
        setGetProductCategory([...getProductCategory, response.data]);
        //-console.log(response.data);
      }
      fetchProductCategory();
      setCreateProductCategory("");
      setShowProductCategoryForm(false);
      setIsLoading(false);
      setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
    } catch (error) {
      //-console.log("Error adding create productCategory / updating productCategory :", error);
      setShowAlert({ visible: true, message: 'Error occurred while deleting product category', type: 'danger' });
    }
  };

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (editingProduct) {
        const response = await api.put(`/product/${editingProduct._id}`, {
          productCategory: selectedProductCategory,
          product: createProduct,
          termsAndCondition
        });
        //-console.log(response.data);
        setEditingProduct(null);
      } else {
        const response = await api.post("/product", {
          productCategory: selectedProductCategory,
          product: createProduct,
          termsAndCondition
        });
        setGetProduct([...getCreateProduct, response.data]);
        //-console.log(response.data);
      }
      fetchEntireProducts();
      setCreateProduct("");
      setShowProductForm(false);
      setIsLoading(false);
      setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
    } catch (error) {
      //-console.log("Error adding create product / updating product :", error);
      setShowAlert({ visible: true, message: 'Error occurred while submitting product. Please try again.', type: 'danger' });
    }
  };


  const handleCreateBrandChange = (e) => {
    setBrand(e.target.value);
  };

  const handleSubmitCreateBrand = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (editingBrand) {
        const response = await api.put(`/createBrand/${editingBrand._id}`, {
          productCategory: selectedProductCategory,
          brand: brand,
        });
        //-console.log(response.data);
        setEditingBrand(null);
      } else {
        const response = await api.post("/createBrand", {
          productCategory: selectedProductCategory,
          brand: brand,
        });
        //-console.log(response.data);
      }
      fetchCreateBrandList();
      setBrand("");
      setShowBrandForm(false);
      setIsLoading(false);
      setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
    } catch (error) {
      //-console.log("Error adding create brand / updating brand :", error);
      setShowAlert({ visible: true, message: 'Error occurred while submitting brand. Please try again.', type: 'danger' });
    }
  };


  const handleCreateProductTypes = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("productCategory", selectedProductCategory);
    formData.append("product", selectedProduct);
    formData.append("productTypes", createProductTypes);
    formData.append("brand", selectedProductTypesBrand);
    formData.append("productCode", productCode);
    formData.append("productGST", selectedGst);
    formData.append("productDescription", productDescription);

    if (getImageFile) {
      formData.append("productImage", getImageFile.name);
      formData.append("file", getImageFile);
    }

    try {
      let response;
      if (editingProductTypes) {
        // If editing an existing product type, use PUT request
        response = await api.put(`/productTypes/${editingProductTypes._id}`, formData);
        //-console.log("Product type updated successfully:", response.data);
      } else {
        // Otherwise, use POST request to create a new product type
        //-console.log("formData", formData);
        response = await api.post("/productTypes", formData);
        //-console.log("Product type created successfully:", response.data);
      }

      // Clear form fields or reset state variables
      setCreateProductTypes("");
      setProductDescription("");
      setProductCode("");
      setSelectedGst("");
      setEditingProductTypes(null);
      setShowProductTypesForm(false);
      fetchProductCategory();
      fetchProductTypes();
      fetchCreateBrandList();
      setIsLoading(false);
      setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });

    } catch (error) {
      //-console.error("Error handling product types:", error);
      setShowAlert({ visible: true, message: 'Error occurred while submitting productTypes. Please try again.', type: 'danger' });
    }
  };

  const fetchProductCategory = async () => {
    try {
      const response = await api.get("/productCategory");
      setGetProductCategory(response.data);
    } catch (error) {
      //-console.error("Error fetching product categories:", error);
    }
  };


  useEffect(() => {
    const fetchProducts = async () => {
      if (selectedProductCategory !== "") {
        try {
          const response = await api.get(`/product/${selectedProductCategory}`);
          setGetProduct(response.data);
        } catch (error) {
          //-console.error("Error fetching products:", error);
        }
      }
    };

    if (selectedProductCategory) {
      fetchProducts();
    } else {
      setGetProduct([]);
    }
  }, [selectedProductCategory]);


  useEffect(() => {
    const fetchBrand = async () => {
      if (selectedProductCategory !== "") {
        try {
          const response = await api.get(`/createBrand/${selectedProductCategory}`);
          setGetBrand(response.data);
        } catch (error) {
          //-console.error("Error fetching brand:", error);
        }
      }
    };

    if (selectedProductCategory) {
      fetchBrand();
    } else {
      setGetBrand([]);
    }
  }, [selectedProductCategory]);

  useEffect(() => {
    const fetchProductTypesBrand = async () => {
      if (selectedProductCategory !== "") {
        try {
          const response = await api.get(`/productTypeBrand/${selectedProductCategory}`);
          //-console.log("brand", response.data);
          setGetProductTypesBrand(response.data);
        } catch (error) {
          //-console.error("Error fetching brand:", error);
        }
      }
    };

    if (selectedProductCategory) {
      fetchProductTypesBrand();
    } else {

    }
  }, [selectedProductCategory]);



  const fetchEntireProducts = async () => {

    try {
      const response = await api.get("/product");
      setGetEntireProduct(response.data);
    } catch (error) {
      //-console.error("Error fetching entire products:", error);
    }

  };


  const fetchProductTypes = async () => {
    try {
      const response = await api.get("/productTypes");
      setGetProductTypes(response.data);
    } catch (error) {
      //-console.error("Error fetching product types:", error);
    }
  };

  const fetchCreateBrandList = async () => {
    try {
      const response = await api.get("/createBrand");
      setGetEntireBrandList(response.data);
    } catch (error) {
      //("Error fetching create Brand list:", error);
    }
  };

  useEffect(() => {
    // Fetch product categories from TableCelle server
    fetchProductCategory();
    fetchProductTypes();
    fetchEntireProducts();
    fetchCreateBrandList();
  }, []);


  // delete 
  const handleDeleteProductCategory = async (e) => {
    e.preventDefault();

    // Confirm TableCelle deletion action
    if (!window.confirm("Are you sure you want to delete TableCellis entry?")) {
      return;
    }

    try {
      setIsLoading(true);

      const response = await api.delete(`/productCategory/${editingProductCategory._id}`);

      if (response.status === 200) {
        setShowProductCategoryForm(false);
        fetchProductCategory();
        setIsLoading(false);
        setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
      } else {
        //-console.error("Failed to delete TableCelle item. Server returned status:", response.status);

      }
    } catch (error) {
      setShowAlert({ visible: true, message: 'Error occurred while deleting services', type: 'danger' });
    } finally {
      setIsLoading(false);
    }
  };


  const handleDeleteBrand = async (e) => {
    e.preventDefault();

    // Confirm TableCelle deletion action
    if (!window.confirm("Are you sure you want to delete TableCellis entry?")) {
      return;
    }

    try {
      setIsLoading(true);

      const response = await api.delete(`/createBrand/${editingBrand._id}`);

      if (response.status === 200) {
        setShowBrandForm(false);
        fetchCreateBrandList();
        setIsLoading(false);
        setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
      } else {
        //-console.error("Failed to delete TableCelle item. Server returned status:", response.status);

      }
    } catch (error) {
      setShowAlert({ visible: true, message: 'Error occurred while deleting services', type: 'danger' });
    } finally {
      setIsLoading(false);
    }
  };


  const handleDeleteProduct = async (e) => {
    e.preventDefault();

    // Confirm TableCelle deletion action
    if (!window.confirm("Are you sure you want to delete TableCellis entry?")) {
      return;
    }

    try {
      setIsLoading(true);

      const response = await api.delete(`/product/${editingProduct._id}`);

      if (response.status === 200) {
        setShowProductForm(false);
        fetchEntireProducts();
        setIsLoading(false);
        setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
      } else {
        //-console.error("Failed to delete TableCelle item. Server returned status:", response.status);

      }
    } catch (error) {
      setShowAlert({ visible: true, message: 'Error occurred while deleting services', type: 'danger' });
    }
  };

  const handleDeleteProductTypes = async (e) => {
    e.preventDefault();

    // Confirm TableCelle deletion action
    if (!window.confirm("Are you sure you want to delete TableCellis entry?")) {
      return;
    }

    try {
      setIsLoading(true);

      const response = await api.delete(`/productTypes/${editingProductTypes._id}`);

      if (response.status === 200) {
        setShowProductTypesForm(false);
        fetchProductCategory();
        fetchProductTypes();
        fetchCreateBrandList();
        setIsLoading(false);
        setShowAlert(true);
      } else {
        //-console.error("Failed to delete TableCelle item. Server returned status:", response.status);

      }
    } catch (error) {
      //-console.error("Error deleting item:", error);
    } finally {
      setIsLoading(false);
    }
  };


  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };


  const toggleProductCategoryForm = () => {
    setShowProductCategoryForm(!showProductCategoryForm);
    setSelectedProductCategory(" ");
    setEditingProductCategory(null);
  };

  const toggleProductForm = () => {
    setShowProductForm(!showProductForm);
    setSelectedProductCategory(" ");
    setEditingProduct(null);
  };


  const toggleBrandForm = () => {
    setShowBrandForm(!showBrandForm);
    setEditingBrand(null);
  };


  const toggleProductTypesForm = () => {
    setShowProductTypesForm(!showProductTypesForm);
    setSelectedProductCategory(" ");
    setEditingProductTypes(null);
  };


  const toggleUnitForm = () => {
    setShowUnitForm(!showUnitForm);
    setEditingUnit(null);
    setUnit('');
  };


  const handleEditingBrand = (brand) => {
    //-console.log("brand", brand);

    setSelectedProductCategory(brand.productCategory ? brand.productCategory._id : "");
    setEditingBrand(brand);
    setBrand(brand.brand);
    setShowBrandForm(true)
  };

  const handleEditingUnit = (unit) => {
    setEditingUnit(unit);
    setUnit(unit.unit);
    setShowUnitForm(true);
  };

  const handleEditingProductCategory = (productCategory) => {
    setEditingProductCategory(productCategory);
    setCreateProductCategory(productCategory.productCategory)
    setShowProductCategoryForm(true);
  };

  const handleEditingProduct = (product) => {
    //-console.log("product", product);
    setSelectedProductCategory(product.productCategory ? product.productCategory._id : "");
    setTermsAndCondition(product.termsAndCondition);
    setCreateProduct(product.product);
    setEditingProduct(product);
    setShowProductForm(true);
  };

  const handleEditingProductTypes = (productTypes) => {
    //-console.log("productTypes", productTypes);
    setShowProductTypesForm(true);
    setEditingProductTypes(productTypes);
    setProductCode(productTypes.productCode);
    setProductDescription(productTypes.productDescription);
    setCreateProductTypes(productTypes.productTypes);
    setSelectedGst(productTypes.productGST);
    setSelectedProduct(productTypes.product ? productTypes.product._id : "")
    setSelectedProductCategory(productTypes.brand ? productTypes.brand.productCategory : "");
  }


  return (
    <React.Fragment>
      {/* header */}
      <div class="page-header">
        <h3 className="page-title d-block">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i class="fa fa-automobile"></i>
          </span>{" "}
          Product Category
        </h3>
        <nav aria-label="breadcrumb">
          <ul class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
              <span></span>Overview{" "}
              <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
            </li>
          </ul>
        </nav>
      </div>

      {/* off canvas - product category  */}
      <div
        className={`offcanvas offcanvas-end ${showProductCategoryForm ? "show" : ""}`}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            Product Category Form
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            onClick={toggleProductCategoryForm}
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body  p-4">
          <form
            className="form-inline"
            onSubmit={handleCreateProductCategory}
            style={{
              display: showProductCategoryForm || editingProductCategory ? "block" : "none",
            }}
          >
            <div className="form-group">
              <h4 className="card-title">Product Category</h4>
              <label className="sr-only" htmlFor="productCategoryInput">
                Product Category
              </label>
              <input
                type="text"
                className="form-control mb-2"
                id="productCategoryInput"
                placeholder="Enter Product Category"
                value={createProductCategory}
                onChange={handleProductCategory}
              />
            </div>

            <Loader isLoading={isLoading} />

            <div className="d-flex justify-content-between mt-3">
              {userRole && userRole.pageList.find(page => page.pageName === "Product Category")?.access[2] === 1 && (
                <button type="submit" className="btn btn-primary">
                  {editingProductCategory ? "Update Product Category" : "Add Product Category"}
                </button>
              )}

              {userRole?.pageList.some(page => page.pageName === "Product Category" && page.access[3] === 1) && editingProductCategory && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteProductCategory}
                >
                  Delete
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      {showAlert.visible && (
        <div
          className={`alert alert-${showAlert.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1050
          }}
        >
          <strong>{showAlert.message}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert({ ...showAlert, visible: false })}
          ></button>
        </div>
      )}

      {/* off canvas - product  */}
      <div
        className={`offcanvas offcanvas-end ${showProductForm ? "show" : ""}`}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            Product Form
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            onClick={toggleProductForm}
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body p-4">
          <form
            className="form"
            onSubmit={handleCreateProduct}
          >
            <div className="row">
              <div className="col-md-5 mb-3">
                <label className="form-label" htmlFor="productCategory">
                  Product Category
                </label>
                <select
                  className="form-select"
                  id="productCategory"
                  value={selectedProductCategory}
                  onChange={(e) => setSelectedProductCategory(e.target.value)}
                >
                  <option value="">Select</option>
                  {getProductCategory.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.productCategory}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-5 mb-3">
                <h4 className="card-title">Product</h4>
                <label className="form-label sr-only" htmlFor="inlineFormInputName2">
                  Product
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inlineFormInputName2"
                  placeholder="Enter Product"
                  value={createProduct}
                  onChange={handleproduct}
                />
              </div>
              <div className="col-md-5 mb-3">
                <h4 className="card-title"> Terms & Conditons</h4>
                <label className="form-label sr-only" htmlFor="inlineFormInputName2">
                  Terms & Conditons
                </label>
                <textarea
                  id="address"
                  className="form-control"
                  rows="3"
                  placeholder="Enter Terms & Conditons"
                  value={termsAndCondition}
                  onChange={handleTermsAndCondition}
                ></textarea>
              </div>
            </div>
            <Loader isLoading={isLoading} />

            <div className="d-flex justify-content-between mt-3">
              {userRole && userRole.pageList.find(page => page.pageName === "Product Category")?.access[2] === 1 && (
                <button type="submit" className="btn btn-primary">
                  {editingProduct ? "Update Product" : "Add Product"}
                </button>
              )}
              {userRole?.pageList.some(page => page.pageName === "Product Category" && page.access[3] === 1) && editingProduct && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteProduct}
                >
                  Delete
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      {showAlert.visible && (
        <div
          className={`alert alert-${showAlert.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1050
          }}
        >
          <strong>{showAlert.message}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert({ ...showAlert, visible: false })}
          ></button>
        </div>
      )}

      {/* off canvas - Product Types  */}
      <div
        className={`offcanvas offcanvas-end ${showProductTypesForm ? "show" : ""}`}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            Product Types Form
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            onClick={toggleProductTypesForm}
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body p-4">
          <form
            className="form"
            onSubmit={handleCreateProductTypes}
            style={{ display: showProductTypesForm || editingProductTypes ? "block" : "none" }}
          >
            <div className="row mb-3">
              <div className="col-4">
                <label className="h4" htmlFor="productCategory">
                  Product Category
                </label>
                <select
                  className="form-select"
                  id="productCategory"
                  value={selectedProductCategory}
                  onChange={(e) => setSelectedProductCategory(e.target.value)}
                >
                  <option value="">Select</option>
                  {getProductCategory.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.productCategory}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-4 mb-3">
                <label className="h4" htmlFor="product">
                  Product
                </label>
                <select
                  className="form-select"
                  id="product"
                  value={selectedProduct}
                  onChange={(e) => setSelectedProduct(e.target.value)}
                >
                  <option value="">Select</option>
                  {getCreateProduct.map((product) => (
                    <option key={product._id} value={product._id}>
                      {product.product}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-4 mb-3">
                <label className="h4" htmlFor="productTypes">
                  Product Types
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="productTypes"
                  placeholder="Enter Product Types"
                  value={createProductTypes}
                  onChange={handleProductTypes}
                />
              </div>
              <div className="col-4 mb-3">
                <label className="h4" htmlFor="brand">
                  Brand
                </label>
                <select
                  className="form-select"
                  id="brand"
                  value={selectedProductTypesBrand}
                  onChange={(e) => setSelectedProductTypesBrand(e.target.value)}
                >
                  <option value="">Select</option>
                  {getProductTypesBrand.map((brand) => (
                    <option key={brand._id} value={brand._id}>
                      {brand.brand}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-4 mb-3">
                <label className="h4" htmlFor="productImage">
                  Product Image
                </label>
                {!ProductImageSource && (
                  <input
                    className="form-control"
                    type="file"
                    accept="image/*"
                    id="productImage"
                    onChange={(e) => setImageFile(e.target.files[0])}
                  />
                )}
              </div>
              <div className="col-4 mb-3">
                <label className="h4" htmlFor="productGst">
                  Product GST
                </label>
                <select
                  className="form-select"
                  id="productGst"
                  value={selectedGst}
                  onChange={(e) => setSelectedGst(e.target.value)}
                >
                  <option value="">Select GST</option>
                  <option value="0">0</option>
                  <option value="5">5</option>
                  <option value="12">12</option>
                  <option value="18">18</option>
                  <option value="28">28</option>
                </select>
              </div>
              <div className="col-4 mb-3">
                <label className="h4" htmlFor="productCode">
                  Product Code
                </label>
                <input
                  className="form-control"
                  id="productCode"
                  placeholder="Enter a code"
                  value={productCode}
                  onChange={(e) => setProductCode(e.target.value)}
                />
              </div>
              <div className="col-12 mb-3">
                <label className="h4" htmlFor="productDescription">
                  Product Description
                </label>
                <textarea
                  className="form-control"
                  id="productDescription"
                  placeholder="Enter a description"
                  rows="3"
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <Loader isLoading={isLoading} />
            <div className="d-flex justify-content-between mt-4">
              {userRole && userRole.pageList.find(page => page.pageName === "Product Category")?.access[2] === 1 && (
                <button type="submit" className="btn btn-primary">
                  {editingProductTypes ? "Update Product Types" : "Add Product Types"}
                </button>
              )}
              {userRole?.pageList.some(page => page.pageName === "Product Category" && page.access[3] === 1) && editingProductTypes && (
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDeleteProductTypes}
                >
                  Delete
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
      {showAlert.visible && (
        <div
          className={`alert alert-${showAlert.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1050
          }}
        >
          <strong>{showAlert.message}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert({ ...showAlert, visible: false })}
          ></button>
        </div>
      )}
      {/* off canvas brand */}
      <div
        className={`offcanvas offcanvas-end w-50 ${showBrandForm ? "show" : ""}`}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            {editingBrand ? "Edit Brand" : "Create Brand"}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            onClick={toggleBrandForm}
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body  p-4">
          <form
            onSubmit={handleSubmitCreateBrand}
            style={{
              display: showBrandForm || (editingBrand ? "block" : "none"),
            }}
          >
            <div className="row">
              <div className="col-md-5 mb-3">
                <label className="form-label" htmlFor="productCategory">
                  Product Category
                </label>
                <select
                  className="form-select"
                  id="productCategory"
                  value={selectedProductCategory}
                  onChange={(e) => setSelectedProductCategory(e.target.value)}
                >
                  <option value="">Select</option>
                  {getProductCategory.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.productCategory}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 mb-3">
                <label htmlFor="brand" className="form-label">
                  Brand Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="brand"
                  value={brand}
                  onChange={handleCreateBrandChange}
                />
              </div>
            </div>
            <div className="row text-start w-75">
              <div className="col-md-3 text-start">
                {userRole && userRole.pageList.find(page => page.pageName === "Product Category")?.access[2] === 1 && (
                  <button type="submit" className="btn btn-sm btn-primary">
                    {" "}
                    {editingBrand ? "Update Brand" : "Add Brand"}
                  </button>
                )}
              </div>
              <div className="col-md-3 align-items-center">
                {userRole?.pageList.some(page => page.pageName === "Product Category" && page.access[3] === 1) && editingBrand && (
                  <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    onClick={handleDeleteBrand}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
      {showAlert.visible && (
        <div
          className={`alert alert-${showAlert.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1050
          }}
        >
          <strong>{showAlert.message}</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => setShowAlert({ ...showAlert, visible: false })}
          ></button>
        </div>
      )}

      {/* off canvas unit */}
      <div
        className={`offcanvas offcanvas-end w-50 ${showUnitForm ? 'show' : ''}`}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            {editingUnit ? 'Edit Unit' : 'Create Unit'}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            onClick={toggleUnitForm}
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body  p-4">
          <form
            onSubmit={handleSubmitUnit}
            style={{
              display: showUnitForm || (editingUnit ? "block" : "none"),
            }}
          >
            <div className="mb-3">
              <label htmlFor="unit" className="form-label">
                Unit Name
              </label>
              <input
                type="text"
                className="form-control"
                id="unit"
                value={unit}
                onChange={handleUnit}
              />
            </div>

            <Loader isLoading={isLoading} />
            <div className="row text-start w-75">
              <div className="col-md-3 text-start">
                {userRole && userRole.pageList.find(page => page.pageName === "Product Category")?.access[2] === 1 && (
                  <button type="submit" className="btn btn-sm btn-primary">
                    {" "}
                    {editingUnit ? 'Update Unit' : 'Add Unit'}
                  </button>
                )}
              </div>
              <div className="col-md-3 align-items-center">
                {userRole?.pageList.some(page => page.pageName === "Product Category" && page.access[3] === 1) && editingUnit && (
                  <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    onClick={handleDeleteUnit}
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          </form>
          {showAlert.visible && (
            <div
              className={`alert alert-${showAlert.type} alert-dismissible fade show`}
              role="alert"
              style={{
                position: 'fixed',
                bottom: '20px',
                right: '20px',
                zIndex: 1050
              }}
            >
              <strong>{showAlert.message}</strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => setShowAlert({ ...showAlert, visible: false })}
              ></button>
            </div>
          )}
        </div>
      </div>

      {/* Tab */}
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabValue}>
          <Box>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label="Unit" value="1" />
              <Tab label="Product Category" value="2" />
              <Tab label="Brand" value="3" />
              <Tab label="Product" value="4" />
              <Tab label="Product Types" value="5" />

            </TabList>
          </Box>

          {/* unit */}
          <TabPanel value="1">
            <div className="container">
              <div className="col-12">
                {userRole && userRole.pageList.find(page => page.pageName === 'Product Category').access[1] === 1 && (
                  <button
                    onClick={toggleUnitForm}
                    className="btn btn-gradient-primary mb-2"
                    type="button"
                  >
                    Create Unit
                  </button>
                )}
              </div>
              {/* Table */}
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Number</TableCell>
                      <TableCell>Unit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getUnit.map((unit, index) => (

                      <TableRow
                        key={index}
                        onDoubleClick={() => handleEditingUnit(unit)}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{unit.unit}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>

          <TabPanel value="2">
            <div className="container">
              <div className="col-12">
                {userRole && userRole.pageList.find(page => page.pageName === 'Product Category').access[1] === 1 && (
                  <button
                    onClick={toggleProductCategoryForm}
                    className="btn btn-gradient-primary mb-2"
                    type="button"
                  >
                    Create Product Category
                  </button>
                )}
              </div>
              {/* Table */}
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Number</TableCell>
                      <TableCell>Product Category</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getProductCategory.map((productCategory, index) => (

                      <TableRow
                        key={index}
                        onDoubleClick={() => handleEditingProductCategory(productCategory)}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{productCategory.productCategory}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>

          <TabPanel value="3">
            <div className="container">
              <div className="col-12">
                {userRole && userRole.pageList.find(page => page.pageName === 'Product Category').access[1] === 1 && (
                  <button
                    onClick={toggleBrandForm}
                    className="btn btn-gradient-primary mb-2"
                    type="button"
                  >
                    Create Brand
                  </button>
                )}
              </div>
              {/* Table */}
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Number</TableCell>
                      <TableCell>Product Category</TableCell>
                      <TableCell>Brand</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getEntireBrandList.map((brand, index) => (

                      <TableRow
                        key={index}
                        onDoubleClick={() => handleEditingBrand(brand)}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{brand.productCategory?.productCategory || 'N/A'}</TableCell>
                        <TableCell>{brand.brand}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>

          <TabPanel value="4">
            <div className="container">
              <div className="col-12">
                {userRole && userRole.pageList.find(page => page.pageName === 'Product Category').access[1] === 1 && (
                  <button
                    onClick={toggleProductForm}
                    className="btn btn-gradient-primary mb-2"
                    type="button"
                  >
                    Create Product
                  </button>
                )}
              </div>
              {/* Table */}
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Number</TableCell>
                      <TableCell>Product Category</TableCell>
                      <TableCell>Product</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getEntireProduct.map((product, index) => (

                      <TableRow
                        key={index}
                        onDoubleClick={() => handleEditingProduct(product)}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{product.productCategory?.productCategory || 'N/A'}</TableCell>
                        <TableCell>{product.product}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>

          <TabPanel value="5">
            <div className="container">
              <div className="col-12">
                {userRole && userRole.pageList.find(page => page.pageName === 'Product Category').access[1] === 1 && (
                  <button
                    onClick={toggleProductTypesForm}
                    className="btn btn-gradient-primary mb-2"
                    type="button"
                  >
                    Create Product Types
                  </button>
                )}
              </div>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Number</TableCell>
                      <TableCell>Product Code</TableCell>
                      <TableCell>Product GST</TableCell>
                      <TableCell>Product Category</TableCell>
                      <TableCell>Product</TableCell>
                      <TableCell>Product Types</TableCell>
                      <TableCell>Brand</TableCell>
                      <TableCell>Product Description</TableCell>
                      <TableCell>Product Image</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getProductTypes.map((item, index) => {
                      // Find the product categorys
                      const productCategoryValue = getProductCategory.find(
                        (productCat) => item.product?.productCategory === productCat._id
                      );
                      //-console.log("productCategory", productCategoryValue);

                      return (
                        <TableRow
                          key={index}
                          onDoubleClick={() => handleEditingProductTypes(item)}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{item.productCode}</TableCell>
                          <TableCell>{item.productGST} %</TableCell>
                          <TableCell>
                            {productCategoryValue?.productCategory || "-"}
                          </TableCell>
                          <TableCell>
                            {item.product?.product || "-"}
                          </TableCell>
                          <TableCell>{item.productTypes}</TableCell>
                          <TableCell>{item.brand?.brand || "-"}</TableCell>
                          <TableCell>
                            <pre>
                              {item.productDescription || "-"}
                            </pre>
                          </TableCell>
                          <TableCell>
                            {item.productImage ? (
                              <img
                                src={`${process.env.REACT_APP_API_SERVER}/${item.productImage}`}
                                width="130px"
                                height="130px"
                                alt="Product"
                                className="d-block m-3 ms-0"
                              />
                            ) : (
                              <span>No Image</span>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </React.Fragment >
  );
};
export default ProductCategory;
