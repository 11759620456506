import React, { useState, useEffect } from 'react';
import api from "../Api";
import Loader from './Loader';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';

const Team = ({ adminId }) => {
  const [teamName, setTeamName] = useState("");
  const [teams, setTeams] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editingTeam, setEditingTeam] = useState(null);
  const [deleteTeam, setDeleteTeam] = useState(null);
  const [adminRoles, setAdminRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ visible: false, message: '', type: '' });
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    if (showAlert.visible) {
      const timer = setTimeout(() => {
        setShowAlert(prev => ({ ...prev, visible: false }));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert.visible])



  useEffect(() => {
    const fetchAdminRoles = async () => {
      try {
        const response = await api.get("/admin");
        setAdminRoles(response.data);
      } catch (error) {
        console.error("Error fetching admin roles:", error);
      }
    };

    fetchAdminRoles();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await api.get("/user");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchTeams = async () => {
    try {
      const response = await api.get("/team");
      setTeams(response.data);
    } catch (error) {
      console.error("Error fetching teams:", error);
    }
  };

  useEffect(() => {
    fetchTeams();
  }, []);

  useEffect(() => {
    const getUserRole = (adminId) => {
      const user = users.find((user) => user._id === adminId);
      if (user) {
        const role = adminRoles.find((role) => role._id === user.adminRole._id);
        setUserRole(role);
        return role?.roleName || null;
      }
      return null;
    };

    const role = getUserRole(adminId);
  }, [adminId, users, adminRoles]);

  const handleSubmitCreateTeam = async (e) => {
    e.preventDefault();
    setIsLoading(true);


    const isDuplicate = teams.some(team => team.teamName === teamName && (!editingTeam || team._id !== editingTeam._id));

    if (isDuplicate) {
      setIsLoading(false);
      setShowAlert({ visible: true, message: 'Team already exists', type: 'danger' });
      return;
    }

    try {
      if (editingTeam) {
        const response = await api.put(`/team/${editingTeam._id}`, { teamName });
      } else {
        const response = await api.post("/team", { teamName });
      }
      setEditingTeam(null);
      setTeamName("");
      setIsLoading(false);
      fetchTeams();
      setShowForm(false);
      setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
    } catch (error) {
      console.error("Error submitting team:", error);
    }
  };

  const handleDeleteTeam = async (e) => {
    e.preventDefault();
    if (!window.confirm("Are you sure you want to delete this entry?")) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await api.delete(`/team/${deleteTeam._id}`);
      if (response.status === 200) {
        setShowForm(false);
        fetchTeams();
        setIsLoading(false);
        setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
      } else {
        throw new Error("Failed to delete the item. Please try again later.");
      }
    } catch (error) {
      console.error("Error deleting team:", error);
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
    setEditingTeam(null);
    setTeamName("");
  };

  const handleEditingTeam = (team) => {
    //("team", team);
    setIsLoading(false);
    setShowAlert({ visible: false, message: '', type: '' });
    setEditingTeam(team);
    setDeleteTeam(team);
    setTeamName(team.teamName);
    setShowForm(true);
  };

  const columns = [

    { field: 'number', headerName: 'Number', width: 170 },
    { field: 'teamName', headerName: 'Team Name', width: 250 },
  ];

  const rows = teams.map((team, index) => ({ id: team._id, teamName: team.teamName, number: index + 1 }));

  return (
    <React.Fragment>
      <div className="page-header">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i className="fa fa-podcast"></i>
          </span>{" "}
          Teams
        </h3>
        <nav aria-label="breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              <span></span>Overview{" "}
              <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
            </li>
          </ul>
        </nav>
      </div>
      <div className="container">
        <div className="col-12">
          {userRole && userRole.pageList.find(page => page.pageName === "Team").access[1] === 1 && (
            <button
              onClick={toggleForm}
              className="btn btn-gradient-primary mb-2"
              type="button"
            >
              Create Team
            </button>
          )}
        </div>

        <div
          className={`offcanvas offcanvas-end w-50 ${showForm ? 'show' : ''}`}
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
              {editingTeam ? 'Edit Team' : 'Create Team'}
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={toggleForm}
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body">
            <form onSubmit={handleSubmitCreateTeam}>
              <div className="mb-3">
                <label htmlFor="unit" className="form-label">
                  Team Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="unit"
                  placeholder="Enter team name"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                />
              </div>

              <Loader isLoading={isLoading} />
              <div className="row text-start w-75">
                <div className="col-md-3 text-start">
                  {userRole && userRole.pageList.find(page => page.pageName === "Team").access[2] === 1 && (
                    <button type="submit" className="btn btn-sm btn-primary">
                      {editingTeam ? 'Update Team' : 'Add Team'}
                    </button>
                  )}
                </div>
                <div className="col-md-3 align-items-center">
                  {userRole?.pageList.some(page => page.pageName === "Team" && page.access[3] === 1) && editingTeam && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={handleDeleteTeam}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        {showAlert.visible && (
          <div
            className={`alert alert-${showAlert.type} alert-dismissible fade show`}
            role="alert"
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: 1050
            }}
          >
            <strong>{showAlert.message}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert({ ...showAlert, visible: false })}
            ></button>
          </div>
        )}

        {/* DataGrid */}
        <div className="row mt-4">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body table-responsive">
                <h4 className="card-title">List of Teams</h4>
                <Paper sx={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    sx={{ border: 0 }}
                    onRowDoubleClick={(params) => handleEditingTeam(teams.find(team => team._id === params.id))}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default Team;
