import React, { useState, useEffect, useCallback } from "react";
import api from "../Api";
import moment from "moment";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

const RepeatedCustomer = () => {
    const [getCompareProductTypes, setGetCompareProductTypes] = useState([]);
    const [getCustomerCreation, setGetCustomerCreation] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);

    // Fetch product type comparison data
    useEffect(() => {
        const fetchCompareProductTypes = async () => {
            try {
                const response = await api.get('/compareProductTypes');
                setGetCompareProductTypes(response.data);
            } catch (error) {
                //-console.error('Error fetching product type comparisons:', error);
            }
        };

        fetchCompareProductTypes();
    }, []);
    console.log("getCompareProductTypes", getCompareProductTypes);


    // Fetch customer creation data
    useEffect(() => {
        const fetchCustomerCreation = async () => {
            try {
                const response = await api.get("/customerCreation");
                setGetCustomerCreation(response.data);
            } catch (error) {
                //-console.error('Error fetching customer creation data:', error);
            }
        };
        fetchCustomerCreation();
    }, []);

    // Filter rows based on date range
    const filterRowsByDate = useCallback(
        (startDate, endDate) => {
          console.log("startDate", startDate);
      
          const filtered = getCompareProductTypes.filter(item => {
            return item.differences.some(diff => {
              // Get all unique categories dynamically
              const allUniqueItems = Object.values(diff.differences).flat();
              return allUniqueItems.some(diffItem => {
                const createdAt = moment(diffItem.createdAt);
                return createdAt.isBetween(
                  moment(startDate).startOf("day"),
                  moment(endDate).endOf("day"),
                  null,
                  "[]"
                );
              });
            });
          });
      
          setFilteredRows(filtered);
          console.log("Filtered Rows:", filtered);
        },
        [getCompareProductTypes] 
      );

    // Handle date changes
    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    // Handle search click
    const handleSearchClick = () => {
        setSearchClicked(true);
        filterRowsByDate(fromDate, toDate);
    };

    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD HH:mm:ss");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };

        setDefaultFromToDates();
    }, []);

    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DDTHH:mm");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        filterRowsByDate(currentDateFormatted, currentDateFormatted);
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Multiple Product Categories
                </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                            <span></span>Overview{" "}
                            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* Date Filters */}
            <div className="container">
                <div className="row">
                    {/* From Date */}
                    <div className="col-md-3">
                        <label>From Date</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            name="fromDate"
                            value={fromDate}
                            onChange={handleFromDateChange}
                        />
                    </div>
                    {/* To Date */}
                    <div className="col-md-3">
                        <label>To Date</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            name="toDate"
                            value={toDate}
                            onChange={handleToDateChange}
                        />
                    </div>
                    {/* Search Button */}
                    <div className="col-md-3">
                        <button className="btn btn-primary mt-4" onClick={handleSearchClick}>
                            Search Date
                        </button>
                        {searchClicked && (
                            <button
                                onClick={handleClearFilter}
                                className="btn btn-sm badge-gradient-danger"
                                aria-label="Close"
                                style={{ marginLeft: "10px", marginTop: "30px" }}
                            >
                                &times;
                            </button>
                        )}
                    </div>
                </div>

                {/* Table */}
                <TableContainer component={Paper} className="mt-4">
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Number</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Mobile</TableCell>
                                <TableCell>Email</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows.map((row, index) => {
                                const customer = getCustomerCreation.find(
                                    (customer) => customer._id === row.name
                                );
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{customer?.name || "N/A"}</TableCell>
                                        <TableCell>{customer?.mobile || "N/A"}</TableCell>
                                        <TableCell>{customer?.email || "N/A"}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </React.Fragment>
    );
};

export default RepeatedCustomer;
