import React, { useState, useEffect } from "react";
import api from "../Api";

const CreateAdmin = () => {
  const [pageNames, setPageNames] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState({});
  const [selectedRole, setSelectedRole] = useState("");
  const [getAdminRole, setGetAdminRole] = useState([]);
  const [editMode, setEditMode] = useState(false);

  // Fetch page names on component mount
  useEffect(() => {
    const fetchPageNames = async () => {
      try {
        const response = await api.get("/pageName");
        setPageNames(response.data);
      } catch (error) {
        //-console.error("Error fetching page names:", error);
      }
    };

    fetchPageNames();
  }, []);

  // Fetch admin roles on component mount
  useEffect(() => {
    const fetchAdminRole = async () => {
      try {
        const response = await api.get("/admin");
        setGetAdminRole(response.data);
      } catch (error) {
        //-console.error("Error fetching admin roles:", error);
      }
    };

    fetchAdminRole();
  }, []);

  useEffect(() => {
    if (!selectedRole) {
      setPermissions({});
      return;
    }

    const selectedAdminRole = getAdminRole.find(
      (role) => role._id === selectedRole
    );
    //-console.log("selectedAdminRole",selectedAdminRole);
    //-console.log("selectedRole", selectedRole);
    if (selectedAdminRole) {
      const updateRolePageList = {};
      selectedAdminRole.pageList.forEach((page) => {
        updateRolePageList[page.pageName] = {
          read: page.access[0],
          write: page.access[1],
          update: page.access[2],
          delete: page.access[3],
        };
        //-console.log("selectedAdminRole", selectedAdminRole.pageList);
        //-console.log("updateRolePageList", updateRolePageList);
      });
      setPermissions(updateRolePageList);
    }
  }, [selectedRole, getAdminRole]);

  // Handle checkbox change
  const handleCheckboxChange = (pageName, permissionType, checked) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [pageName]: {
        ...prevPermissions[pageName],
        [permissionType]: checked,
      },
    }));
  };

  // Handle select all permissions
  const handleSelectAllPermissions = (permissionType) => {
    const updatedPermissions = {};
    pageNames.forEach((page) => {
      updatedPermissions[page.pageName] = {
        ...permissions[page.pageName],
        [permissionType]: !permissions[page.pageName]?.[permissionType],
      };
    });
    setPermissions(updatedPermissions);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      let response;
      if (editMode && selectedRole) {
        //-console.log(selectedRole);
        const updateData = {
          roleName: selectedRole.roleName,
          pageList: Object.keys(permissions).map((pageName) => ({
            pageName: pageName,
            access: [
              permissions[pageName]?.read ? 1 : 0,
              permissions[pageName]?.write ? 1 : 0,
              permissions[pageName]?.update ? 1 : 0,
              permissions[pageName]?.delete ? 1 : 0,
            ],
          })),
        };

        response = await api.put(`/admin/${selectedRole}`, updateData);
        const updatedRoles = getAdminRole.map((role) => {
          if (role._id === selectedRole) {
            return response.data;
          }
          return role;
        });
        setGetAdminRole(updatedRoles);
      } else {
        const postData = {
          roleName: roleName,
          pageList: Object.keys(permissions).map((pageName) => ({
            pageName: pageName,
            access: [
              permissions[pageName]?.read ? 1 : 0,
              permissions[pageName]?.write ? 1 : 0,
              permissions[pageName]?.update ? 1 : 0,
              permissions[pageName]?.delete ? 1 : 0,
            ],
          })),
        };

        //-console.log("Submitting data:", postData);
        response = await api.post("/admin", postData);
        //-console.log("Data created successfully:", response.data);

        setGetAdminRole((prevRoles) => [...prevRoles, response.data]);
        setSelectedRole(response.data._id);
      }
    } catch (error) {
      //-console.error("Error saving data:", error);
    }
  };


  return (
    <React.Fragment>
      <div class="page-header">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i class="fa fa-gavel"></i>
          </span>{" "}
          Role
        </h3>
        <nav aria-label="breadcrumb">
          <ul class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
              <span></span>Overview{" "}
              <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
            </li>
          </ul>
        </nav>
      </div>
      <div className="d-flex justify-content-center container">
        <form onSubmit={handleSubmit}>
          <div className="row align-items-end justify-content-center">
            <div className="col-8 col-sm-4 mb-3">
              <input
                type="text"
                className="form-control"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              />
            </div>
            <div className="col-8 col-sm-4 mb-3">
              <label htmlFor="selectedRole">Selected Role</label>
              <select
                className="form-select"
                id="selectedRole"
                value={selectedRole || ""}
                onChange={(e) => setSelectedRole(e.target.value)}
              >
                <option value="">Select role</option>
                {getAdminRole.map((role) => (
                  <option key={role._id} value={role._id}>
                    {role.roleName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12">
              <div className="row justify-content-center align-items-center">
                <div
                  className="col-12 card text-white"
                  style={{
                    background:
                      "linear-gradient(to right, #90caf9, #047edf 99%)",
                  }}
                >
                  <div className="row p-sm-3 d-none d-sm-inline-flex">
                    <div className="col-4 shrink-text">Navbars</div>
                    <div className="col-md-2 shrink-text">
                      <input
                        type="checkbox"
                        id="selectAllRead"
                        className="custom-control-input"
                        checked={pageNames.every(
                          (page) => permissions[page.pageName]?.read
                        )}
                        onChange={() => handleSelectAllPermissions("read")}
                      />
                      <label htmlFor="selectAllRead" className="cursor-pointer">
                        Read
                      </label>
                    </div>
                    <div className="col-md-2 shrink-text">
                      <input
                        type="checkbox"
                        id="selectAllWrite"
                        className="custom-control-input"
                        checked={pageNames.every(
                          (page) => permissions[page.pageName]?.write
                        )}
                        onChange={() => handleSelectAllPermissions("write")}
                      />
                      <label
                        htmlFor="selectAllWrite"
                        className="cursor-pointer"
                      >
                        Write
                      </label>
                    </div>
                    <div className="col-md-2 shrink-text">
                      <input
                        type="checkbox"
                        id="selectAllUpdate"
                        className="custom-control-input"
                        checked={pageNames.every(
                          (page) => permissions[page.pageName]?.update
                        )}
                        onChange={() => handleSelectAllPermissions("update")}
                      />
                      <label
                        htmlFor="selectAllUpdate"
                        className="cursor-pointer"
                      >
                        Update
                      </label>
                    </div>
                    <div className="col-2 shrink-text">
                      <input
                        type="checkbox"
                        id="selectAllDelete"
                        className="custom-control-input"
                        checked={pageNames.every(
                          (page) => permissions[page.pageName]?.delete
                        )}
                        onChange={() => handleSelectAllPermissions("delete")}
                      />
                      <label htmlFor="selectAllRead" className="cursor-pointer">
                        Delete
                      </label>
                    </div>
                  </div>
                </div>

                {pageNames.map((page, index) => (
                  <div
                    key={index}
                    className="col-12 card p-sm-3 p-3 rounded mb-4 mt-3"
                  >
                    <div className="row justify-content-center align-items-center">
                      <div className="col-4">{page.pageName}</div>
                      <div className="col-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={permissions[page.pageName]?.read || false}
                          onChange={(e) =>
                            handleCheckboxChange(
                              page.pageName,
                              "read",
                              e.target.checked
                            )
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor=""
                        ></label>
                      </div>
                      <div className="col-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={permissions[page.pageName]?.write || false}
                          onChange={(e) =>
                            handleCheckboxChange(
                              page.pageName,
                              "write",
                              e.target.checked
                            )
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor=""
                        ></label>
                      </div>
                      <div className="col-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={permissions[page.pageName]?.update || false}
                          onChange={(e) =>
                            handleCheckboxChange(
                              page.pageName,
                              "update",
                              e.target.checked
                            )
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor=""
                        ></label>
                      </div>
                      <div className="col-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          checked={permissions[page.pageName]?.delete || false}
                          onChange={(e) =>
                            handleCheckboxChange(
                              page.pageName,
                              "delete",
                              e.target.checked
                            )
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor=""
                        ></label>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="col-12 text-center">
                  {selectedRole && (
                    <>
                      {!editMode && (
                        <button
                          type="button"
                          onClick={() => setEditMode(true)}
                          className="btn btn-primary mr-2"
                        >
                          Edit
                        </button>
                      )}
                      {editMode && (
                        <input
                          type="submit"
                          className="btn btn-primary"
                          value="Save"
                        />
                      )}
                      <input
                        type="submit"
                        className="btn btn-danger d-none"
                        value="Delete"
                      />
                    </>
                  )}
                  {!selectedRole && (
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value="Create"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default CreateAdmin;
