// import axios from "axios";

// // const api = axios.create({
// //   baseURL: process.env.REACT_APP_API_SERVER + "/api",
// // });

////-console.log("API baseURL:", process.env.REACT_APP_API_SERVER);

// export default api;

import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER + "/api",
});

export default api;
