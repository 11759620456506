import React, { useEffect, useState } from 'react';
import api from '../Api';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlock } from '@fortawesome/free-solid-svg-icons';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons';
import { faArrowDown, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import "./AdminDashboard.css"

const AdminDashboard = ({ adminId }) => {
  const localizer = momentLocalizer(moment);

  const [events, setEvents] = useState([]);


  const [live, setlive] = useState({
    totalOpenLive: 0,
    totalQuotationLive: 0,
    totalConvertedLive: 0,
  });

  const [lead, setLead] = useState({
    totalOpenLead: 0,
    totalQuotationLead: 0,
    totalConvertedLead: 0,
  })

  const [todayFollowUpLive, setTodayFollowUPLive] = useState({
    totalFollowUpTodayOpenLive: 0,
    totalFollowUpTodayQuotationLive: 0,
  });

  const [tomorrowFollowUpLive, setTomorrowFollowUPLive] = useState({
    totalFollowUpTomorrowOpenLive: 0,
    totalFollowUpTomorrowQuotationLive: 0,
  });

  const [yesterdayFollowUpLive, setYesterdayFollowUPLive] = useState({
    totalFollowUpYesterdayOpenLive: 0,
    totalFollowUpYesterdayQuotationLive: 0,
  });


  const [todayFollowUpLead, setTodayFollowUPLead] = useState({
    totalFollowUpTodayOpenLead: 0,
    totalFollowUpTodayQuotationLead: 0,
  });

  const [tomorrowFollowUpLead, setTomorrowFollowUPLead] = useState({
    totalFollowUpTomorrowOpenLead: 0,
    totalFollowUpTomorrowQuotationLead: 0,
  });

  const [yesterdayFollowUpLead, setYesterdayFollowUPLead] = useState({
    totalFollowUpYesterdayOpenLead: 0,
    totalFollowUpYesterdayQuotationLead: 0,
  });

  // Today followUpLive
  useEffect(() => {
    const fetchFollowUpTodayLiveData = async () => {
      try {
        const openResponse = await api.get(`/findTodayFollowUpOpenLive/${adminId}`);
        const quotationResponse = await api.get(`/findTodayFollowUpQuotationLive/${adminId}`);

        if (openResponse.data) {
          setTodayFollowUPLive((prevLive) => ({
            ...prevLive,
            totalFollowUpTodayOpenLive: openResponse.data.totalFollowUpTodayOpenLive,
          }));
        }

        if (quotationResponse.data) {
          setTodayFollowUPLive((prevLive) => ({
            ...prevLive,
            totalFollowUpTodayQuotationLive: quotationResponse.data.totalFollowUpTodayQuotationLive,
          }));
        }

      } catch (error) {
        //-console.log("Error fetching live count:", error);
      }
    };

    fetchFollowUpTodayLiveData();
  }, [adminId]);

  // Tomorrow followUpLive
  useEffect(() => {
    const fetchFollowUpTodayLiveData = async () => {
      try {
        const openResponse = await api.get(`/findTomorrowFollowUpOpenLive/${adminId}`);
        const quotationResponse = await api.get(`/findTomorrowFollowUpQuotationLive/${adminId}`);

        if (openResponse.data) {
          setTomorrowFollowUPLive((prevLive) => ({
            ...prevLive,
            totalFollowUpTomorrowOpenLive: openResponse.data.totalFollowUpTomorrowOpenLive,
          }));
        }

        if (quotationResponse.data) {
          setTomorrowFollowUPLive((prevLive) => ({
            ...prevLive,
            totalFollowUpTomorrowQuotationLive: quotationResponse.data.totalFollowUpTomorrowQuotationLive,
          }));
        }

      } catch (error) {
        //-console.log("Error fetching live count:", error);
      }
    };

    fetchFollowUpTodayLiveData();
  }, [adminId]);


  // Yesterday followUpLive
  useEffect(() => {
    const fetchFollowUpYesterdayLiveData = async () => {
      try {
        const openResponse = await api.get(`/findYesterdayFollowUpOpenLive/${adminId}`);
        //-console.log(openResponse.data);
        const quotationResponse = await api.get(`/findYesterdayFollowUpQuotationLive/${adminId}`);

        if (openResponse.data) {
          setYesterdayFollowUPLive((prevLive) => ({
            ...prevLive,
            totalFollowUpYesterdayOpenLive: openResponse.data.totalFollowUpYesterdayOpenLive,
          }));
          //-console.log(yesterdayFollowUpLive.totalFollowUpYesterdayOpenLive);
        }

        if (quotationResponse.data) {
          setYesterdayFollowUPLive((prevLive) => ({
            ...prevLive,
            totalFollowUpYesterdayQuotationLive: quotationResponse.data.totalFollowUpYesterdayQuotationLive,
          }));
        }

      } catch (error) {
        //-console.log("Error fetching live count:", error);
      }
    };

    fetchFollowUpYesterdayLiveData();
  }, [adminId]);


  // lead 
  // Today followUp lead
  useEffect(() => {
    const fetchFollowUpTodayLeadData = async () => {
      try {
        const openResponse = await api.get(`/findTodayFollowUpOpenLead/${adminId}`);
        const quotationResponse = await api.get(`/findTodayFollowUpQuotationLead/${adminId}`);

        if (openResponse.data) {
          setTodayFollowUPLead((prevLive) => ({
            ...prevLive,
            totalFollowUpTodayOpenLead: openResponse.data.totalFollowUpTodayOpenLead,
          }));
        }

        if (quotationResponse.data) {
          setTodayFollowUPLead((prevLive) => ({
            ...prevLive,
            totalFollowUpTodayQuotationLead: quotationResponse.data.totalFollowUpTodayQuotationLead,
          }));
        }

      } catch (error) {
        //-console.log("Error fetching live count:", error);
      }
    };

    fetchFollowUpTodayLeadData();
  }, [adminId]);

  // Tomorrow followUpLive
  useEffect(() => {
    const fetchFollowUpTomorrowLeadData = async () => {
      try {
        const openResponse = await api.get(`/findTomorrowFollowUpOpenLead/${adminId}`);
        const quotationResponse = await api.get(`/findTomorrowFollowUpQuotationLead/${adminId}`);

        if (openResponse.data) {
          setTomorrowFollowUPLead((prevLive) => ({
            ...prevLive,
            totalFollowUpTomorrowOpenLead: openResponse.data.totalFollowUpTomorrowOpenLead,
          }));
        }

        if (quotationResponse.data) {
          setTomorrowFollowUPLead((prevLive) => ({
            ...prevLive,
            totalFollowUpTomorrowQuotationLead: quotationResponse.data.totalFollowUpTomorrowQuotationLead,
          }));
        }

      } catch (error) {
        //-console.log("Error fetching live count:", error);
      }
    };

    fetchFollowUpTomorrowLeadData();
  }, [adminId]);


  // Yesterday followUpLive
  useEffect(() => {
    const fetchFollowUpYesterdayLeadData = async () => {
      try {
        const openResponse = await api.get(`/findYesterdayFollowUpOpenLead/${adminId}`);
        const quotationResponse = await api.get(`/findYesterdayFollowUpQuotationLead/${adminId}`);

        if (openResponse.data) {
          setYesterdayFollowUPLead((prevLive) => ({
            ...prevLive,
            totalFollowUpYesterdayOpenLead: openResponse.data.totalFollowUpYesterdayOpenLead,
          }));
        }

        if (quotationResponse.data) {
          setYesterdayFollowUPLead((prevLive) => ({
            ...prevLive,
            totalFollowUpYesterdayQuotationLead: quotationResponse.data.totalFollowUpYesterdayQuotationLead,
          }));
        }

      } catch (error) {
        //-console.log("Error fetching live count:", error);
      }
    };

    fetchFollowUpYesterdayLeadData();
  }, [adminId]);

  useEffect(() => {
    const fetchLiveData = async () => {
      try {
        const openResponse = await api.get(`/findtotalOpenLive/${adminId}`);
        const quotationResponse = await api.get(`/findtotalQuotationLive/${adminId}`);
        const convertedResponse = await api.get(`/findtotalConvertedLive/${adminId}`);

        if (openResponse.data) {
          setlive((prevlive) => ({
            ...prevlive,
            totalOpenLive: openResponse.data.totalOpenLive,
          }));
        }

        if (quotationResponse.data) {
          setlive((prevlive) => ({
            ...prevlive,
            totalQuotationLive: quotationResponse.data.totalQuotationLive,
          }));
        }

        if (convertedResponse.data) {
          setlive((prevlive) => ({
            ...prevlive,
            totalConvertedLive: convertedResponse.data.totalConvertedLive,
          }));
        }

      } catch (error) {
        //-console.log("Error fetching live count:", error);
      }
    };

    fetchLiveData();
  }, [adminId]);


  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        const openLeadResponse = await api.get(`/findtotalOpenLead/${adminId}`);
        const quotationLeadResponse = await api.get(`/findtotalQuotationLead/${adminId}`);
        const convertedLeadResponse = await api.get(`/findtotalConvertedLead/${adminId}`);

        if (openLeadResponse.data) {
          setLead((prevlead) => ({
            ...prevlead,
            totalOpenLead: openLeadResponse.data.totalOpenLead,
          }));
        }

        if (quotationLeadResponse.data) {
          setLead((prevlead) => ({
            ...prevlead,
            totalQuotationLead: quotationLeadResponse.data.totalQuotationLead,
          }));
        }

        if (convertedLeadResponse.data) {
          setLead((prevlive) => ({
            ...prevlive,
            totalConvertedLead: convertedLeadResponse.data.totalConvertedLead,
          }));
        }

      } catch (error) {
        //-console.log("Error fetching lead count:", error);
      }
    };

    fetchLeadData();
  }, [adminId]);



  useEffect(() => {
    const fetchCalendarLead = async () => {
      try {
        const response = await api.get(`/calender/${adminId}`);
        setEvents(response.data);
      } catch (error) {
        //-console.error("Error fetching lead calendar:", error);
      }
    };

    fetchCalendarLead();
  }, [adminId]);

  const totallive = live.totalOpenLive + live.totalQuotationLive + live.totalConvertedLive;

  const totalLead = lead.totalOpenLead + lead.totalQuotationLead + lead.totalConvertedLead;

  const calendarStyle = {
    height: '600px',
    width: '100%',
    padding: '20px',
    backgroundColor: 'white',
  };

  return (
    <React.Fragment>
      <div className="page-header">
        <h4 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i className="mdi mdi-home"></i>
          </span> Admin Dashboard
        </h4>
        <nav aria-label="breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              <span></span>Overview <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
            </li>
          </ul>
        </nav>
      </div>
      <div className="col-md-12 stretch-card grid-margin">
        <div className="card card-custom">
          <div className="card-body">
            <h4 className="font-weight-normal mb-3">
              <FontAwesomeIcon icon={faBookmark} /> Notes !!
            </h4>
            <div className="row">
              <div className="col-md-6">
                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faUnlock} size="lg" className="me-2" />
                    <span>It represent the total numbers of open leads</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faFileInvoice} size="lg" className="me-2" />
                    <span>It represent the numbers of quotation leads</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} size="lg" className="me-2" />
                    <span>It represent the converted leads</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCalendarDays} size="lg" className="me-2" />
                    <span>It represent the calender</span>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="list-unstyled">
                  <li>
                    <FontAwesomeIcon icon={faArrowDown} size="lg" className="me-2" />
                    <span>It represent the today's leads based on nextFollowUp Date</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faArrowRight} size="lg" className="me-2" />
                    <span>It represent the tomorrow leads based on nextFollowUp Date</span>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faArrowLeft} size="lg" className="me-2" />
                    <span>It represent the yesterday leads based on nextFollowUp Date</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* Total Live Card */}
        <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-danger card-img-holder text-white">
            <div className="card-body">
              <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
              <h4 className="font-weight-normal mb-3"> Total Live <i className="mdi mdi-chart-line mdi-24px float-end"></i>
              </h4>
              <table className="table table-borderless  color-liveTable" >
                <thead>
                  <tr>
                    <th className="dashboardth"><FontAwesomeIcon icon={faUnlock} size="lg" /></th>
                    <th className="dashboardth"><FontAwesomeIcon icon={faFileInvoice} size="lg" /></th>
                    <th className="dashboardth"><FontAwesomeIcon icon={faCheck} size="lg" /></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{live.totalOpenLive}</td>
                    <td>{live.totalQuotationLive}</td>
                    <td>{live.totalConvertedLive}</td>
                  </tr>
                </tbody>
              </table>

              <h4 className="card-text mt-4">Total Live's - {totallive}</h4>
            </div>
          </div>
        </div>

        {/* Total Lead Count */}
        <div className="col-md-4 stretch-card grid-margin">
          <div class="card bg-gradient-info card-img-holder text-white">
            <div className="card-body">
              <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
              <h4 className="font-weight-normal mb-3"> Total Lead <i className="mdi mdi-chart-line mdi-24px float-end"></i>
              </h4>

              {/* Lead Status Table */}
              <table className="table table-borderless color-leadTable ">
                <thead>
                  <tr>
                    <th className="dashboardth"><FontAwesomeIcon icon={faUnlock} size="lg" /></th>
                    <th className="dashboardth"><FontAwesomeIcon icon={faFileInvoice} size="lg" /></th>
                    <th className="dashboardth"><FontAwesomeIcon icon={faCheck} size="lg" /></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{lead.totalOpenLead}</td>
                    <td>{lead.totalQuotationLead}</td>
                    <td>{lead.totalConvertedLead}</td>
                  </tr>
                </tbody>
              </table>

              <h4 className="card-text mt-4">Total Lead's - {totalLead}</h4>
            </div>
          </div>
        </div>

        {/*Live followUp Count based on days */}
        <div className="col-md-4 stretch-card grid-margin">
          <div className="card bg-gradient-danger card-img-holder text-white">
            <div className="card-body">
              <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
              <h4 className="font-weight-normal mb-3"> FollowUp's <i class="fa fa-calendar  mdi-24px float-end"></i>
              </h4>

              {/* Lead Status Table */}
              <table className="table table-borderless color-liveTable">
                <thead>
                  <tr>
                    <th className="dashboardth"><FontAwesomeIcon icon={faCalendarDays} size="lg" /></th>
                    <th className="dashboardth"><FontAwesomeIcon icon={faUnlock} size="lg" /></th>
                    <th className="dashboardth"><FontAwesomeIcon icon={faFileInvoice} size="lg" /></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="dashboardth"><FontAwesomeIcon icon={faArrowDown} size="lg" /></th>
                    <td>{todayFollowUpLive.totalFollowUpTodayOpenLive}</td>
                    <td>{todayFollowUpLive.totalFollowUpTodayQuotationLive}</td>
                  </tr>
                  <tr>
                    <th className="dashboardth"><FontAwesomeIcon icon={faArrowRight} size="lg" /></th>
                    <td>{tomorrowFollowUpLive.totalFollowUpTomorrowOpenLive}</td>
                    <td>{tomorrowFollowUpLive.totalFollowUpTomorrowQuotationLive}</td>
                  </tr>
                  <tr>
                    <th className="dashboardth"><FontAwesomeIcon icon={faArrowLeft} size="lg" /></th>
                    <td>{yesterdayFollowUpLive.totalFollowUpYesterdayOpenLive}</td>
                    <td>{yesterdayFollowUpLive.totalFollowUpYesterdayQuotationLive}</td>
                  </tr>
                </tbody>
              </table>
              <h4 className="card-text mt-4">Total Live FollowUp's</h4>
            </div>
          </div>
        </div>

        {/* Total lead followUp */}
        <div className="col-md-4 stretch-card grid-margin">
          <div class="card bg-gradient-info card-img-holder text-white">
            <div className="card-body">
              <img src="assets/images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
              <h4 className="font-weight-normal mb-3"> FollowUp's <i class="fa fa-calendar  mdi-24px float-end"></i>
              </h4>

              {/* Lead Status Table */}
              <table className="table table-borderless color-leadTable ">
                <thead>
                  <tr>
                    <th className="dashboardth"><FontAwesomeIcon icon={faCalendarDays} size="lg" /></th>
                    <th className="dashboardth"><FontAwesomeIcon icon={faUnlock} size="lg" /></th>
                    <th className="dashboardth"><FontAwesomeIcon icon={faFileInvoice} size="lg" /></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th className="dashboardth"><FontAwesomeIcon icon={faArrowDown} size="lg" /></th>
                    <td>{todayFollowUpLead.totalFollowUpTodayOpenLead}</td>
                    <td>{todayFollowUpLead.totalFollowUpTodayQuotationLead}</td>
                  </tr>
                  <tr>
                    <th className="dashboardth"><FontAwesomeIcon icon={faArrowRight} size="lg" /></th>
                    <td>{tomorrowFollowUpLead.totalFollowUpTomorrowOpenLead}</td>
                    <td>{tomorrowFollowUpLead.totalFollowUpTomorrowQuotationLead}</td>
                  </tr>
                  <tr>
                    <th className="dashboardth"><FontAwesomeIcon icon={faArrowLeft} size="lg" /></th>
                    <td>{yesterdayFollowUpLead.totalFollowUpYesterdayOpenLead}</td>
                    <td>{yesterdayFollowUpLead.totalFollowUpYesterdayQuotationLead}</td>
                  </tr>
                </tbody>
              </table>
              <h4 className="card-text mt-4">Total Lead FollowUp's</h4>
            </div>
          </div>
        </div>

        {/* calender*/}
        <h4>Lead's Calender</h4>
        <div style={calendarStyle}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          </div>
          <Calendar
            localizer={localizer}
            events={events.map(event => ({
              ...event,
              title: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <FontAwesomeIcon icon={faUnlock} size="sm" style={{ marginRight: '5px' }} />
                  {event.count}
                </div>
              )
            }))}
            startAccessor="start"
            endAccessor="end"
            views={['month', 'week', 'day']}
          />
        </div>
      </div>
    </React.Fragment >
  );
};

export default AdminDashboard;