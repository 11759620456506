import React, { useState, useEffect } from "react";
import api from "../Api";
import Loader from './Loader';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';

const CreateUser = ({ adminId }) => {
  // State declarations
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [employeeCode, setEmployeeCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [getAdminRole, setGetAdminRole] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [passwordFormatError, setPasswordFormatError] = useState("");
  const [editingUsers, setEditingUsers] = useState(null);
  const [deleteUser, setDeleteUser] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [getUser, setGetUser] = useState([]);
  const [getTeams, setGetTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({ visible: false, message: '', type: '' });
  const [userRole, setUserRole] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (showAlert.visible) {
      const timer = setTimeout(() => {
        setShowAlert(prev => ({ ...prev, visible: false }));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert.visible])


  // Toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // Validate password format
  const validatePasswordFormat = () => {
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).{8,}$/;
    if (!passwordPattern.test(password)) {
      setPasswordFormatError(
        'Must contain at least one number, one uppercase letter, one lowercase letter, and at least 8 characters'
      );
      return false;
    }
    setPasswordFormatError('');
    return true;
  };

  // Fetch admin roles
  useEffect(() => {
    const fetchAdminRole = async () => {
      try {
        const response = await api.get("/admin");
        setGetAdminRole(response.data);
      } catch (error) {
        // console.error("Error fetching admin roles:", error);
      }
    };

    fetchAdminRole();
  }, []);

  // Fetch user roles
  useEffect(() => {
    const getUserRole = (adminId) => {
      const user = getUser.find((user) => user._id === adminId);
      if (user) {
        const loginuserRole = getAdminRole.find((role) => role._id === user.adminRole._id);
        setUserRole(loginuserRole);
        return loginuserRole?.roleName || null;
      } else {
        return null;
      }
    };

    const role = getUserRole(adminId);
  }, [adminId, getUser, getAdminRole]);

  // Fetch teams
  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await api.get("/team");
        setGetTeams(response.data);
      } catch (error) {
        // console.error(error);
      }
    };
    fetchTeams();
  }, []);

  // Fetch users
  const fetchUser = async () => {
    try {
      const response = await api.get("/user");
      setGetUser(response.data);
    } catch (error) {
      // console.error("Error fetching User:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  // Handle form submission
  const handleSubmitUserDetails = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setPasswordMatchError('Passwords do not match');
      return;
    }
    if (!validatePasswordFormat()) {
      return;
    }

    setIsLoading(true);

    const isDuplicate = getUser.some(user => user.email === email && (!editingUsers || user._id !== editingUsers.id));

    if (isDuplicate) {
      setIsLoading(false);
      setShowAlert({ visible: true, message: 'User already exists', type: 'danger' });
      return;
    }

    try {
      if (editingUsers) {
        await api.put(`/user/${editingUsers.id}`, {
          name,
          email,
          employeeCode,
          password,
          phoneNumber,
          adminRole: selectedRole,
          team: selectedTeam
        });
      } else {
        await api.post("/user", {
          name,
          email,
          employeeCode,
          password,
          phoneNumber,
          adminRole: selectedRole,
          team: selectedTeam,
        });
      }
      setIsLoading(false);
      setName("");
      setEmail("");
      setEmployeeCode("");
      setPassword("");
      setConfirmPassword("");
      setPhoneNumber("");
      setSelectedRole("");
      setSelectedTeam("");
      fetchUser();
      setShowForm(false);
      setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // Handle user deletion
  const handleDeleteUser = async (e) => {
    e.preventDefault();
    if (!window.confirm("Are you sure you want to delete this entry?")) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await api.delete(`/user/${deleteUser.id}`);
      if (response.status === 200) {
        setShowForm(false);
        fetchUser();
        setIsLoading(false);
        setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
      } else {
        // console.error("Failed to delete the item. Server returned status:", response.status);
        throw new Error("Failed to delete the item. Please try again later.");
      }
    } catch (error) {
      // console.error("Error deleting item:", error);
      setIsLoading(false);
      setShowAlert({ visible: true, message: 'Error occurred while deleting user', type: 'danger' });

    }
  };

  // Handle user editing
  const handleEditingUsers = (users) => {
    //-console.log("users", users);
    setIsLoading(false);
    setShowAlert({ visible: false, message: '', type: '' });
    setEditingUsers(users);
    setDeleteUser(users);
    setEmployeeCode(users.employeeCode);
    setName(users.name);
    setEmail(users.email);
    setPhoneNumber(users.phoneNumber);
    setSelectedRole(users.roleId);
    setSelectedTeam(users.teamId);
    setPassword(users.password);
    setConfirmPassword(users.password);
    setShowForm(true);
  };

  // Toggle form visibility
  const toggleForm = () => {
    setShowForm(!showForm);
    setEditingUsers(null);
  };

  // Define DataGrid columns
  const columns = [
    { field: 'number', headerName: 'Number', width: 90 },
    { field: 'employeeCode', headerName: 'Employee Code', width: 140 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
    { field: 'teamName', headerName: 'Team Name', width: 150 },
    { field: 'roleName', headerName: 'Role', width: 150 },
  ];

  // Map user data to DataGrid rows
  const rows = getUser.map((user, index) => {
    // Log the user to the console
    //-console.log("user", user);

    return {
      number: index + 1,
      id: user._id,
      name: user.name,
      employeeCode: user.employeeCode,
      email: user.email,
      phoneNumber: user.phoneNumber,
      teamName: user.team ? user.team.teamName : "-",
      roleName: user.adminRole?.roleName,
      teamId: user.team ? user.team._id : "-",
      password: user.password,
      roleId: user.adminRole?._id,

    };
  });

  //-console.log("rows", rows);


  // Render the component
  return (
    <React.Fragment>
      <div className="page-header">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i className="fa fa-podcast"></i>
          </span>{" "}
          Users
        </h3>
        <nav aria-label="breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              <span></span>Overview{" "}
              <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
            </li>
          </ul>
        </nav>
      </div>
      <div className="container">
        <div className="col-12">
          {userRole && userRole.pageList.find(page => page.pageName === "Users").access[1] === 1 && (
            <button
              onClick={toggleForm}
              className="btn btn-gradient-primary mb-2"
              type="button"
            >
              Create User
            </button>
          )}
        </div>
        <div
          className={`offcanvas offcanvas-end w-50 ${showForm ? "show" : ""}`}
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasExampleLabel">
              {editingUsers ? "Edit User" : "Create User"}
            </h5>
            <button
              type="button"
              className="btn-close text-reset"
              onClick={toggleForm}
              aria-label="Close"
            ></button>
          </div>
          <div className="offcanvas-body p-4">
            <form
              onSubmit={handleSubmitUserDetails}
              style={{
                display: showForm || (editingUsers ? "block" : "none"),
              }}
            >
              {/* Form fields */}
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your name"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="employeeCode">Employee Code</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter employee code"
                      id="employeeCode"
                      value={employeeCode}
                      onChange={(e) => setEmployeeCode(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <div className="input-group">
                      <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder="Enter your password"
                        id="password"
                        value={password}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <label className="input-group-text" onClick={togglePasswordVisibility} aria-label={showPassword ? 'Hide password' : 'Show password'}>{showPassword ? '🙈' : '👁️'}</label>
                    </div>
                    {passwordFormatError && (
                      <p style={{ color: 'red' }}>{passwordFormatError}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <div className="input-group">
                      <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        className="form-control"
                        placeholder="Enter your confirm password"
                        id="confirmPassword"
                        value={confirmPassword}
                        required
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <label className="input-group-text" onClick={toggleConfirmPasswordVisibility} aria-label={showConfirmPassword ? 'Hide confirm password' : 'Show confirm password'}>{showConfirmPassword ? '🙈' : '👁️'}</label>
                    </div>
                    {passwordMatchError && (
                      <p style={{ color: "red" }}>{passwordMatchError}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      id="phoneNumber"
                      value={phoneNumber}
                      required
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Selected Role</label>
                    <select
                      className="form-select"
                      id="selectedRole"
                      value={selectedRole || ""}
                      onChange={(e) => setSelectedRole(e.target.value)}
                    >
                      <option value="">Select role</option>
                      {getAdminRole.map((role) => (
                        <option key={role._id} value={role._id}>
                          {role.roleName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <h4 className="card-title">Select Team</h4>
                  <div className="form-group">
                    <select
                      className="form-select form-control"
                      value={selectedTeam}
                      onChange={(e) => setSelectedTeam(e.target.value)}
                    >
                      <option value="">Select Team</option>
                      {getTeams.map((team) => (
                        <option key={team._id} value={team._id}>
                          {team.teamName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <Loader isLoading={isLoading} />
              <div className="row text-start w-75">
                <div className="col-md-3 text-start">
                  {userRole && userRole.pageList.find(page => page.pageName === "Users").access[2] === 1 && (
                    <button type="submit" className="btn btn-sm btn-primary">
                      {editingUsers ? "Update User" : "Add User"}
                    </button>
                  )}
                </div>
                <div className="col-md-3 align-items-center">
                  {userRole?.pageList.some(page => page.pageName === "Users" && page.access[3] === 1) && editingUsers && (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={handleDeleteUser}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        {showAlert.visible && (
          <div
            className={`alert alert-${showAlert.type} alert-dismissible fade show`}
            role="alert"
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              zIndex: 1050
            }}
          >
            <strong>{showAlert.message}</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setShowAlert({ ...showAlert, visible: false })}
            ></button>
          </div>
        )}

        <div className="row mt-4">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body table-responsive">
                <h4 className="card-title">List of Users</h4>
                <Paper sx={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    sx={{ border: 0 }}
                    onRowDoubleClick={(params) => handleEditingUsers(params.row)}
                  />
                </Paper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
};

export default CreateUser;
