import React, { useState, useEffect } from "react";
import api from "../Api";
import moment from "moment";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";


const ProductBasedReport = () => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [selectedProductCategory, setSelectedProductCategory] = useState("");
    const [getSelectedProductReport, setGetSelectedProductReport] = useState([]);
    const [getProductCategory, setGetProductCategory] = useState([]);
    const [getCustomerCreation, setGetCustomerCreation] = useState([]);
    const [getProduct, setGetProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState("");
    const [expandedRowIndex, setExpandedRowIndex] = useState(null);
    const [getUnit, setGetUnit] = useState([]);
    const [getProductType, setGetProductType] = useState([]);


    useEffect(() => {
        const fetchUnits = async () => {
            try {
                const response = await api.get("/unit");
                setGetUnit(response.data);
            } catch (error) {
                //-console.log(error);
            }
        };
        fetchUnits();
    }, []);


    useEffect(() => {
        const fetchProductType = async () => {
            try {
                const response = await api.get("/productTypes");
                setGetProductType(response.data);
            } catch (error) {
                //-console.log(error);
            }
        };
        fetchProductType();
    }, []);

    useEffect(() => {
        const fetchSelectedProductReport = async () => {
            if (selectedProductCategory || selectedProduct) {
                try {
                    const params = {};

                    if (selectedProductCategory) {
                        params.selectedProductCategory = selectedProductCategory;
                    }

                    if (selectedProduct) {
                        params.selectedProduct = selectedProduct;
                    }

                    const response = await api.get('/productReport', { params });
                    //-console.log('Request params:', params);

                    setGetSelectedProductReport(response.data.filteredProductReportData);
                } catch (error) {
                    //-console.error("Error fetching selected product report:", error);
                }
            } else {
                setGetSelectedProductReport([]);
                setFilteredRows([]);
            }
        };

        if (searchClicked) {
            fetchSelectedProductReport();
        }
    }, [selectedProductCategory, selectedProduct, searchClicked]);

   //-console.log("getSelected", getSelectedProductReport);



    useEffect(() => {
        if (searchClicked) {
            filterRowsByDate(fromDate, toDate);
        }
    }, [getSelectedProductReport, searchClicked]);

    useEffect(() => {
        const fetchProducts = async () => {
            if (selectedProductCategory !== "") {
                try {
                    const response = await api.get(`/product/${selectedProductCategory}`);
                    setGetProduct(response.data);
                } catch (error) {
                    //-console.error("Error fetching products:", error);
                }
            }
        };

        if (selectedProductCategory) {
            fetchProducts();
        } else {
            setGetProduct([]);
        }
    }, [selectedProductCategory]);

    useEffect(() => {
        const fetchCustomerCreation = async () => {
            try {
                const response = await api.get("/customerCreation");
                setGetCustomerCreation(response.data);
            } catch (error) {
                //-console.error('Error fetching customer creation data:', error);
            }
        };
        fetchCustomerCreation();
    }, []);

    useEffect(() => {
        const fetchProductCategory = async () => {
            try {
                const response = await api.get("/productCategory");
                setGetProductCategory(response.data);
            } catch (error) {
                //-console.error("Error fetching product categories:", error);
            }
        };
        fetchProductCategory();
    }, []);

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSearchClick = () => {
        setSearchClicked(true);
        filterRowsByDate(fromDate, toDate);
    };

    const filterRowsByDate = (fromDate, toDate) => {
        //-console.log("fromDate", fromDate);

        const filtered = getSelectedProductReport.filter((item) => {
            const createdAt = moment(item.leadData.createdAt, "YYYY-MM-DD hh:mm A");
            //-console.log("createdAt", createdAt);
            const isBetween = createdAt.isBetween(
                moment(fromDate).startOf("day"),
                moment(toDate).endOf("day"),
                null,
                "[]"
            );
            return isBetween;
        });
        setFilteredRows(filtered);
        //-console.log("Filtered Rows:", filtered);
    };


    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD HH:mm:ss");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };

        setDefaultFromToDates();
    }, []);

    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DDTHH:mm");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        setSelectedProductCategory("");
        setSelectedProduct("");
        setFilteredRows([]);
        setGetSelectedProductReport([]);
    };

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Product Category Based
                </h3>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <label>From Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div className="col-md-2">
                    <label>To Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>
                <div className="col-md-3">
                    <label className="h4" htmlFor="productCategory">Product Category</label>
                    <select
                        className="form-select form-control"
                        id="productCategory"
                        value={selectedProductCategory}
                        required
                        onChange={(e) => setSelectedProductCategory(e.target.value)}
                    >
                        <option value="">Select a product category</option>
                        {getProductCategory.map((productCategory) => (
                            <option key={productCategory._id} value={productCategory._id}>
                                {productCategory.productCategory}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-2">
                    <label className="h4" htmlFor="product">Product</label>
                    <select
                        className="form-select form-control"
                        id="product"
                        value={selectedProduct}
                        required
                        onChange={(e) => setSelectedProduct(e.target.value)}
                    >
                        <option value="">Select a product</option>
                        {getProduct.map((product) => (
                            <option key={product._id} value={product._id}>
                                {product.product}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3 mt-4">
                    <button
                        className="btn btn-primary me-2"
                        onClick={handleSearchClick}
                    >
                        Search Date
                    </button>
                    {searchClicked && (
                        <button
                            onClick={handleClearFilter}
                            className="btn btn-sm badge-gradient-danger"
                            aria-label="Close"
                            style={{ marginBottom: "4px" }}
                        >
                            &times;
                        </button>
                    )}
                </div>
            </div>

            <TableContainer component={Paper} className="mt-4">
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Number</TableCell>
                            <TableCell>Customer Code</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Given Price</TableCell>
                            <TableCell>Expected Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows.map((row, index) => {
                            const customer = getCustomerCreation.find(
                                (customer) => customer._id === row.leadData.name
                            );
                            let rowCounter = index;
                            return row.leadData?.quotationalHistories.flat().map((product, productIdx) => {
                                rowCounter++;
                                // Fetch unit details
                                const selectedUnitObject = getUnit.find(unit => unit._id === product.unit);
                                const unitName = selectedUnitObject ? selectedUnitObject.unit : 'N/A';

                                // Fetch product details
                                const selectedProduct = getProductType.find(prod => prod._id === product.productType);
                                const productName = selectedProduct
                                    ? `${selectedProduct.ProductCategory.productCategory}
                                        ${selectedProduct.product.product} 
                                        ${selectedProduct.productTypes}
                                        ${selectedProduct.brand.brand}`
                                    : 'N/A';

                                return (
                                    <TableRow key={`${row.leadData.name}-${productIdx}`}>
                                        <TableCell>{rowCounter}</TableCell>
                                        <TableCell>{customer?.customerCode || "N/A"}</TableCell>
                                        <TableCell>{customer?.name || "N/A"}</TableCell>
                                        <TableCell>{customer?.mobile || "N/A"}</TableCell>
                                        <TableCell>{productName}</TableCell>
                                        <TableCell>{unitName}</TableCell>
                                        <TableCell>{product.quantity}</TableCell>
                                        <TableCell>{product.givenPrice}</TableCell>
                                        <TableCell>{product.expectedPrice}</TableCell>
                                    </TableRow>
                                );
                            });
                        })}
                    </TableBody>
                </Table>
            </TableContainer>


        </React.Fragment >
    );
};

export default ProductBasedReport;
