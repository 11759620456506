import React, { useState, useEffect } from "react";
import api from "../Api";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { DataGrid } from "@mui/x-data-grid";
import Loader from './Loader';

const CustomerCategory = () => {
  const [customerCategory, setCustomerCategory] = useState("");
  const [getCustomerCategory, setGetCustomerCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [customerWorkNature, setCustomerWorkNature] = useState("");
  const [getCustomerWorkNature, setGetCustomerWorkNature] = useState([]);
  const [getAllWorkNature, setAllWorkNature] = useState([]);
  const [selectedWorkNature, setSelectedWorkNature] = useState("");
  const [services, setServices] = useState("");
  const [getServices, setGetServices] = useState([]);
  const [getTabValue, setTabValue] = useState("1");
  const [getDataTable, setDataTable] = useState({ row: [], column: [] });
  const [editingCustomerCategory, setEditingCustomerCategory] = useState(null);
  const [editingWorkNature, setEditingWorkNature] = useState(null);
  const [editingService, setEditingService] = useState(null);
  const [deleteCustomerCategory, setDeleteCustomerCategory] = useState(null);
  const [deleteWorkNature, setDeleteWorkNature] = useState(null);
  const [deleteServices, setDeleteServices] = useState(null);
  const [showAlert, setShowAlert] = useState({ visible: false, message: '', type: '' });
  

  useEffect(() => {
    if (showAlert.visible) {
      const timer = setTimeout(() => {
        setShowAlert(prev => ({ ...prev, visible: false }));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert.visible])

  const [isLoading, setIsLoading] = useState(false);

  const handleCustomerCategoryChange = (e) => {
    setCustomerCategory(e.target.value);
  };
  
  const handleCustomerWorkNatureChange = (e) => {
    setCustomerWorkNature(e.target.value);
  };
  const handleServicesChange = (e) => {
    setServices(e.target.value);
  };

  const handleSubmitCustomerCategory = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const isDuplicate = getCustomerCategory.some(category => category.customerCategory === customerCategory && (!editingCustomerCategory || category._id !== editingCustomerCategory));

    if (isDuplicate) {
      setIsLoading(false);
      setShowAlert({ visible: true, message: 'Customer category already exists', type: 'danger' });
      return;
    }

    try {
      if (!customerCategory.trim()) {
        alert("Please enter a valid customer category.");
        return;
      }

      if (editingCustomerCategory) {
        await api.put(`/customerCategory/${editingCustomerCategory}`, {
          customerCategory: customerCategory.trim(),
        });
        setEditingCustomerCategory(null);
      } else {
        await api.post("/customerCategory", {
          customerCategory: customerCategory.trim(),
        });
      }

      setCustomerCategory("");
      await fetchCustomerCategories();
      setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });

    } catch (error) {
      if (error.response && error.response.status === 409) {
        // Handle duplicate category error
      } else {
        //-console.error("Error saving customer category:", error);
        setShowAlert({ visible: true, message: 'Error occurred while submitting customer category. Please try again.', type: 'danger' });
      }
    } finally {
      setIsLoading(false);
    }
  };



  const handleDeleteCustomerCategory = async (e) => {

    e.preventDefault();
    if (!window.confirm("Are you sure you want to delete this entry?")) {
      return;
    }
    try {
      setIsLoading(true);
      // Make the API call to delete the item
      const response = await api.delete(`/customerCategory/${deleteCustomerCategory}`);

      if (response.status === 200) {
        setEditingCustomerCategory(null);
        setCustomerCategory("");
        fetchCustomerCategories();
        setIsLoading(false);
        setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
      } else {
        //-console.error("Failed to delete the item. Server returned status:", response.status);
        throw new Error("Failed to delete the item. Please try again later.");
      }
    } catch (error) {
      //-console.error("Error deleting item:", error);
      setShowAlert({ visible: true, message: 'Error occurred while deleting customer category', type: 'danger' });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCustomerCategories = async () => {
    try {
      const response = await api.get("/customerCategory");
      setGetCustomerCategory(response.data);
      //-console.log("fetching customer categories:", response.data);
    } catch (error) {
      //-console.log("Error fetching customer categories:", error);
    }
  };

  const fetchWorkNature = async () => {
    try {
      const response = await api.get(`/customerWorkNature/${selectedCategory}`);
      setGetCustomerWorkNature(response.data);
    } catch (error) {
      console.error("Error fetching work nature data:", error);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      fetchWorkNature();
    }
  }, [selectedCategory]);

  const fetchAllhWorkNature = async () => {
    try {
      const allWorkNature = await api.get(`/customerWorkNature`);
      setAllWorkNature(allWorkNature.data);
    } catch (error) {
      //-console.error("Error fetching work nature data:", error);
    }
  };

  useEffect(() => {
    fetchAllhWorkNature();
  }, [getTabValue]);


  const handleSubmitWorkNature = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (selectedCategory && customerWorkNature) {
        // If we have an editingWorkNature, we are in update mode
        if (editingWorkNature) {


          const response = await api.put(`/customerWorkNature/${editingWorkNature}`, {
            category: selectedCategory,
            workNature: customerWorkNature,
          });
          //-console.log("edit", response.data);
        } else {
          // Otherwise, we are in create mode
          const response = await api.post("/customerWorkNature", {
            category: selectedCategory,
            workNature: customerWorkNature,
          });
          //-console.log(response.data);
        }

        setCustomerWorkNature("");
        setSelectedCategory("");
        setEditingWorkNature(null);
        setIsLoading(false);
        fetchAllhWorkNature();
        setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
      }
    } catch (error) {
      //("Error handling work nature:", error);
      setShowAlert({ visible: true, message: 'Error occurred while submit workNature', type: 'danger' });
    }
  };

  const handleDeleteWorkNature = async (e) => {
    // Confirm the deletion action
    e.preventDefault();
    if (!window.confirm("Are you sure you want to delete this entry?")) {
      return;
    }
    try {
      setIsLoading(true);

      // Make the API call to delete the item
      const response = await api.delete(`/customerWorkNature/${deleteWorkNature}`);

      if (response.status === 200) {
        setEditingWorkNature(null);
        setSelectedCategory("");
        setCustomerWorkNature("")
        fetchCustomerCategories();
        fetchAllhWorkNature();
        setIsLoading(false);
        setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
      } else {
        //-console.error("Failed to delete the item. Server returned status:", response.status);
        throw new Error("Failed to delete the item. Please try again later.");
      }
    } catch (error) {
      //-console.error("Error deleting item:", error);
      setShowAlert({ visible: true, message: 'Error occurred while deleting customer workNature', type: 'danger' });
    } finally {
      setIsLoading(false);
    }
  };



  const fetchServices = async () => {
    try {
      const response = await api.get(`/services`);
      setGetServices(response.data);
    } catch (error) {
      //-console.error("Error fetching services:", error);
    }
  };



  const handleSubmitServices = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (selectedCategory && selectedWorkNature && services) {
        if (editingService) {

          const response = await api.put(`/services/${editingService}`, {
            customerCategory: selectedCategory,
            workNature: selectedWorkNature,
            services: services,
          });
          //-console.log("edit", response.data);
        } else {
          // Otherwise, we are in create mode
          const response = await api.post("/services", {
            customerCategory: selectedCategory,
            workNature: selectedWorkNature,
            services: services,
          });
          //-console.log(response.data);
        }

        setSelectedCategory("");
        setSelectedWorkNature("");
        setServices("");
        setIsLoading(false);
        setEditingService(null);
        fetchCustomerCategories();
        fetchAllhWorkNature();
        fetchServices();
        setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
      }
    } catch (error) {
      //("Error handling work nature:", error);
      setShowAlert({ visible: true, message: 'Error occurred while submit services', type: 'danger' });
    }
  };


  const handleDeleteServices = async (e) => {

    e.preventDefault();
    if (!window.confirm("Are you sure you want to delete this entry?")) {
      return;
    }

    try {
      setIsLoading(true);
      // Make the API call to delete the item
      const response = await api.delete(`/services/${deleteServices}`);

      if (response.status === 200) {
        setEditingService(null);
        setCustomerCategory("");
        fetchCustomerCategories();
        fetchAllhWorkNature();
        fetchServices();
        setIsLoading(false);
        setShowAlert({ visible: true, message: 'Submitted successfully!', type: 'success' });
      } else {
        //-console.error("Failed to delete the item. Server returned status:", response.status);
        throw new Error("Failed to delete the item. Please try again later.");
      }
    } catch (error) {
      //-console.error("Error deleting item:", error);
      setShowAlert({ visible: true, message: 'Error occurred while deleting services', type: 'danger' });
    } finally {
      setIsLoading(false);
    }
  };



  const newDataTable = (newValue) => {
    newValue = parseInt(newValue);
    var tcol = [];
    var trow = [];
    switch (newValue) {
      case 1:
        tcol = [
          { headerName: "Number", field: "sno", width: 100 },
          { headerName: "Customer Category", field: "cc", width: 150 },
        ];
        trow = [];
        {
          getCustomerCategory.map((item, index) =>
            trow.push({
              id: item._id,
              sno: index + 1,
              cc: item.customerCategory,
            })
          );
        }
        setDataTable({ row: trow, column: tcol });
        //-console.log(getCustomerCategory);
        break;
      case 2:
        tcol = [
          { headerName: "Number", field: "sno", width: 100 },
          { headerName: "Customer Category", field: "cc", width: 150 },
          { headerName: "Customer WorkNature", field: "cw", width: 180 },
        ];
        trow = [];
        {
          getAllWorkNature.map((item, index) =>
            trow.push({
              id: item._id,
              sno: index + 1,
              cc: item.category ? item.category.customerCategory : "",
              cw: item.workNature,
              ccId: item.category ? item.category._id : "",
            })
          );
        }
        setDataTable({ row: trow, column: tcol });
        break;
      case 3:
        tcol = [
          { headerName: "Number", field: "sno", width: 100 },
          { headerName: "Customer Category", field: "cc", width: 150 },
          { headerName: "Customer WorkNature", field: "cw", width: 180 },
          { headerName: "Services", field: "cs", width: 150 },
        ];
        trow = [];
        {
          getServices.map((item, index) =>
            trow.push({
              id: item._id,
              sno: index + 1,
              cc: item.customerCategory ? item.customerCategory.customerCategory : "",
              cw: item.workNature ? item.workNature.workNature : "",
              cs: item.services,
              ccId: item.customerCategory ? item.customerCategory._id : "",
              cwId: item.workNature ? item.workNature._id : "",
            })
          );
        }
        setDataTable({ row: trow, column: tcol });
        break;
    }
  };

  useEffect(() => {
    fetchCustomerCategories();
    fetchServices();
  }, []);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    newDataTable(newValue);
  };

  const handleRowDoubleClick = (params) => {

    const row = params.row;
    if (row) {
      //-console.log("Row data:", row);
    } else {
      //-console.error("Row data is undefined or null");
    }

    //("Current tab value:", getTabValue);

    switch (getTabValue) {
      case "1":
        //("Handling Customer Category");
        handleEditingCustomerCategory(row);
        break;
      case "2":
        //("Handling Work Nature");
        handleEditingWorkNature(row);
        break;
      case "3":
        //("Handling Service");
        handleEditingService(row);
        break;
      default:
        //-console.error("Unknown tab value");
        break;
    }
  };


  const handleEditingCustomerCategory = (item) => {
    setIsLoading(false);
    //("Customer Category Data:", item);
    setCustomerCategory(item.cc);
    setEditingCustomerCategory(item.id);
    setDeleteCustomerCategory(item.id);
  };

  const handleEditingWorkNature = (item) => {
    //-console.log("Work Nature Data:", item);
    setIsLoading(false);
    setSelectedCategory(item.ccId);
    setCustomerWorkNature(item.cw)
    setEditingWorkNature(item.id)
    setDeleteWorkNature(item.id)
  };

  const handleEditingService = (item) => {
    //-console.log("Service Data:", item);
    setIsLoading(false);
    setSelectedCategory(item.ccId);
    setSelectedWorkNature(item.cwId);
    setServices(item.cs);
    setEditingService(item.id)
    setDeleteServices(item.id)
  };

  // Fetch data when component mounts or tab changes
  useEffect(() => {
    fetchCustomerCategories();
    fetchServices();
  }, []);

  useEffect(() => {
    newDataTable(getTabValue);
  }, [getCustomerCategory, getAllWorkNature, getServices, getTabValue]);

  return (
    <React.Fragment>
      <div class="page-header">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i class="fa fa-vcard"></i>
          </span>{" "}
          Customer Category
        </h3>
        <nav aria-label="breadcrumb">
          <ul class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
              <span></span>Overview{" "}
              <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
            </li>
          </ul>
        </nav>
      </div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={getTabValue}>
          <Box>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="secondary"
              indicatorColor="secondary"
            >
              <Tab label="Catogery" value="1" />
              <Tab label="Work Nature" value="2" />
              <Tab label="Service" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ paddingX: "0px" }}>
            {/* Custom Category */}

            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <form
                  className="form-inline"
                  onSubmit={handleSubmitCustomerCategory}
                >
                  <div className="card-body row">
                    <div className="col-sm">
                      <input
                        type="text"
                        className="form-control mb-2 mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder="Customer Category"
                        value={customerCategory}
                        onChange={handleCustomerCategoryChange}
                      />
                    </div>
                    <Loader isLoading={isLoading} />
                    <div className="col-sm text-center">
                      <button type="submit" className="btn btn-gradient-primary mb-2">
                        <i className={`fa ${editingCustomerCategory ? 'fa-pencil' : 'fa-send'} me-2`}></i>
                        {editingCustomerCategory ? "Update" : "Add"}
                      </button>
                      {editingCustomerCategory && (
                        <button
                          type="button"
                          className="btn btn-gradient-danger ms-2 mb-2"
                          onClick={handleDeleteCustomerCategory}
                        >
                          <i className="fa fa-trash me-2"></i>
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                </form>
                {showAlert.visible && (
                  <div
                    className={`alert alert-${showAlert.type} alert-dismissible fade show`}
                    role="alert"
                    style={{
                      position: 'fixed',
                      bottom: '20px',
                      right: '20px',
                      zIndex: 1050
                    }}
                  >
                    <strong>{showAlert.message}</strong>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                      aria-label="Close"
                      onClick={() => setShowAlert({ ...showAlert, visible: false })}
                    ></button>
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2" sx={{ paddingX: "0px" }}>
            {/* wrok nature */}
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <form
                  className="form-inline"
                  onSubmit={handleSubmitWorkNature}
                >
                  <div className="card-body row">
                    <div className="col-sm">
                      <select
                        className="form-select form-control"
                        id=""
                        value={selectedCategory || ""}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      >
                        <option value="">Select</option>
                        {getCustomerCategory.map((customerCategory) => (
                          <option
                            key={customerCategory._id}
                            value={customerCategory._id}
                          >
                            {customerCategory.customerCategory}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm">
                      <input
                        type="text"
                        className="form-control mb-2 mr-sm-2"
                        id="inlineFormInputName2"
                        placeholder="Work Nature"
                        value={customerWorkNature || ""}
                        onChange={handleCustomerWorkNatureChange}
                      />
                    </div>
                    <Loader isLoading={isLoading} />
                    <div className="col-sm text-center">
                      <button type="submit" className="btn btn-gradient-primary mb-2">
                        <i className={`fa ${editingWorkNature ? 'fa-pencil' : 'fa-send'} me-2`}></i>
                        {editingWorkNature ? "Update" : "Add"}
                      </button>
                      {editingWorkNature && (
                        <button
                          type="button"
                          className="btn btn-gradient-danger ms-2 mb-2"
                          onClick={handleDeleteWorkNature}
                        >
                          <i className="fa fa-trash me-2"></i>
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {showAlert.visible && (
              <div
                className={`alert alert-${showAlert.type} alert-dismissible fade show`}
                role="alert"
                style={{
                  position: 'fixed',
                  bottom: '20px',
                  right: '20px',
                  zIndex: 1050
                }}
              >
                <strong>{showAlert.message}</strong>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setShowAlert({ ...showAlert, visible: false })}
                ></button>
              </div>
            )}
          </TabPanel>
          <TabPanel value="3" sx={{ paddingX: "0px" }}>
            {/* Services */}
            <div className="col-12 grid-margin stretch-card">
              <div className="card">
                <form
                  className="form-inline"
                  onSubmit={handleSubmitServices}
                >
                  <div className="card-body row">
                    <div className="col-sm col-lg-6">
                      <label htmlFor="categorySelect" className="form-label">
                        Customer category
                      </label>
                      <select
                        className="form-select form-control"
                        id="categorySelect"
                        value={selectedCategory || ""}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      >
                        <option value="">Select a customerCategory </option>
                        {getCustomerCategory.map((category) => (
                          <option key={category._id} value={category._id}>
                            {category.customerCategory}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm col-lg-6">
                      <label htmlFor="workNatureSelect" className="form-label">
                        Work nature
                      </label>
                      <select
                        className="form-select form-control"
                        id="workNatureSelect"
                        value={selectedWorkNature}
                        onChange={(e) => setSelectedWorkNature(e.target.value)}
                      >
                        <option value="">Select a work nature</option>
                        {getCustomerWorkNature.map((work) => (
                          <option key={work._id} value={work._id}>
                            {work.workNature}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm col-lg-6">
                      <form className="form-inline">
                        <label htmlFor="serviceInput" className="form-label">
                          Create services
                        </label>
                        <input
                          type="text"
                          className="form-control mb-2 mr-sm-2"
                          id="serviceInput"
                          placeholder="Enter a service"
                          value={services || ""}
                          onChange={handleServicesChange}
                        />
                      </form>
                    </div>
                    <Loader isLoading={isLoading} />
                    <div className="col-sm text-center mt-4">
                      <button type="submit" className="btn btn-gradient-primary mb-2">
                        <i className={`fa ${editingService ? 'fa-pencil' : 'fa-send'} me-2`}></i>
                        {editingService ? "Update" : "Add"}
                      </button>
                      {editingService && (
                        <button
                          type="button"
                          className="btn btn-gradient-danger ms-2 mb-2"
                          onClick={handleDeleteServices}
                        >
                          <i className="fa fa-trash me-2"></i>
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {showAlert.visible && (
              <div
                className={`alert alert-${showAlert.type} alert-dismissible fade show`}
                role="alert"
                style={{
                  position: 'fixed',
                  bottom: '20px',
                  right: '20px',
                  zIndex: 1050
                }}
              >
                <strong>{showAlert.message}</strong>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setShowAlert({ ...showAlert, visible: false })}
                ></button>
              </div>
            )}
          </TabPanel>
        </TabContext>
      </Box>

      {/* Display table with added customer category */}
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Customer Category</h4>
              <p class="card-description"> Types of customers </p>
              <div style={{ height: 400 }}>
                <DataGrid
                  rows={getDataTable.row}
                  columns={getDataTable.column}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  onRowDoubleClick={handleRowDoubleClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerCategory;
