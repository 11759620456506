import React, { useEffect, useState } from "react";
import api from "../Api";
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';

const LocationManager = () => {
  const [state, setState] = useState("");
  const [getState, setGetState] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [city, setCity] = useState("");
  const [getCity, setGetCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [area, setArea] = useState("");
  const [getPincode, setGetPincode] = useState([]);
  const [selectedDistrictDropdown, setSelectedDistrictDropdown] = useState("Erode");
  const [filteredDistrict, setFilteredDistrict] = useState([]);

  useEffect(() => {
    if (selectedDistrictDropdown !== '') {
      const districtFilter = getPincode.filter(pin => pin.district && pin.district.district === selectedDistrictDropdown);
      setFilteredDistrict(districtFilter);
    } else {
      setFilteredDistrict([]);
    }
  }, [getPincode, selectedDistrictDropdown]);

  const handleState = (e) => {
    setState(e.target.value);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handlePincode = (e) => {
    setPincode(e.target.value);
  };

  useEffect(() => {
    const fetchState = async () => {
      try {
        const response = await api.get("/state");
        setGetState(response.data);
      } catch (error) {
        //-console.error("Error fetching states:", error);
      }
    };
    fetchState();
  }, []);

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await api.get("/city", {
          params: { selectedState },
        });
        setGetCity(response.data);
      } catch (error) {
        //-console.error("Error fetching cities:", error);
      }
    };
    if (selectedState) {
      fetchCity();
    } else {
      setGetCity([]);
    }
  }, [selectedState]);

  useEffect(() => {
    const fetchPincode = async () => {
      try {
        const response = await api.get("/pincode");
       //-console.log(response.data);
        setGetPincode(response.data);
       //-console.log(pincode);
      } catch (error) {
        //-console.error("Error fetching areas:", error);
      }
    };
    fetchPincode();
  }, []);

  const handleAddState = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/state", {
        state: state,
      });
      setState("");
      //-console.log(response.data);
      setGetState([...getState, response.data]);
    } catch (error) {
      //-console.error("Error adding state:", error);
    }
  };

  const handleAddCity = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/city", {
        state: selectedState,
        district: city,
      });
      //-console.log(response.data);
      setSelectedState("");
      setCity("");
      setGetCity([...getCity, response.data]);
    } catch (error) {
      //-console.error("Error adding city:", error);
    }
  };

  const handleAddPincode = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("/pincode", {
        state: selectedState,
        district: selectedCity,
        pincode: pincode,
        area: area,
      });
      setSelectedState("");
      setSelectedCity("");
      setPincode("");
      //-console.log(response.data);
    } catch (error) {
      //-console.error("Error adding pincode:", error);
    }
  };

  const columns = [
    { field: 'state', headerName: 'State', width: 150 },
    { field: 'city', headerName: 'City', width: 150 },
    { field: 'pincode', headerName: 'Pincode', width: 150 },
    { field: 'area', headerName: 'Area', width: 150 },
  ];

  const rows = filteredDistrict.map((item, index) => ({
    id: index,
    state: item.state ? item.state.state : "",
    city: item.district ? item.district.district : "",
    pincode: item.pincode ? item.pincode : "",
    area: item.area ? item.area : "",
  }));

  return (
    <React.Fragment>
      <div className="page-header">
        <h3 className="page-title">
          <span className="page-title-icon bg-gradient-primary text-white me-2">
            <i className="fa fa-map-marker"></i>
          </span>{" "}
          Area Manager
        </h3>
        <nav aria-label="breadcrumb">
          <ul className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              <span></span>Overview{" "}
              <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
            </li>
          </ul>
        </nav>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title"></h4>
              <form className="form-inline" onSubmit={handleAddState}>
                <label className="form-label" htmlFor="">
                  Create State
                </label>
                <input
                  type="text"
                  className="form-control mb-2 mr-sm-2"
                  id="inlineFormInputName2"
                  placeholder="Enter a State"
                  value={state}
                  onChange={handleState}
                />
                <button type="submit" className="btn btn-gradient-primary mb-2">
                  Add State
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Add City */}
        <div className="col-12 col-sm-6 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title"></h4>
              <form className="form-inline" onSubmit={handleAddCity}>
                <div className="form-group">
                  <label className="form-label">Select State</label>
                  <select
                    className="js-example-basic-single form-control"
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                  >
                    <option value="">Select State</option>
                    {getState.map((state) => (
                      <option key={state._id} value={state._id}>
                        {state.state}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label className="sr-only" htmlFor="inlineFormInputName2">
                    Create City
                  </label>
                  <input
                    type="text"
                    className="form-control mb-2 mr-sm-2"
                    id="inlineFormInputName2"
                    placeholder="Enter a City"
                    value={city}
                    onChange={handleCity}
                  />
                </div>
                <button type="submit" className="btn btn-gradient-primary mb-2">
                  Add City
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* pincode */}
      <div className="col-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title"></h4>
            <form className="form-inline" onSubmit={handleAddPincode}>
              <div className="form-group">
                <label>Select State</label>
                <select
                  className="js-example-basic-single form-control"
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <option value="">Select State</option>
                  {getState.map((state) => (
                    <option key={state._id} value={state._id}>
                      {state.state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Select City</label>
                <select
                  className="js-example-basic-single form-control"
                  value={selectedCity}
                  onChange={(e) => setSelectedCity(e.target.value)}
                >
                  <option value="">Select City</option>
                  {getCity.map((city) => (
                    <option key={city._id} value={city._id}>
                      {city.district}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Area Name</label>
                <input
                  type="text"
                  className="form-control mb-2 mr-sm-2"
                  id="inlineFormInputName2"
                  placeholder="Enter a Area "
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Create Pincode</label>
                <input
                  type="text"
                  className="form-control mb-2 mr-sm-2"
                  id="inlineFormInputName2"
                  placeholder="Enter a Pincode"
                  value={pincode}
                  onChange={handlePincode}
                />
              </div>
              <button type="submit" className="btn btn-gradient-primary mb-2">
                Add Pincode
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* DataGrid */}
      <div className="row mt-4">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              {/* dropdown cities */}
              <div className="form-group">
                <label>Select City</label>
                <select
                  className="js-example-basic-single form-control"
                  value={selectedDistrictDropdown}
                  onChange={(e) => setSelectedDistrictDropdown(e.target.value)}
                >
                  {Array.from(new Set(getPincode.map(pin => pin.district && pin.district.district))).map((district, index) => (
                    <option key={index} value={district}>{district}</option>
                  ))}
                </select>
              </div>
              <h4 className="card-title">List of Areas</h4>
              <Paper sx={{ height: 400, width: '100%' }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  sx={{ border: 0 }}
                />
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LocationManager;
