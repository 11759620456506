import React, { useState, useEffect, useCallback } from "react";
import api from "../Api";
import moment from "moment";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const UnderFollowUp = () => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [rows, setGridRows] = useState([]);
    const [selectedReport, setSelectedReport] = useState("");
    const [getLiveMarketing, setGetLiveMarketing] = useState([]);
    const [getLeadOnline, setGetLeadOnline] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [getLeadSource, setGetLeadSource] = useState([]);
    const [selectedLeadSource, setSelectedLeadSource] = useState("All");
    const [getLeadCancelReason, setGetLeadCancelReason] = useState([]);
    const [selectedReason, setSelectedReason] = useState("");


    useEffect(() => {
        const fetchLeadOnline = async () => {
            try {
                const response = await api.get("/leadCreationData");
                //-console.log(response.data);
                setGetLeadOnline(response.data);
            } catch (error) {
                //-console.log(error);
            }
        };
        fetchLeadOnline();
    }, []);

    useEffect(() => {
        const fetchLeadCancelReason = async () => {
            try {
                const response = await api.get("/cancelReason");
                setGetLeadCancelReason(response.data);
            } catch (error) {
                //-console.log(error);
            }
        };
        fetchLeadCancelReason();
    }, []);

    useEffect(() => {
        const fetchLeadSources = async () => {
            try {
                const response = await api.get("/leadSource");
                setGetLeadSource(response.data);
            } catch (error) {
                //-console.error("Error fetching lead sources:", error);
            }
        };
        fetchLeadSources();
    }, []);

    useEffect(() => {
        const fetchLiveMarketing = async () => {
            try {
                const response = await api.get("/liveMarketing");
                setGetLiveMarketing(response.data);
                //-console.log(response.data);

            } catch (error) {
                //-console.log(error);
            }
        };
        fetchLiveMarketing()
    }, []);

    useEffect(() => {
        let temp_rows = [];

        getLiveMarketing.forEach((item, index) => {
            const quotationalHistories = item.quotationalHistories.map((product) => ({
                productType: product.productType,
                unit: product.unit,
                quantity: product.quantity,
                givenPrice: product.givenPrice,
                expectedPrice: product.expectedPrice,
            }));

            temp_rows.push(
                createData(
                    item.name?.name || "",
                    item.assignedTo?.name || "",
                    item.createdAt,
                    item.pincode,
                    quotationalHistories,
                    item
                )
            );
        });

        getLeadOnline.forEach((item, index) => {
            const quotationalHistories = item.quotationalHistories.map((product) => ({
                productType: product.productType,
                unit: product.unit,
                quantity: product.quantity,
                givenPrice: product.givenPrice,
                expectedPrice: product.expectedPrice,
            }));

            temp_rows.push(
                createData(
                    item.name?.name || "",
                    item.assignedTo?.name || "",
                    item.createdAt,
                    item.pincode,
                    quotationalHistories,
                    item
                )
            );
        });

        //-console.log("Constructed Rows:", temp_rows);
        setGridRows(temp_rows);

    }, [getLiveMarketing, getLeadOnline]);


    // Handle date changes
    const handleFromDateChange = (e) => {
        //-console.log("From date changed:", e.target.value);
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        //-console.log("To date changed:", e.target.value);
        setToDate(e.target.value);
    };

    // Handle search click
    const handleSearchClick = () => {
        //-console.log("Search clicked.");
        setSearchClicked(true);
        filterRowsByDate(fromDate, toDate);
    };

    const filterRowsByDate = (fromDate, toDate) => {
        //-console.log("fromDate", fromDate);
        //-console.log("toDate", toDate);

        // Ensure that fromDate and toDate are parsed correctly
        const fromMoment = moment(fromDate).startOf("day");
        const toMoment = moment(toDate).endOf("day");

        //-console.log("fromMoment", fromMoment);
        //-console.log("toMoment", toMoment);

        const filtered = rows.filter((row) => {
            // Parse createdAt according to its format
            const createdAt = moment(row.item.createdAt, "YYYY-MM-DD hh:mm A");
            //-console.log("createdAt", createdAt);

            // Check if createdAt is between fromMoment and toMoment
            const isBetween = createdAt.isBetween(fromMoment, toMoment, null, "[]");
            return isBetween;
        });
        setFilteredRows(filtered);
    };



    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD HH:mm:ss");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };

        setDefaultFromToDates();
    }, []);

    // Handle clear filter
    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DDTHH:mm");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        filterRowsByDate(currentDateFormatted, currentDateFormatted);
        setSelectedLeadSource("All");
        setSelectedReport("FollowUp")
    };

   useEffect(() => {
    const determineStatusFilter = () => {
        switch (selectedReport) {
            case "FollowUp":
                return ["open", "escalation", "quotation"];
            case "Non Converted Leads":
                return ["cancel"];
            case "Converted":
                return ["order success"];
            default:
                return [];
        }
    };

    const newStatusFilter = determineStatusFilter();
    //-console.log("Status Filter:", newStatusFilter);
    setStatusFilter(newStatusFilter);
}, [selectedReport]);


    const getReportFilteredRows = () => {
        if (!searchClicked) return [];

        //-console.log("Filtered Rows Before Processing:", filteredRows);

        return filteredRows.filter((row) => {
            //-console.log("Processing Row:", row);

            const { leadCancelation } = row.item;
            //-console.log("leadCancel", row.item.leadCancelation);

            const statusMatches = statusFilter.includes(row.item.status.leadStatus);
            //-console.log("Status Matches:", statusMatches);

            if (selectedReport === "Non Converted Leads") {
                const lastApprovalStatus =
                    leadCancelation && leadCancelation.length > 0
                        ? leadCancelation[leadCancelation.length - 1]?.status
                        : null;
                //-console.log("Last Approval Status:", lastApprovalStatus);
                return statusMatches && lastApprovalStatus === "approval";
            }
            return statusMatches;
        });
    };


    const getFinalFilteredRows = () => {
        const reportFilteredRows = getReportFilteredRows();


        if (selectedLeadSource && selectedLeadSource !== "All") {
            const liveMarketingSource = getLeadSource.find(source => source.leadSource === "Live Marketing");


            const liveMarketingId = liveMarketingSource ? liveMarketingSource._id : null;

            return reportFilteredRows.filter(row => {

                if (selectedLeadSource === liveMarketingId) {
                    return !row.item.leadSource || row.item.leadSource === liveMarketingId;
                }
                return row.item.leadSource && row.item.leadSource._id === selectedLeadSource;
            });
        }

        if (selectedReport === "Non Converted Leads") {
            return reportFilteredRows.filter(row => {
                if (selectedReason === "All") {
                    return true;
                }
                return row.item.cancelReason === selectedReason;
            });
        }
        return reportFilteredRows;
    };



    const renderRows = () => {
        const ReportFilteredRows = getFinalFilteredRows();
        return ReportFilteredRows.map((row) => <Row key={row.item._id} row={row} />);
    };

    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow
                    sx={{ "& > *": { borderBottom: "unset" } }}
                >
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {row.cus_name}
                    </TableCell>
                    <TableCell>
                        {row.item && row.item.leadSource ? row.item.leadSource.leadSource : "-"}
                    </TableCell>
                    <TableCell>{row.item && row.item.assignedTo ? row.item.assignedTo.name : "-"}</TableCell>
                    <TableCell>{row.lead_date}</TableCell>
                    <TableCell>{row.item.buildingRating}</TableCell>
                    <TableCell>{row.item.customerRating}</TableCell>
                    <TableCell>{row.item.pincode ? row.item.pincode.pincode : ""}</TableCell>
                    <TableCell>{row.item.pincode ? row.item.pincode.area : ""}</TableCell>
                    <TableCell>{row.item.cancelReason}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Products
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Product Name</TableCell>
                                            <TableCell>Unit</TableCell>
                                            <TableCell>Quantity</TableCell>
                                            <TableCell>Given price</TableCell>
                                            <TableCell>Expected Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.quotationalHistories.flat().map((quotation, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    {quotation.productType ? quotation.productType.productTypes : 'N/A'}
                                                </TableCell>
                                                <TableCell>{quotation.unit ? quotation.unit.unit : 'N/A'}</TableCell>
                                                <TableCell>{quotation.quantity || 'N/A'}</TableCell>
                                                <TableCell>{quotation.givenPrice || 'N/A'}</TableCell>
                                                <TableCell>{quotation.expectedPrice || 'N/A'}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Under FollowUp
                </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                            <span></span>Overview{" "}
                            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* Table */}
            <div className="row">
                {/* From Date */}
                <div className="col-md-2">
                    <label>From Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div className="col-md-2">
                    <label>To Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>
                <div className="col-md-3 mb-2">
                    <div className="form-group">
                        {/* Form */}
                        <label htmlFor="inlineFormInputName2">Status Category</label>
                        <select
                            className="form-select form-control"
                            value={selectedReport}
                            onChange={(e) => setSelectedReport(e.target.value)}
                            required
                        >
                            <option selected value="FollowUp">FollowUp</option>
                            <option value="Non Converted Leads">Non Converted Leads</option>
                            <option value="Converted">Converted Leads</option>
                        </select>
                    </div>
                </div>
                {selectedReport === "Non Converted Leads" && (
                    <div className="col-md-3 mb-2">
                        <div className="form-group">
                            {/* Form */}
                            <label htmlFor="inlineFormInputName2">Cancel reason</label>
                            <select
                                className="form-select form-control"
                                value={selectedReason}
                                onChange={(e) => setSelectedReason(e.target.value)}
                                required
                            >
                                <option selected value="All">All</option>
                                {getLeadCancelReason[0]?.cancelReason.map((reason, index) => (
                                    <option key={index} value={reason}>
                                        {reason}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )}
                {/* Select the Lead Soucrce */}
                <div className="col-md-3 mb-2">
                    <div className="form-group">
                        {/* Form */}
                        <label htmlFor="inlineFormInputName2">Lead Source</label>
                        <select
                            className="form-select form-control"
                            value={selectedLeadSource}
                            onChange={(e) => setSelectedLeadSource(e.target.value)}
                        >
                            <option selected value="All">All</option>
                            {getLeadSource.map((source) => (
                                <option key={source._id} value={source._id}>
                                    {source.leadSource}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="col-md-2">
                    <button
                        className="btn btn-primary mt-4"
                        onClick={handleSearchClick}
                    >
                        Search Date
                    </button>
                    {searchClicked && (
                        <button
                            onClick={handleClearFilter}
                            className="btn btn-sm badge-gradient-danger"
                            aria-label="Close"
                            style={{ marginLeft: "10px", marginTop: "30px" }}
                        >
                            &times;
                        </button>
                    )}
                </div>

                {/* Table display */}
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Customer Name</TableCell>
                                <TableCell>Lead Source</TableCell>
                                <TableCell>Assigned To</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Customer Rating</TableCell>
                                <TableCell>Building Rating</TableCell>
                                <TableCell>Pincode</TableCell>
                                <TableCell>City</TableCell>
                                {selectedReport === "Non Converted Leads" && <TableCell>Reason</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {renderRows()}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </React.Fragment>
    );
};

function createData(
    cus_name,
    lead_source,
    lead_date,
    pincode,
    quotationalHistories,
    item
) {
    return {
        cus_name,
        lead_source,
        lead_date,
        pincode,
        quotationalHistories,
        item,
    };
}

export default UnderFollowUp;
