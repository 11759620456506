import React, { useState, useEffect } from "react";
import api from "../Api";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const CustomerAgeing = () => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [getCustomerAgeing, setGetCustomerAgeing] = useState([]);
    const [getCustomerCreation, setGetCustomerCreation] = useState([]);
    const [searchName, setSearchName] = useState("");
    const [getUnit, setGetUnit] = useState([]);
    const [getProductType, setGetProductType] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);

    useEffect(() => {
        const fetchCustomerAgeing = async () => {
            try {
                const response = await api.get('/customerAgeing');
                setGetCustomerAgeing(response.data.filteredCustomerAgeingData);
            } catch (error) {
                //-console.error('Error fetching customer ageing:', error);
            }
        };
        fetchCustomerAgeing();
    }, []);

    useEffect(() => {
        const fetchUnits = async () => {
            try {
                const response = await api.get("/unit");
                setGetUnit(response.data);
            } catch (error) {
                //-console.log(error);
            }
        };
        fetchUnits();
    }, []);

    useEffect(() => {
        const fetchProductType = async () => {
            try {
                const response = await api.get("/productTypes");
                setGetProductType(response.data);
            } catch (error) {
                //-console.log(error);
            }
        };
        fetchProductType();
    }, []);

    useEffect(() => {
        const fetchCustomerCreation = async () => {
            try {
                const response = await api.get("/customerCreation");
                setGetCustomerCreation(response.data);
            } catch (error) {
                //-console.error('Error fetching customer creation data:', error);
            }
        };
        fetchCustomerCreation();
    }, []);

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSearchClick = () => {
        setSearchClicked(true);
        filterRowsByDate(fromDate, toDate);
    };

    const filterRowsByDate = (fromDate, toDate) => {
        const filtered = getCustomerAgeing.filter((item) => {
            const createdAt = moment(item.leadData.createdAt, "YYYY-MM-DD hh:mm A");
            return createdAt.isBetween(
                moment(fromDate).startOf("day"),
                moment(toDate).endOf("day"),
                null,
                "[]"
            );
        });
        setFilteredRows(filtered);
    };

    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DD");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };
        setDefaultFromToDates();
    }, []);

    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DD");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        setFilteredRows([]);
        setSearchName("");
        setSelectedCustomerId(null);

    };

    const filteredCustomers = getCustomerCreation.filter((customer) => {
        const searchLower = searchName.toLowerCase();
        const name = customer?.name?.toLowerCase() || '';
        const alterMobile = customer?.alterMobile || '';
        const mobile = customer?.mobile || '';
        const customerCode = customer?.customerCode || '';
        return (
            name.includes(searchLower) ||
            alterMobile.includes(searchName) ||
            mobile.includes(searchName) ||
            customerCode.includes(searchName)
        );
    });

    const handleNameSelect = (customer) => {
        setSearchName(
            `${customer.name} - ${customer.mobile} - ${customer.alterMobile} - ${customer.customerCode}`
        );
        setSelectedCustomerId(customer._id);
    };

    // Calculate frequency only for consecutive rows
    const calculateFrequency = (rows) => {
        return rows.map((row, index) => {
            if (index === 0) return "N/A"; // No frequency for the first row
            const prevDate = moment(rows[index - 1].leadData.createdAt, "YYYY-MM-DD hh:mm A");
            const currDate = moment(row.leadData.createdAt, "YYYY-MM-DD hh:mm A");
            const diffDays = currDate.diff(prevDate, 'days');
            return `${diffDays} days`;
        });
    };

    // Sorting rows by date to ensure correct frequency calculation
    const sortedRows = [...filteredRows].sort((a, b) =>
        moment(a.leadData.createdAt).diff(moment(b.leadData.createdAt))
    );

    // Filtering rows for the selected customer
    const rowsForSelectedCustomer = selectedCustomerId
        ? sortedRows.filter(row => row.leadData.name === selectedCustomerId)
        : sortedRows;

    // Calculating frequency after sorting rows
    const frequencyDays = calculateFrequency(rowsForSelectedCustomer);

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Customer Ageing
                </h3>
            </div>
            <div className="row">
                <div className="col-md-3">
                    <label>From Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div className="col-md-3">
                    <label>To Date</label>
                    <input
                        type="date"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>
                <div className="col-md-3">
                    <div className="d-flex gap-2">
                        <div className="form-group w-100">
                            <label>Customer Name</label>
                            <input
                                type="text"
                                placeholder="Search a customer name"
                                value={searchName}
                                onChange={(e) => setSearchName(e.target.value)}
                                className="form-control mb-2"
                                required
                            />
                            {searchName && (
                                <div className="form-group">
                                    <ul>
                                        {filteredCustomers.map((customer) => (
                                            <li
                                                key={customer._id}
                                                onClick={() => handleNameSelect(customer)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {`${customer.name} - ${customer.alterMobile} - ${customer.mobile} - ${customer.customerCode}`}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-3 mt-4">
                    <button
                        className="btn btn-primary me-2"
                        onClick={handleSearchClick}
                    >
                        Search Date
                    </button>
                    {searchClicked && (
                        <button
                            onClick={handleClearFilter}
                            className="btn btn-sm badge-gradient-danger"
                            aria-label="Close"
                            style={{ marginBottom: "4px" }}
                        >
                            &times;
                        </button>
                    )}
                </div>
            </div>

            <TableContainer component={Paper} className="mt-4">
                <Table aria-label="collapsible table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Number</TableCell>
                            <TableCell>Customer Code</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Mobile</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Unit</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Given Price</TableCell>
                            <TableCell>Expected Price</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Frequency (Days)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchName && rowsForSelectedCustomer.length > 0 ? (
                            rowsForSelectedCustomer.map((row, index) => {
                                const customer = getCustomerCreation.find(customer => customer._id === row.leadData.name);
                                const selectedUnitObject = getUnit.find(unit => unit._id === row.product.unit);
                                const unitName = selectedUnitObject ? selectedUnitObject.unit : 'N/A';

                                const selectedProduct = getProductType.find(prod => prod._id === row.product.productType);
                                const productName = selectedProduct
                                    ? `${selectedProduct.ProductCategory.productCategory} ${selectedProduct.product.product} ${selectedProduct.productTypes} ${selectedProduct.brand.brand}`
                                    : 'N/A';

                                return (
                                    <TableRow key={row._id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{customer?.customerCode}</TableCell>
                                        <TableCell>{customer?.name}</TableCell>
                                        <TableCell>{customer?.mobile}</TableCell>
                                        <TableCell>{productName}</TableCell>
                                        <TableCell>{unitName}</TableCell>
                                        <TableCell>{row.product.quantity}</TableCell>
                                        <TableCell>{row.product.givenPrice}</TableCell>
                                        <TableCell>{row.product.expectedPrice}</TableCell>
                                        <TableCell>
                                            {moment(row.leadData.createdAt).format("YYYY-MM-DD hh:mm A")}
                                        </TableCell>
                                        <TableCell>{frequencyDays[index]}</TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            filteredRows.map((row, index) => {
                                const customer = getCustomerCreation.find(customer => customer._id === row.leadData.name);
                                const selectedUnitObject = getUnit.find(unit => unit._id === row.product.unit);
                                const unitName = selectedUnitObject ? selectedUnitObject.unit : 'N/A';

                                const selectedProduct = getProductType.find(prod => prod._id === row.product.productType);
                                const productName = selectedProduct
                                    ? `${selectedProduct.ProductCategory.productCategory} ${selectedProduct.product.product} ${selectedProduct.productTypes} ${selectedProduct.brand.brand}`
                                    : 'N/A';

                                return (
                                    <TableRow key={row._id}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{customer?.customerCode}</TableCell>
                                        <TableCell>{customer?.name}</TableCell>
                                        <TableCell>{customer?.mobile}</TableCell>
                                        <TableCell>{productName}</TableCell>
                                        <TableCell>{unitName}</TableCell>
                                        <TableCell>{row.product.quantity}</TableCell>
                                        <TableCell>{row.product.givenPrice}</TableCell>
                                        <TableCell>{row.product.expectedPrice}</TableCell>
                                        <TableCell>
                                            {moment(row.leadData.createdAt).format("YYYY-MM-DD hh:mm A")}
                                        </TableCell>
                                        <TableCell>{frequencyDays[index]}</TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
};

export default CustomerAgeing;
