import React from "react";
import api from "../Api";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import "./LeadCreation.css";
import moment from "moment";
import "react-datetime/css/react-datetime.css";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import QuotationGenerator from "./QuotationGenerator";
import Loader from './Loader';


const LeadCreationOnline = ({ adminId, adminName }) => {
  const [filters, setFilters] = useState({
    name: '',
    source: '',
    date: null,
    pincode: '',
    area: ''
  });
  const [getProductCategory, setGetProductCategory] = useState([]);
  const [getLeadCancelReason, setGetLeadCancelReason] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");
  const [selectedProductDescription, setSelectedProductDescription] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState([]);
  const [selectedProdCatProduct, setSelectedProdCatProduct] = useState([]);
  const [showProdSuggestModal, setShowProdSuggestModal] = useState(false);
  const [alertMessages, setAlertMessages] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [shouldShowButton, setShouldShowButton] = useState(true);
  const [getTabValue, setTabValue] = useState("1");
  const [getLeadSource, setGetLeadSource] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState("");
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [areas, setAreas] = useState([]);
  const [selectedAreaName, setSelectedAreaName] = useState("");
  const [address, setAddress] = useState("");
  const [getCustomerCreation, setGetCustomerCreation] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [searchReference, setSearchReference] = useState("");
  const [getUnit, setGetUnit] = useState([]);
  const [dropdowns, setDropdowns] = useState([]);
  const [hoveredCustomer, setHoveredCustomer] = useState("");
  const [searchProduct, setSearchProduct] = useState("");
  const [getProductType, setGetProductType] = useState([]);
  const [selectedProductType, setSelectedproductType] = useState([]);
  const [getLeadOnline, setGetLeadOnline] = useState([]);
  const [showClearButton, setShowClearButton] = useState(false);
  const [showRefButton, setShowRefButton] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedRefId, setSelectedRefId] = useState(null);
  const [getUser, setGetUser] = useState([]);
  const [getTeams, setGetTeams] = useState([]);
  const [selectedTeamUser, setSelectedTeamUser] = useState("");
  const [getPincode, setGetPincode] = useState([]);
  const [showLeadForm, setShowLeadForm] = useState(false);
  const [getAdminRole, setGetAdminRole] = useState([]);
  const [editingLead, setEditingLead] = useState(null);
  const [deleteLead, setDeleteLead] = useState(null);
  const [isLeadChecked, setIsLeadChecked] = useState(true);
  const [getLastLead, setGetLastLead] = useState(null);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [isEscalateChecked, setIsEscalateChecked] = useState(
    Array(additionalFields.length).fill(false)
  );
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(true);
  const [selectedEscalationUser, setSelectedEscalationUser] = useState("");
  const [rows, setGridRows] = useState([]);
  const [getLeadStatus, setGetLeadStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [givenPrice, setGivenPrice] = useState("");
  const [expectedPrice, setExpectedPrice] = useState("");
  const [getAudioFile, setAudioFile] = useState();
  const [getFollowUp, setGetFollowUp] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const [fromDate1, setFromDate1] = useState("");
  const [toDate1, setToDate1] = useState("");
  const [searchClicked1, setSearchClicked1] = useState(false);
  const [filteredRows1, setFilteredRows1] = useState([]);

  const [fromDate2, setFromDate2] = useState('');
  const [toDate2, setToDate2] = useState('');
  const [searchClicked2, setSearchClicked2] = useState(false);
  const [filteredRows2, setFilteredRows2] = useState([]);

  const [fromDate3, setFromDate3] = useState('');
  const [toDate3, setToDate3] = useState('');
  const [searchClicked3, setSearchClicked3] = useState(false);
  const [filteredRows3, setFilteredRows3] = useState([]);

  const [fromDate4, setFromDate4] = useState('');
  const [toDate4, setToDate4] = useState('');
  const [searchClicked4, setSearchClicked4] = useState(false);
  const [filteredRows4, setFilteredRows4] = useState([]);

  const [fromDate5, setFromDate5] = useState('');
  const [toDate5, setToDate5] = useState('');
  const [searchClicked5, setSearchClicked5] = useState(false);
  const [filteredRows5, setFilteredRows5] = useState([]);

  const [leadTLApprovalBy, setLeadTLApprovalBy] = useState("");
  const [leadAdminApprovalBy, setLeadAdminApprovalBy] = useState("");
  const [isProjectChecked, setIsProjectChecked] = useState("");
  const [QModal, setQModal] = useState(false);
  const [getTransferUserLead, setGetTransferUserLead] = useState([]);
  const [showQuotationHistory, setShowQuotationHistory] = useState(false);
  const [quotationalHistories, setQuotationalHistories] = useState([]);
  const [quotationStatusId, setQuotationStatusId] = useState(null);

  const [getAddTabValue, setAddTabValue] = useState("1");
  const [tabAddIndex, setAddTabIndex] = useState(0);
  const [cancelStatusId, setCancelStatusId] = useState(null);
  const [customerCount, setCustomerCount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [getPincodeObjectId, setGetPincodeObjectId] = useState(null);
  const [userRole, setUserRole] = useState(null);


  useEffect(() => {
    if (showAlert.visible) {
      const timer = setTimeout(() => {
        setShowAlert(prev => ({ ...prev, visible: false }));
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert.visible])


  useEffect(() => {
    if (alertMessages.length) {
      const timer = setTimeout(() => {
        setAlertMessages((prev) => prev.slice(1));
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [alertMessages]);


  useEffect(() => {
    const fetchLeadCancelReason = async () => {
      try {
        const response = await api.get("/cancelReason");
        setGetLeadCancelReason(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchLeadCancelReason();
  }, []);

  const selectedAreaObjectId = async () => {
    try {
      const response = await api.get(`/selectedArea/${selectedAreaName}`);
      setGetPincodeObjectId(response.data.pincodeObjectIds);
    } catch (error) {
      //-console.error("Error fetching pincode object ID:", error);
    }
  };

  useEffect(() => {
    selectedAreaObjectId();
  }, [selectedAreaName]);

  useEffect(() => {
    const fetchProductCategory = async () => {
      try {
        const response = await api.get("/productCategory");
        setGetProductCategory(response.data);
      } catch (error) {
        //-console.error("Error fetching product categories:", error);
      }
    };
    fetchProductCategory();
  }, []);


  const handleChangeTab = (index) => {
    setTabIndex(index);
  };

  // Quotation Modal
  const closeQModal = () => {
    setQModal(false);
  };

  const openQModal = () => {
    setQModal(true);
  };


  const handleProductSuggest = () => {
    setShowProdSuggestModal(true);
  };

  const handleClose = () => {
    setShowProdSuggestModal(false);
  };


  useEffect(() => {

    const setDefaultFromToDates = () => {
      const currentDateFormatted = moment().format("YYYY-MM-DD");
      setFromDate1(currentDateFormatted);
      setFromDate2(currentDateFormatted);
      setFromDate3(currentDateFormatted);
      setFromDate4(currentDateFormatted);
      setFromDate5(currentDateFormatted);
      setToDate1(currentDateFormatted);
      setToDate2(currentDateFormatted);
      setToDate3(currentDateFormatted);
      setToDate4(currentDateFormatted);
      setToDate5(currentDateFormatted);
    };

    setDefaultFromToDates();
  }, []);

  useEffect(() => {
    const fetchLeadStatus = async () => {
      try {
        const response = await api.get("/leadStatus");
        const leadStatuses = response.data;

        // Find the lead status object with leadStatus 'quotation'
        const quotationStatus = leadStatuses.find(status => status.leadStatus === 'quotation');
        const cancelationStatus = leadStatuses.find(status => status.leadStatus === 'cancel');

        if (cancelationStatus) {
          // If found, you can access its _id
          const cancelationStatusId = cancelationStatus._id;
          setCancelStatusId(cancelationStatusId);
        }

        if (quotationStatus) {
          // If found, you can access its _id
          const quotationStatusId = quotationStatus._id;
          setQuotationStatusId(quotationStatusId);
          //-console.log(quotationStatusId);
        } else {
          //-console.log("Lead status 'quotation' not found.");
        }
        setGetLeadStatus(leadStatuses);
      } catch (error) {
        //-console.error("Error fetching User:", error);
      }
    };
    fetchLeadStatus();
  }, []);


  const fetchFollowUp = async () => {
    try {
      const response = await api.get("/findFollowUpData");
      setGetFollowUp(response.data);
    } catch (error) {
      //-console.log(error);
    }
  };

  useEffect(() => {
    fetchFollowUp();
  }, []);

  useEffect(() => { }, [getFollowUp]);


  useEffect(() => {
    const fetchTransferUserLead = async () => {
      try {
        const response = await api.get("/transferUserLead");
        //-console.log("transfer", response.data);
        setGetTransferUserLead(response.data.transferTo);
        //-console.log(getTransferUserLead);
      } catch (error) {
        //-console.error("Error fetching transfer user:", error);
      }
    };
    fetchTransferUserLead();
  }, []);

  useEffect(() => {
    const fetchLastLead = async () => {
      try {
        const response = await api.get("/findLastLead");
        setGetLastLead(response.data.assignedTo);
      } catch (error) {
        //-console.error("Error fetching admin roles:", error);
      }
    };
    fetchLastLead();
  }, []);

  const handleCheckboxChange = (event) => {
    setIsLeadChecked(event.target.checked);
    if (event.target.checked) {
      setSelectedTeamUser("");
    }
  };

  const handleEscaltionCheckbox = (index, checked) => {
    const updatedEscalationCheck = [...isEscalateChecked];
    updatedEscalationCheck[index] = checked;
    setIsEscalateChecked(updatedEscalationCheck);

    setIsDropdownDisabled(!checked);
  };

  const toggleLeadForm = () => {
    setShowLeadForm(!showLeadForm);
    setShowProdSuggestModal(false);
    // setEditingLead(null);
    clearForm();
  };

  useEffect(() => {
    fetchLeadSources();
    fetchUnits();
    groupTeamUsers();
    fetchPincode();
    fetchLeadOnline();
  }, []);


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await api.get("/user");
        setGetUser(response.data);
      } catch (error) {
        //-console.error("Error fetching User:", error);
      }
    };
    fetchUser();
  }, []);

  useEffect(() => {
    const fetchAdminRole = async () => {
      try {
        const response = await api.get("/admin");
        setGetAdminRole(response.data);
      } catch (error) {
        //-console.error("Error fetching admin roles:", error);
      }
    };

    fetchAdminRole();
  }, []);

  const fetchUnits = async () => {
    try {
      const response = await api.get("/unit");
      setGetUnit(response.data);
    } catch (error) {
      //-console.log(error);
    }
  };

  const fetchLeadSources = async () => {
    try {
      const response = await api.get("/leadSource");
      setGetLeadSource(response.data);
    } catch (error) {
      //-console.error("Error fetching lead sources:", error);
    }
  };
  useEffect(() => {
    const fetchCustomerCreation = async () => {
      try {
        const response = await api.get("/customerCreation");
        setGetCustomerCreation(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchCustomerCreation();
  }, []);

  useEffect(() => {
    const fetchProductType = async () => {
      try {
        const response = await api.get("/productTypes");
        setGetProductType(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchProductType();
  }, []);

  const fetchLeadOnline = async () => {
    try {
      const response = await api.get("/leadCreationData");
      setGetLeadOnline(response.data);
    } catch (error) {
      //-console.log(error);
    }
  };

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await api.get("/team");
        setGetTeams(response.data);
      } catch (error) {
        //-console.log(error);
      }
    };
    fetchTeams();
  }, []);

  const fetchPincode = async () => {
    try {
      const response = await api.get(`/pincode`);
      setGetPincode(response.data);
    } catch (error) {
      //-console.error("Error fetching pincode:", error);
    }
  };

  const getCityAndState = (pincode) => {
    const pincodeEntry = getPincode.find((entry) => entry.pincode === pincode);

    if (pincodeEntry) {
      return {
        city: pincodeEntry.district.district,
        state: pincodeEntry.state.state,
      };
    } else {
      return { city: "Unknown", state: "Unknown" };
    }
  };

  const handleProductSelect = (selectedProduct) => {
    console.log("handleProductSelect called with", selectedProduct);

    const productDetail = getProductType.find(
      (producttype) => producttype._id === selectedProduct
    );

    if (!productDetail) {
      console.error("Product not found");
      return;
    }

    const productCategoryValue = getProductCategory.find(
      (productCat) => productDetail.product?.productCategory === productCat._id
    );

    const selectedProductString = `${productCategoryValue?.productCategory || ''} - ${productDetail.product?.product || ''} - ${productDetail.productTypes || ''} - ${productDetail.brand?.brand || ''}`;
    const productCategoryFromString = productDetail.productCategory?.productCategory || '';
    const productCategoryId = productDetail.product.productCategory;

    console.log("productCategoryId", productCategoryId);

    // Update the selected products and types
    setSelectedProductList((prevList) => [...prevList, selectedProductString]);
    setSelectedproductType((prevTypes) => [...prevTypes, productDetail._id]);
    setSelectedProductDescription((prevDescription) => [
      ...prevDescription,
      productDetail.productDescription
    ]);
    setAlertMessages((prevAlerts) => [
      {
        product: selectedProductString,
        description: productDetail.productDescription
      },
      ...prevAlerts
    ]);

    // Use the extracted product category
    setSelectedProductCategory((prevCategory) => {
      const updatedCategories = [...prevCategory, productCategoryFromString];
      const uniqueCategories = [...new Set(updatedCategories)];
      return uniqueCategories;
    });

    // Filter the getProductType collection based on the selected product category
    const matchedProducts = getProductType.filter(
      (producttype) => producttype.product?.productCategory === productCategoryId
    );
    console.log("matchedProducts", matchedProducts);

    setSelectedProdCatProduct((prevProdCat) => {
      const updatedList = [...prevProdCat, ...matchedProducts];
      const uniqueMap = new Map(updatedList.map(item => [item._id, item]));
      const uniqueList = Array.from(uniqueMap.values());

      return uniqueList;
    });
    setShowProdSuggestModal(false);

    // Clear search input
    setSearchProduct("");

  };

  useEffect(() => {
    //-console.log("pd:", selectedProductDescription);
    //-console.log("category", selectedProductCategory);
    //-console.log("matchedProdCat", selectedProdCatProduct);


  }, [selectedProductList, selectedProductType, selectedProdCatProduct]);

  const fetchPincodeData = async (enteredPincode) => {
    if (/^\d{6}$/.test(enteredPincode)) {
      try {
        const response = await api.get(`/pincode/${enteredPincode}`);
        const { city, state, areas } = response.data;

        setCity(city);
        setState(state);
        setAreas(areas);
      } catch (error) {
        console.error('Error fetching pincode data:', error);
        setCity('');
        setState('');
        setAreas([]);
      }
    } else {
      setCity('');
      setState('');
      setAreas([]);
    }
  };


  const handlePincodeChange = (e) => {
    const enteredPincode = e.target.value;
    setPincode(enteredPincode);
  };

  const handlePincodeBlur = () => {
    fetchPincodeData(pincode);
  };


  const groupTeamUsers = () => {
    const teams = {};

    const teamIdToNameMap = getTeams.reduce((acc, team) => {
      acc[team._id] = team.teamName;
      return acc;
    }, {});

    // Iterate over each team user and group them by team name
    getUser.forEach((user) => {
      const teamName = teamIdToNameMap[user.team];
      if (!teams[teamName]) {
        teams[teamName] = [];
      }
      teams[teamName].push(user);
    });
    return teams;
  };
  const groupedTeams = groupTeamUsers();
  //-console.log(groupedTeams);

  useEffect(() => {
    const fetchCount = async () => {
      if (selectedCustomerId) {
        try {
          const response = await api.get(`/customerCount/${selectedCustomerId}`);
          //-console.log('Customer Count:', response.data.count);
          setCustomerCount(response.data.count);
        } catch (error) {
          //-console.error('Error fetching customer count:', error);
        }
      } else {
        console.warn('Selected customer ID is missing.');
        setCustomerCount(null);
      }
    };

    fetchCount();
  }, [selectedCustomerId]);


  const handleLeadCreationSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {

      const getUserRole = (adminId) => {
        const user = getUser.find((user) => user._id === adminId);
        return user
          ? getAdminRole.find((role) => role._id === user.adminRole._id)?.roleName
          : null;
      };

      const getTLAndAdminIds = () => {
        const teamName = findLeadUserTeamName(adminId);
        const usersLeadTL = findLeadUsersByTeamAndRole(getUser, getTeams, getAdminRole, teamName, "TL");
        const usersLeadAdmin = findLeadUsersByAdmin(getUser, getAdminRole, "Admin");

        const TL = usersLeadTL.length > 0 ? usersLeadTL[0]._id : null;
        const Admin = usersLeadAdmin.length > 0 ? usersLeadAdmin[0]._id : null;
        return { TL, Admin };

      };

      const { TL, Admin } = getTLAndAdminIds();
      const userRole = getUserRole(adminId);

      let approvalby = TL;
      let adminApprovalBy = Admin;

      if (userRole === "TL" || userRole === "Admin") {
        approvalby = adminId;
      } else if (!TL) {
        approvalby = TL;
      }


      const mapQuotationalHistories = () => {
        return quotationalHistories.map((history) =>
          history.map((product) => {
            const unit = getUnit.find(unit => unit.unit === product.unit) || {};
            return {
              productType: product.productTypeId,
              unit: unit._id || '',
              quantity: product.quantity,
              givenPrice: product.givenPrice,
              expectedPrice: product.expectedPrice
            };
          })
        );
      };

      const mapFollowups = () => {
        return additionalFields.map((field, index) => ({
          followupDate: moment(field.followupDate).format("YYYY-MM-DD HH:mm:ss"),
          nextFollowUpDate: field.nextFollowupDate ? moment(field.nextFollowupDate).format("YYYY-MM-DD HH:mm:ss") : null,
          audioSource: field.audioSource,
          remark: field.remarks,
          createdBy: adminId,
          escalationTo: selectedEscalationUser[index]
        }));
      };

      if (editingLead) {
        // Update existing lead
        const mappedQuotationalHistories = mapQuotationalHistories();
        const mappedFollowup = mapFollowups();

        const quotationApproval = [...editingLead.quotationApproval];

        const now = moment().format("YYYY-MM-DD HH:mm:ss");

        if (selectedStatus === quotationStatusId && (ExQuotationHistLength < quotationalHistoriesLength) && (quotationalHistories.length > 0)) {
          if (userRole === "Admin") {
            if (isProjectChecked === 1) {
              // Admin with isProjectChecked = 1: Add both objects with "approval"
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: now,
                approvalStatus: "approved",
                approvalDate: now,
              });
              quotationApproval.push({
                approvalby: adminApprovalBy,
                submissionDate: now,
                approvalStatus: "approved",
                approvalDate: now,
              });
            } else {
              // Admin without isProjectChecked = 1: Add only the first object with "approval"
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: now,
                approvalStatus: "approved",
                approvalDate: now,
              });
            }
          } else if (userRole === "TL") {
            if (isProjectChecked === 1) {
              // TL with isProjectChecked = 1: Add "forAdminApproval" and "waiting" objects
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: now,
                approvalStatus: "forAdminApproval",
                approvalDate: now,
              });
              quotationApproval.push({
                approvalby: adminApprovalBy,
                submissionDate: now,
                approvalStatus: "waiting",
                approvalDate: now,
              });
            } else {
              // TL without isProjectChecked = 1: Add only the "approval" object
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: now,
                approvalStatus: "approved",
                approvalDate: now,
              });
            }
          } else {
            // For roles other than "TL" or "Admin": Add only the "waiting" object
            quotationApproval.push({
              approvalby: approvalby,
              submissionDate: now,
              approvalStatus: "waiting",
              approvalDate: null,
            });
          }
        }

        console.log("quotationApproval", quotationApproval);

        const leadCancelation = [...editingLead.leadCancelation];
        if (selectedStatus === cancelStatusId) {
          leadCancelation.push({
            doneBy: null,
            submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            status: "pending",
            statusDate: null
          });
        }

        const updatedData = {
          name: selectedCustomerId,
          leadSource: selectedLeadSource,
          leadReference: selectedRefId,
          quotationalHistories: mappedQuotationalHistories,
          followup: mappedFollowup,
          status: selectedStatus,
          cancelReason: selectedReason,
          pincode: getPincodeObjectId,
          address,
          assignedTo: editingLead.assignedTo,
          autoAssigned: isLeadChecked ? 1 : 0,
          project: isProjectChecked ? 1 : 0,
          quotationApproval,
          leadCancelation
        };
        //-console.log("updatedData", updatedData);

        const response = await api.put(`/leadCreationData/${editingLead._id}`, updatedData);
        if (response.status === 200) {
          setShowLeadForm(false);
          fetchLeadOnline();
          setIsLoading(false);
          setEditingLead(null);
          setIsLoading(false);
          fetchFollowUp();
          setShowAlert(true);
        } else {
          //-console.error("Failed to update lead. Server returned status:", response.status);
          throw new Error("Failed to update lead. Please try again later.");
        }
      } else {
        // Create new lead
        let mappedFollowup = mapFollowups();

        let quotationApproval = [];
        if (selectedStatus === quotationStatusId) {
          if (userRole === "Admin") {
            if (isProjectChecked === 1) {
              // Admin with isProjectChecked = 1: Add both objects with "approval"
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                approvalStatus: "approved",
                approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });
              quotationApproval.push({
                approvalby: adminApprovalBy,
                submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                approvalStatus: "approved",
                approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });
            } else {
              // Admin without isProjectChecked = 1: Add only the first object with "approval"
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                approvalStatus: "approved",
                approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });
            }
          } else if (userRole === "TL") {
            if (isProjectChecked === 1) {
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                approvalStatus: "forAdminApproval",
                approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });
              quotationApproval.push({
                approvalby: adminApprovalBy,
                submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                approvalStatus: "waiting",
                approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });
            } else {
              // TL without isProjectChecked = 1: Add only the "approval" object
              quotationApproval.push({
                approvalby: adminId,
                submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
                approvalStatus: "approved",
                approvalDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              });
            }
          } else {
            // For roles other than "TL" or "Admin": Add only the "waiting" object
            quotationApproval.push({
              approvalby: approvalby,
              submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
              approvalStatus: "waiting",
              approvalDate: null,
            });
          }
        }
        console.log("quotationApproval", quotationApproval);

        const mappedQuotationalHistories = mapQuotationalHistories();

        const leadCancelation = selectedStatus === cancelStatusId ? [{
          doneBy: "",
          submissionDate: moment().format("YYYY-MM-DD HH:mm:ss"),
          status: "pending",
          statusDate: null
        }] : [];

        const totalCustomerCount = customerCount + 1;

        const leadData = {
          name: selectedCustomerId,
          leadSource: selectedLeadSource,
          leadReference: selectedRefId,
          quotationalHistories: mappedQuotationalHistories,
          followup: mappedFollowup,
          status: selectedStatus,
          cancelReason: selectedReason,
          pincode: getPincodeObjectId,
          address,
          createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
          createdBy: adminId,
          leadCount: totalCustomerCount,
          assignedTo: isLeadChecked ? getLastLead : selectedTeamUser,
          autoAssigned: isLeadChecked ? 1 : 0,
          project: isProjectChecked ? 1 : 0,
          quotationApproval,
          leadCancelation
        };

        const response = await api.post("/leadCreationData", leadData);
        if (response.status === 200) {
          clearForm();
          setShowLeadForm(false);
          fetchLeadOnline();
          fetchFollowUp();
          setAdditionalFields([]);
          setIsLoading(false);
          setShowAlert(true);
        } else {
          //-console.error("Failed to create lead. Server returned status:", response.status);
          throw new Error("Failed to create lead. Please try again later.");
        }
      }
    } catch (error) {
      //-console.error("Error submitting lead creation data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteLead = async () => {
    // Confirm the deletion action
    if (!window.confirm("Are you sure you want to delete this entry?")) {
      return;
    }

    try {

      setIsLoading(true);
      // Make the API call to delete the item
      const response = await api.delete(`/leadCreationData/${deleteLead._id}`);

      if (response.status === 200) {
        setShowLeadForm(false);
        fetchLeadOnline();
        fetchFollowUp();
        setIsLoading(false);
        setShowAlert(true);
        setAlertMessages([]);
      } else {
        //-console.error("Failed to delete the item. Server returned status:", response.status);
        throw new Error("Failed to delete the item. Please try again later.");
      }
    } catch (error) {
      //-console.error("Error deleting item:", error);
      setShowAlert(true);
      setAlertMessages(["Failed to delete the item. Please try again later."]);
    } finally {
      setIsLoading(false);
    }
  };


  const clearForm = () => {
    setCustomerCount("");
    setSearchName("");
    setSelectedLeadSource("");
    setQuotationalHistories([]);
    setSelectedProductList([]);
    setQuantity([]);
    setState("");
    setCity("");
    setPincode("");
    setAddress("");
    setSearchReference("");
    setSelectedStatus("");
    setSelectedTeamUser("");
    setIsLeadChecked(true);
    setAdditionalFields([]);
    setShowClearButton(false);
    setShowRefButton(false);
  };

  const filteredCustomers = getCustomerCreation.filter((customer) => {
    // Ensure searchName is a string
    if (typeof searchName !== "string") {
      return false;
    }

    // Convert searchName to lowercase once
    const searchLower = searchName.toLowerCase();

    const name = customer?.name?.toLowerCase() || '';
    const alterMobile = customer?.alterMobile || '';
    const mobile = customer?.mobile || '';
    const address = customer.address ? customer.address : '';
    return (
      name.includes(searchLower) ||
      alterMobile.includes(searchName) ||
      mobile.includes(searchName) ||
      address.includes(searchName)
    );
  });

  const filteredRefCustomers = getCustomerCreation.filter((customer) => {

    if (typeof searchReference !== "string") {
      return false;
    }

    const searchLower = searchReference.toLowerCase();

    const name = customer?.name?.toLowerCase() || '';
    const alterMobile = customer?.alterMobile || '';
    const mobile = customer?.mobile || '';

    return (
      name.includes(searchLower) ||
      alterMobile.includes(searchReference) ||
      mobile.includes(searchReference)
    );
  });


  const filteredProductType = getProductType.filter(product => {
    // Check if the product object and its nested properties exist
    if (
      product &&
      product.product &&
      product.product.product &&
      product.productTypes &&
      product.brand &&
      product.brand.brand &&
      product.productDescription
    ) {
      // Find the product category that matches the product's category ID
      const productCategoryValue = getProductCategory.find(
        (productCat) => product.product.productCategory === productCat._id
      );

      // Debugging: Log the product category value and other properties
      //-console.log('Product Category ID:', product.product.ProductCategory);
      //-console.log('Product Category Value:', productCategoryValue);

      const productCategory = productCategoryValue ? productCategoryValue.productCategory.toLowerCase() : '';
      const productProduct = product.product.product.toLowerCase();
      const productTypes = product.productTypes.toLowerCase();
      const brand = product.brand.brand.toLowerCase();
      const productDescription = product.productDescription.toLowerCase();
      const searchTerm = searchProduct.toLowerCase();

      // Check if the search term is included in any of the product fields
      return (
        productCategory.includes(searchTerm) ||
        productProduct.includes(searchTerm) ||
        productTypes.includes(searchTerm) ||
        brand.includes(searchTerm) ||
        productDescription.includes(searchTerm)
      );
    }

    // If the product or its nested properties are missing, return false
    return false;
  });
  //-console.log("Filtered Product Type:", filteredProductType);


  const removeDropdown = (indexToRemove) => {
    setDropdowns(dropdowns.filter((_, index) => index !== indexToRemove));
    const updatedSelectedProductList = selectedProductList.filter(
      (_, index) => index !== indexToRemove
    );
    setSelectedProductList(updatedSelectedProductList);
  };

  const clearName = (searchName) => {
    if (searchName) {
      setSearchName("");
      setAddress("");
    }
  };
  const clearRef = (searchReference) => {
    if (searchReference) {
      setSearchReference("");
    }
  };

  const handleNameSelect = (customer) => {
    try {
      setSearchName(
        `${customer.name} - ${customer.mobile} - ${customer.alterMobile}`
      );
      setSelectedCustomerId(customer._id);
      setAddress(customer.address);

      const pincode = customer.pincode?.pincode || "";
      const state = customer.pincode?.state?.state || "";
      const city = customer.pincode?.district?.district || "";

      setPincode(pincode);
      setState(state);
      setCity(city);
      setSelectedAreaName(customer.pincode?.area || "");
    } catch (error) {
      console.error("Error selecting customer name:", error.message);
    }
  };

  const handleRefSelect = (customer) => {
    setSearchReference(
      `${customer.name} - ${customer.mobile} - ${customer.alterMobile}`
    );
    setSelectedRefId(customer._id);
  };

  const handleUnitChange = (index, value) => {
    const updatedUnits = [...selectedUnit];
    updatedUnits[index] = value;
    setSelectedUnit(updatedUnits);
  };

  // Example handler for updating quantity state
  const handleQuantityChange = (index, value) => {
    const updatedQuantities = [...quantity];
    updatedQuantities[index] = value;
    setQuantity(updatedQuantities);
  };

  const handleGivePrice = (index, value) => {
    const updatedGivePrice = [...givenPrice];
    updatedGivePrice[index] = value;
    setGivenPrice(updatedGivePrice)
  }

  const handleExpectedPrice = (index, value) => {
    const updatedExpectedPrice = [...expectedPrice];
    updatedExpectedPrice[index] = value;
    setExpectedPrice(updatedExpectedPrice)
  }

  const toggleQuotationHistory = () => {
    setShowQuotationHistory(!showQuotationHistory);
  };

  const handleAddChangeTab = (index) => {
    setAddTabIndex(index);
  };
  const handleAddTabChange = (event, newValue) => {
    setAddTabValue(newValue);
  };


  const handleCreateQuotationalHistory = () => {
    //-console.log("Selected Product List:", selectedProductList);
    //-console.log("Selected Product Types:", selectedProductType);
    //-console.log("Selected Units:", selectedUnit);
    //-console.log("Quantities:", quantity);
    //-console.log("Given Prices:", givenPrice);
    //-console.log("Expected Prices:", expectedPrice);

    // Map selected products to the desired format
    const selectedProductsForQuotation = selectedProductList.map((product, index) => {
      const selectedUnitObject = getUnit.find(unit => unit._id === selectedUnit[index]);

      //-console.log('Processing product index:', index);
      //-console.log('Selected product:', product);
      //-console.log('Selected product type:', selectedProductType[index]);
      //-console.log('Selected unit object:', selectedUnitObject);
      //-console.log('Quantity:', quantity[index]);
      //-console.log('Given price:', givenPrice[index]);
      //-console.log('Expected price:', expectedPrice[index]);

      return {
        productTypeId: selectedProductType[index],
        productType: product,
        unit: selectedUnitObject ? selectedUnitObject.unit : '',
        quantity: quantity[index] || '',
        givenPrice: givenPrice[index] || '',
        expectedPrice: expectedPrice[index] || '',
        productDescription: selectedProductDescription[index] || '',
      };
    });

    //-console.log('Selected products for quotation:', selectedProductsForQuotation);
    //-console.log("beforeQuotational", quotationalHistories);


    // Update state to replace old history with the new one
    setQuotationalHistories(prevHistories => {
      const newHistories = [...prevHistories, selectedProductsForQuotation];
      //-console.log("Updated quotationalHistories:", newHistories);
      return newHistories;
    });
    //-console.log("AfterQuotational", quotationalHistories);

    // Clear form fields
    toggleQuotationHistory();
    setSelectedProductList([]);
    setSelectedproductType([]);
    setSelectedUnit([]);
    setQuantity([]);
    setGivenPrice([]);
    setExpectedPrice([]);
    setSearchProduct('');
  };

  useEffect(() => {
    if (editingLead && (editingLead.quotationalHistories?.length ?? false) > 0) {
      setShouldShowButton(false);

      if (editingLead.quotationApproval && editingLead.quotationApproval.length > 0) {
        const lastApprovalStatus = editingLead.quotationApproval[editingLead.quotationApproval.length - 1].approvalStatus;
        //-console.log("checkLastApproval:", lastApprovalStatus);
        setShouldShowButton(lastApprovalStatus !== "waiting");
      }
    }

  }, [editingLead]);



  const quotationalHistoriesLength = quotationalHistories.length;
  //-console.log("quotationalHistoriesLength", quotationalHistoriesLength);

  const handleEditingLeadOnline = (item) => {
    //-console.log("lead", item);
    setIsLoading(false);
    setSelectedReason(item.cancelReason ? item.cancelReason : "");
    const searchName = item.name
      ? `${item.name.name || ''} ${item.name.mobile || '-'} ${item.name.alterMobile || '-'}`
      : '';

    setSearchName(searchName);
    const searchReferenceName = item.leadReference
      ? `${item.leadReference?.name ?? ''} ${item.leadReference?.mobile ?? '-'} ${item.leadReference?.alterMobile ?? '-'}`
      : "";

    setSearchReference(searchReferenceName);
    setCustomerCount(item.leadCount ? item.leadCount : "");
    setSelectedLeadSource(item.leadSource ? item.leadSource._id : "");
    setSelectedCustomerId(item.name._id);
    const history = item.quotationalHistories.map(innerArray =>
      Object.values(innerArray).map(product => {
        // Log the product to the console
        //-console.log("product", product);
        // Find the product category value based on the product's category ID
        const productCategoryValue = getProductCategory.find(
          (productCat) => product.productType?.product?.ProductCategory === productCat._id
        );

        // Return a new object with the required properties
        return {
          productType: `${productCategoryValue?.productCategory || ''} ${product.productType?.product?.product || ''} ${product.productType?.productTypes || ''} ${product.productType?.brand?.brand || ''}`,
          unit: product.unit?.unit || '',
          quantity: product.quantity || 0,
          givenPrice: product.givenPrice || 0,
          expectedPrice: product.expectedPrice || 0,
          productTypeId: product.productType?._id || '',
          productDescription: product.productDescription || ''
        };
      })
    );

    setQuotationalHistories(history);
    setSelectedStatus(item.status ? item.status._id : "N/A");
    setAdditionalFields(
      item.followup.map((followup) => ({
        id: followup._id,
        nextFollowupDate: followup.nextFollowUpDate
          ? new Date(followup.nextFollowUpDate)
          : null,
        audioSource: followup.audioSource,
        remarks: followup.remark || "",
      }))
    );
    const pincode = item.pincode || {};
    setPincode(pincode.pincode || "");
    setSelectedAreaName(pincode.area || "");
    setCity(pincode.district ? pincode.district.district : "");
    setState(pincode.state ? pincode.state.state : "");
    setIsProjectChecked(item.project === 1 ? 1 : 0);
    setAddress(item.address);
    setEditingLead(item);
    setDeleteLead(item);
    setGetLastLead(item.assignedTo);
    setShowLeadForm(true);
  };

  const ExQuotationHistLength = editingLead ? editingLead.quotationalHistories.length : 0;
  //-console.log("ExQuotationHistLength", ExQuotationHistLength);

  const addAdditionalField = () => {
    const newField = {
      id: uuidv4(),
      nextfollowUp: null,
      remarks: "",
      audioSource: "",
    };
    setAdditionalFields([...additionalFields, newField]);
  };

  // Function to remove an additional field by index
  const removeAdditionalField = async (index) => {
    const files = additionalFields[index].audioSource;
    if (files) {
      const response = await api.delete("/deleteFile", {
        data: { files },
      });
      //-console.log("response", response);
    }

    const updatedFields = [...additionalFields];
    updatedFields.splice(index, 1);
    setAdditionalFields(updatedFields);
  };

  // Function to update remarks in additional fields
  const handleRemarksChange = (index, remarks) => {
    const updatedFields = [...additionalFields];
    updatedFields[index].remarks = remarks;
    setAdditionalFields(updatedFields);
  };

  const handleDateChange = (date, index, fieldName) => {
    const updatedFields = [...additionalFields];

    if (fieldName === "nextFollowup") {
      updatedFields[index] = {
        ...updatedFields[index],
        nextFollowupDate: date,
      };
    }

    setAdditionalFields(updatedFields);
  };

  const handleProjectCheckboxChange = (event) => {
    setIsProjectChecked(event.target.checked);
    if (event.target.checked) {

    }
  };

  // access based on role ---------------------------
  useEffect(() => {

    const getLoginUserRole = (adminId) => {
      //-console.log('Searching for user with adminId:', adminId);
      const user = getUser.find((user) => user._id === adminId);
      //-console.log('User found:', user);

      if (user) {
        //-console.log('Searching for role with roleId:', user.adminRole._id);
        const loginuserRole = getAdminRole.find((role) => role._id === user.adminRole._id);
        //-console.log('Role found:', loginuserRole);
        setUserRole(loginuserRole);
        return loginuserRole?.roleName || null;
      } else {
        //-console.log('No user found with the given adminId');
        return null;
      }
    };

    // Fetch the userRole based on adminId
    const LoginRole = getLoginUserRole(adminId);
  }, [adminId, getUser, getAdminRole]);


  const findLeadUserTeamName = (getLastLead) => {
    const foundUser = getUser.find(user => user._id === getLastLead);
    if (foundUser && foundUser.team) {
      const foundTeam = getTeams.find(team => team._id === foundUser.team._id);
      return foundTeam ? foundTeam.teamName : null;
    }
    return null;
  }

  const findLeadUsersByTeamAndRole = (users, teams, adminRoles, teamName, roleName) => {
    return users.filter(user => {
      const userTeam = teams.find(team => team._id === user.team?._id);
      //-console.log("userTeam", userTeam);
      return userTeam && userTeam.teamName === teamName;
    }).filter(user => {
      const userRole = adminRoles.find(role => role._id === user.adminRole._id);
      //-console.log("userRole", userRole);

      return userRole && userRole.roleName === roleName;
    });
  };

  const findLeadUsersByAdmin = (users, adminRoles, roleName) => {
    return users.filter(user => {
      const userRole = adminRoles.find(role => role._id === user.adminRole._id && role.roleName === roleName);
      return !!userRole;
    });
  };

  useEffect(() => {
    const teamName = findLeadUserTeamName(getLastLead);

    const usersLeadInTeamTL = findLeadUsersByTeamAndRole(getUser, getTeams, getAdminRole, teamName, 'TL');
    const usersLeadInTeamAdmin = findLeadUsersByAdmin(getUser, getAdminRole, 'Admin');

    if (usersLeadInTeamTL.length > 0) {
      const TL = usersLeadInTeamTL[0]._id;
      const Admin = usersLeadInTeamAdmin[0] ? usersLeadInTeamAdmin[0]._id : null;
      console.log("LeadTL", TL);
      //-console.log("LeadAdmin", Admin);
      setLeadTLApprovalBy(TL);
      setLeadAdminApprovalBy(Admin);
    }
    else {
      setLeadTLApprovalBy(null);
      setLeadAdminApprovalBy(null);
    }
  }, [getLastLead, getTeams, getUser, getAdminRole, findLeadUserTeamName, setLeadTLApprovalBy, setLeadAdminApprovalBy]);

  //-console.log("leadTL", leadTLApprovalBy);


  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const quotationApproval = row.item.quotationApproval;
    let approvalIcon = "";

    if (quotationApproval.length > 0) {
      const lastApproval = quotationApproval[quotationApproval.length - 1];
      if (lastApproval.approvalStatus === 'approved') {
        approvalIcon = <CheckIcon color="success" />;
      } else if (lastApproval.approvalStatus === 'declined') {
        approvalIcon = <CancelIcon color="error" />;
      } else if (lastApproval.approvalStatus === 'waiting') {
        approvalIcon = <RefreshIcon color="info" />;
      } else {
        approvalIcon = null;
      }
    }

    // product list display in table
    const quotationalHistories = row.item.quotationalHistories;
    //-console.log("productList", quotationalHistories);
    const lastQuotationalHistories = quotationalHistories[quotationalHistories.length - 1]
    //-console.log("lastQuotationalHistories", lastQuotationalHistories);

    const filteredRows = rows.filter(row => {
      const { name, source, date, pincode, area } = filters;
      return (
        (name === '' || row.cus_name.includes(name)) &&
        (source === '' || row.lead_source.includes(source)) &&
        (date === null || row.lead_date === date) &&
        (pincode === '' || row.item.pincode.pincode.includes(pincode)) &&
        (area === '' || row.item.pincode.area.includes(area))
      );
    });

    const handleFilterChange = (event) => {
      setFilters({
        ...filters,
        [event.target.name]: event.target.value
      });
    };


    return (
      <React.Fragment>
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          onDoubleClick={() => handleEditingLeadOnline(row.item)}
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{approvalIcon}</TableCell>
          <TableCell component="th" scope="row">
            {row.cus_name}
          </TableCell>
          <TableCell>{row.lead_source}</TableCell>
          <TableCell>{row.lead_date}</TableCell>
          <TableCell>{row.item.pincode ? row.item.pincode.pincode : ""}</TableCell>
          <TableCell>{row.item.pincode ? row.item.pincode.area : ""}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Products
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className="text-center">Product Name</TableCell>
                      <TableCell className="text-center">Unit</TableCell>
                      <TableCell className="text-center">Quantity</TableCell>
                      <TableCell className="text-center">Given Price</TableCell>
                      <TableCell className="text-center">Expected Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(lastQuotationalHistories) && lastQuotationalHistories.length > 0 ? (
                      lastQuotationalHistories.map((productList, index) => (
                        <TableRow key={index}>
                          <TableCell className="text-center" component="th" scope="row">
                            {productList?.productType?.product?.product || 'N/A'} {productList?.productType?.brand?.brand || 'N/A'} {productList?.productType?.productTypes || 'N/A'}
                          </TableCell>
                          <TableCell className="text-center">{productList?.unit?.unit || 'N/A'}</TableCell>
                          <TableCell className="text-center">{productList?.quantity || 'N/A'}</TableCell>
                          <TableCell className="text-center" >{productList?.givenPrice || 'N/A'}</TableCell>
                          <TableCell className="text-center">{productList?.expectedPrice || 'N/A'}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} className="text-center">No product available</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const generateQuotation = (editingLead, index) => {
    //-console.log("New Tab");

    const data = { leadData: editingLead, quote: index };
    localStorage.setItem("generateQuotation", JSON.stringify(data));
    const url = "/quotation";
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const currentDateTime = moment().format('YYYY-MM-DDTHH:mm');

  useEffect(() => {
    // Building Data Table
    let temp_rows = [];
    getLeadOnline.map((item, index) => {
      //-console.log(item);

      const quotationalHistories = [];
      item.quotationalHistories.map((product, index) => {
        quotationalHistories.push({
          productType: product.productType,
          unit: product.unit,
          quantity: product.quantity,
          givenPrice: product.givenPrice,
          expectedPrice: product.expectedPrice,
        });
      });
      temp_rows.push(
        createData(
          item.name ? item.name.name : "",
          item.leadSource ? item.leadSource.leadSource : "",
          item.createdAt,
          getCityAndState(item.pincode).city,
          item.pincode,
          quotationalHistories,
          item
        )
      );
    });
    setGridRows(temp_rows);
  }, [getLeadOnline]);

  const handleUpload = async (index) => {
    const formData = new FormData();
    formData.append("file", getAudioFile);
    formData.append("customerid", adminId);
    formData.append("format", "audio");
    const response = await api.post("/uploadAudio", formData);
    const updatedFields = [...additionalFields];
    updatedFields[index].audioSource = response.data;
    setAdditionalFields(updatedFields);
    //-console.log(additionalFields);
  };
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleFromDateChange = (tabIndex, e) => {
    switch (tabIndex) {
      case 0:
        setFromDate1(e.target.value);
        break;
      case 1:
        setFromDate2(e.target.value);
        break;
      case 2:
        setFromDate3(e.target.value);
        break;
      case 3:
        setFromDate4(e.target.value);
        break;
      case 4:
        setFromDate5(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleToDateChange = (tabIndex, e) => {
    switch (tabIndex) {
      case 0:
        setToDate1(e.target.value);
        break;
      case 1:
        setToDate2(e.target.value);
        break;
      case 2:
        setToDate3(e.target.value);
        break;
      case 3:
        setToDate4(e.target.value);
        break;
      case 4:
        setToDate5(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleSearchClick = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        setSearchClicked1(true);
        break;
      case 1:
        setSearchClicked2(true);
        break;
      case 2:
        setSearchClicked3(true);
        break;
      case 3:
        setSearchClicked4(true);
        break;
      case 4:
        setSearchClicked5(true);
        break;
      default:
        break;
    }
  };

  const handleClearFilter = (tabIndex) => {
    const setDefaultFromDate = () => {
      const currentDateFormatted = moment().format("YYYY-MM-DD");
      switch (tabIndex) {
        case 0:
          setFromDate1(currentDateFormatted);
          setSearchClicked1(false);
          setToDate1(currentDateFormatted);
          setFilteredRows1([]);
          break;
        case 1:
          setFromDate2(currentDateFormatted);
          setSearchClicked2(false);
          setToDate2(currentDateFormatted);
          setFilteredRows2([]);
          break;
        case 2:
          setFromDate3(currentDateFormatted);
          setSearchClicked3(false);
          setToDate3(currentDateFormatted);
          setFilteredRows3([]);
          break;
        case 3:
          setFromDate4(currentDateFormatted);
          setSearchClicked4(false);
          setToDate4(currentDateFormatted);
          setFilteredRows4([]);
          break;
        case 4:
          setFromDate5(currentDateFormatted);
          setSearchClicked5(false);
          setToDate5(currentDateFormatted);
          setFilteredRows5([]);
          break;
        default:
          break;
      }
    };

    setDefaultFromDate();
  };



  useEffect(() => {
    const filterRowsByDate = (tabIndex) => {
      let fromDate, toDate, setFilteredRows, statusFilter;
      switch (tabIndex) {
        case 0:
          fromDate = fromDate1;
          toDate = toDate1;
          setFilteredRows = setFilteredRows1;
          statusFilter = "open";
          break;
        case 1:
          fromDate = fromDate2;
          toDate = toDate2;
          setFilteredRows = setFilteredRows2;
          statusFilter = "quotation";
          break;
        case 2:
          fromDate = fromDate3;
          toDate = toDate3;
          setFilteredRows = setFilteredRows3;
          statusFilter = "escalation";
          break;
        case 3:
          fromDate = fromDate4;
          toDate = toDate4;
          setFilteredRows = setFilteredRows4;
          statusFilter = "order success";
          break;
        case 4:
          fromDate = fromDate5;
          toDate = toDate5;
          setFilteredRows = setFilteredRows5;
          statusFilter = "order cancel";
          break;
        default:
          break;
      }

      //-console.log(`fromDate: ${fromDate}, toDate: ${toDate}`);

      if (fromDate && toDate) {
        const filtered = rows.filter((row) => {
          //-console.log("row", row);

          const followup = row.item.followup;
          //-console.log("followup", followup);
          const assignedTo = row.item.assignedTo ? row.item.assignedTo._id : null;

          //-console.log("assignedTo",assignedTo);
          if (followup && assignedTo === adminId && followup.length > 0) {
            const nextFollowUpDate = moment(followup[0]?.nextFollowUpDate);
            const isWithinDateRange = nextFollowUpDate.isBetween(moment(fromDate).startOf('day'), moment(toDate).endOf('day'), null, '[]');
            return isWithinDateRange && row.item.status.leadStatus === statusFilter;
          }
          return false;
        });
        setFilteredRows(filtered);
      } else {
        setFilteredRows([]);
      }
    };

    switch (tabIndex) {
      case 0:
        if (searchClicked1) {
          filterRowsByDate(0);
        }
        break;
      case 1:
        if (searchClicked2) {
          filterRowsByDate(1);
        }
        break;
      case 2:
        if (searchClicked3) {
          filterRowsByDate(2);
        }
        break;
      case 3:
        if (searchClicked4) {
          filterRowsByDate(3);
        }
        break;
      case 4:
        if (searchClicked5) {
          filterRowsByDate(4);
        }
        break;
      default:
        break;
    }
  }, [
    fromDate1,
    toDate1,
    searchClicked1,
    fromDate2,
    toDate2,
    searchClicked2,
    fromDate3,
    toDate3,
    searchClicked3,
    fromDate4,
    toDate4,
    searchClicked4,
    fromDate5,
    toDate5,
    searchClicked5,
    tabIndex,
    rows,
  ]
  );

  return (
    <React.Fragment>
      {/* Page Header */}
      <div class="page-header">
        <h3 class="page-title">
          <span class="page-title-icon bg-gradient-primary text-white me-2">
            <i class="fa fa-bell"></i>
          </span>{" "}
          Lead Online
        </h3>
        <nav aria-label="breadcrumb">
          <ul class="breadcrumb">
            <li class="breadcrumb-item active" aria-current="page">
              <span></span>Overview{" "}
              <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
            </li>
          </ul>
        </nav>
      </div>

      {/* off cnavas form */}
      <div
        className={`offcanvas offcanvas-end w-50 ${showLeadForm ? "show" : ""
          }`}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            Lead Form
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            onClick={toggleLeadForm}
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="card">
            <div className="card-body">
              {/* Form */}
              <form
                className="form-inline"
                encType="multipart/form-data"
                onSubmit={handleLeadCreationSubmit}
                style={{
                  display: showLeadForm || (editingLead ? "block" : "none"),
                }}
              >
                {/* ROW 1 */}
                <div className="row">
                  {/* Name */}
                  <div className="col-md-3">
                    <div className="d-flex gap-2">
                      <div className="form-group w-100">
                        <label> Customer Name </label>
                        <input
                          type="text"
                          placeholder="Search a name"
                          value={searchName}
                          onChange={(e) =>
                            setSearchName(e.target.value)
                          }
                          className="form-control mb-2"
                          onClick={() => setShowClearButton(true)}
                          required
                        />
                        {searchName && (
                          <div className="form-group">
                            <ul>
                              {filteredCustomers.map((customer) => (
                                <li
                                  key={customer._id}
                                  onClick={() =>
                                    handleNameSelect(customer)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {customer.name} -{" "}
                                  {customer.alterMobile} -{" "}
                                  {customer.mobile}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div>
                        {showClearButton && (
                          <button
                            onClick={() => clearName(searchName)}
                            className="btn  btn-sm badge-gradient-danger"
                            aria-label="Close"
                            style={{ marginTop: "30px" }}
                          >
                            &times;
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Lead Source */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label>Lead Source</label>
                      <select
                        className="form-select form-control"
                        value={selectedLeadSource}
                        onChange={(e) => setSelectedLeadSource(e.target.value)}
                        required
                      >
                        <option value="">Select Lead</option>
                        {getLeadSource.map((lead) => (
                          <option key={lead._id} value={lead._id}>
                            {lead.leadSource}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* Lead Reference */}
                  <div className="col-md-3">
                    <div className="d-flex gap-2">
                      <div className="form-group w-100">
                        <label htmlFor="pincode">Lead Reference</label>
                        <input
                          type="text"
                          value={searchReference}
                          placeholder="Search a lead reference name"
                          onChange={(e) =>
                            setSearchReference(e.target.value)
                          }
                          className="form-control mb-2"
                          onClick={() => setShowRefButton(true)}
                        />
                        {/* filter */}
                        {searchReference && (
                          <div className="form-group">
                            {filteredRefCustomers.map((customer) => (
                              <div
                                key={customer._id}
                                onClick={() =>
                                  handleRefSelect(customer)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {customer.name} - {customer.alterMobile}{" "}
                                - {customer.mobile}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      {/* btn */}
                      <div>
                        {showRefButton && (
                          <button
                            onClick={() => clearRef(searchReference)}
                            className="btn  btn-sm badge-gradient-danger"
                            aria-label="Close"
                            style={{ marginTop: "30px" }}
                          >
                            &times;
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* lead count */}
                  <div className="col-md-2 mb-2">
                    <div className="form-group">
                      <label htmlFor="customerCount">Lead Count</label>
                      <div className="d-flex">
                        <input
                          className="form-control"
                          id="customerCount"
                          value={customerCount !== null ? customerCount : ''}
                          onChange={(e) => setCustomerCount(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* ROW 3 */}
                <div className="row">
                  {/* Pincode */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="pincode">Pincode</label>
                      <input
                        type="text"
                        className="form-control"
                        id="pincode"
                        placeholder="Enter a pincode"
                        value={pincode}
                        onChange={handlePincodeChange}
                        onKeyUp={handlePincodeBlur}
                      />
                    </div>
                  </div>
                  {/* State */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="pincode">state</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        id="pincode"
                        placeholder="State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* city */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="pincode">city</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        placeholder="City"
                        id="pincode"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* Area Name */}
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="city">Area Name</label>
                      <select
                        id="customerCredits"
                        className="form-select form-control"
                        value={selectedAreaName}
                        onChange={(e) => setSelectedAreaName(e.target.value)}
                      >
                        <option value="">Select Area</option>
                        {areas.map((area, index) => (
                          <option key={index} value={area}>
                            {area}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* display file information and progress when file selected */}
                  <div className="row">
                    {/* Address */}
                    <div className="col-md-3">
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input
                          className="form-control"
                          rows={4}
                          id="address"
                          value={address}
                          placeholder="Enter a address"
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* selected Team User */}
                    <div className="col-md-3">
                      {/* Form */}
                      <h4 className="card-title"></h4>
                      <div className="form-group">
                        <label htmlFor="inlineFormInputName2">
                          Manual Assign
                        </label>
                        <select
                          className="form-select form-control"
                          value={selectedTeamUser}
                          onChange={(e) =>
                            setSelectedTeamUser(e.target.value)
                          }
                          disabled={isLeadChecked}
                        >
                          <option value="">Select Team User</option>

                          {getUser.map((teamUser) => (
                            <option
                              key={teamUser._id}
                              value={teamUser._id}
                            >
                              {teamUser.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Auto Assigned */}
                    <div className="col-md-3 text-center align-self-center">
                      <div class="form-check form-check-danger">
                        <label class="form-check-label text-start">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            onChange={handleCheckboxChange}
                            checked={isLeadChecked}
                          ></input>
                          Auto Assign <i class="input-helper"></i>
                        </label>
                      </div>
                      <label
                        className="custom-control-label"
                        htmlFor=""
                      ></label>
                    </div>
                    {/* selected status */}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        {/* Form */}
                        <h4 className="card-title"></h4>
                        <label htmlFor="inlineFormInputName2">
                          Status
                        </label>
                        <select
                          className="form-select form-control"
                          value={selectedStatus}
                          required
                          onChange={(e) =>
                            setSelectedStatus(e.target.value)
                          }
                        >
                          <option value="">Selected a Status</option>
                          {getLeadStatus.map((status) => (
                            <option
                              key={status._id}
                              value={status._id}
                            >
                              {status.leadStatus}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    {/* selected cancel reason */}
                    {selectedStatus === cancelStatusId && (
                      <div className="col-md-3 mb-2">
                        <div className="form-group">
                          {/* Form */}
                          <label htmlFor="inlineFormInputName2">Cancel reason</label>
                          <select
                            className="form-select form-control"
                            value={selectedReason}
                            onChange={(e) => setSelectedReason(e.target.value)}
                            required
                          >
                            <option value="">Select a Reason</option>
                            {getLeadCancelReason[0]?.cancelReason.map((reason, index) => (
                              <option key={index} value={reason}>
                                {reason}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    {/* is project  */}
                    <div className="col-md-3 mb-2">
                      <div className="form-group">
                        <label htmlFor="projectValue">Project</label>
                        <div className="d-flex">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            onChange={handleProjectCheckboxChange}
                            checked={isProjectChecked}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={getAddTabValue}>
                    <Box>
                      <TabList
                        onChange={handleAddTabChange}
                        aria-label="lab API tabs example"
                        textColor="secondary"
                        indicatorColor="secondary"
                      >
                        <Tab label="FollowUp" value="1" />
                        <Tab label="Quotation" value="2" />
                      </TabList>
                    </Box>
                    {/* Add followUP */}
                    <TabPanel value="1" onClick={() => handleAddChangeTab(0)}>
                      <div className="col-md-2">
                        <button
                          type="button"
                          onClick={addAdditionalField}
                          className="btn btn-gradient-success btn-rounded btn-icon"
                        >
                          <i class="fa fa-calendar"></i>
                        </button>
                        &nbsp; New Follow
                      </div>
                      {/* Additional field */}

                      <h4 className="text-danger" style={{ marginBottom: "10px" }}>
                        FollowUp History
                      </h4>
                      <h4
                        className="text-info"
                        style={{ marginBottom: "5px" }}
                      >{`Total FollowUps - ${additionalFields.length}`}</h4>
                      {additionalFields.map((field, index) => (
                        <div
                          key={field.id}
                          className="card follow-cards shadow"
                        >
                          <div class="card-header">
                            <div class="row">
                              <div class="col-10">
                                <label class="badge badge-info me-4">
                                  Follow {index + 1}
                                </label>
                                <label class="badge badge-info me-4">
                                  {new Date().toLocaleString() + ""}
                                </label>
                                <label class="badge badge-info me-4">
                                  {adminName}
                                </label>
                              </div>
                              <div className="col text-end">
                                {/* Remove button */}
                                <button
                                  type="button"
                                  onClick={() => removeAdditionalField(index)}
                                  className="btn btn-sm badge-gradient-danger"
                                  aria-label="Close"
                                >
                                  &times;
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <div class="row">
                              {/* Next follow-up date picker */}
                              <div className="col-md-4">
                                <label>Next Follow-Up</label>
                                <input
                                  type="datetime-local"
                                  class="form-control"
                                  placeholder="Next Follow Date"
                                  value={
                                    field.nextFollowupDate
                                      ? moment(field.nextFollowupDate).format(
                                        "YYYY-MM-DDTHH:mm"
                                      )
                                      : ""
                                  }
                                  required
                                  min={currentDateTime}
                                  onChange={(e) =>
                                    handleDateChange(
                                      new Date(e.target.value),
                                      index,
                                      "nextFollowup"
                                    )
                                  }
                                />
                              </div>
                              {/* Remarks */}
                              <div className="col-md-4">
                                <label htmlFor={`remarks-${index}`}>
                                  Remarks
                                </label>
                                <textarea
                                  id={`remarks-${index}`}
                                  className="form-control"
                                  placeholder="Enter remarks"
                                  rows="3"
                                  value={field.remarks}
                                  onChange={(e) =>
                                    handleRemarksChange(index, e.target.value)
                                  }
                                ></textarea>
                              </div>

                              {/* Upload Audio */}
                              <div className="col-md-4">
                                {!field.audioSource && (
                                  <>
                                    <input
                                      class="form-control"
                                      type="file"
                                      accept=".mp3,audio/*"
                                      onChange={(e) =>
                                        setAudioFile(e.target.files[0])
                                      }
                                    ></input>
                                    <button
                                      type="button"
                                      class="btn btn-outline-success btn-icon-text"
                                      onClick={() => handleUpload(index)}
                                    >
                                      <i class="mdi mdi-upload btn-icon-prepend"></i>
                                      Upload Audio
                                    </button>
                                  </>
                                )}
                                {field.audioSource && (
                                  <audio controls>
                                    <source
                                      src={
                                        process.env.REACT_APP_DATA_SERVER +
                                        field.audioSource
                                      }
                                      type="audio/mpeg"
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                )}
                              </div>
                            </div>
                            <div class="row">
                              <div className="col-md-4 text-center align-self-center">
                                <div class="form-check form-check-success">
                                  <label class="form-check-label text-start">
                                    <input
                                      type="checkbox"
                                      class="form-check-input"
                                      onChange={(e) =>
                                        handleEscaltionCheckbox(
                                          index,
                                          e.target.checked
                                        )
                                      }
                                      checked={isEscalateChecked[index] || false}
                                    ></input>
                                    Escalation <i class="input-helper"></i>
                                  </label>
                                </div>
                              </div>
                              {/* Excalation */}
                              <div className="col-md-4">
                                {/* Form */}
                                <h4 className="card-title"></h4>
                                <div className="form-group">
                                  <label htmlFor="inlineFormInputName2">
                                    Escalation To
                                  </label>
                                  <select
                                    className="form-select form-control"
                                    value={selectedEscalationUser}
                                    onChange={(e) =>
                                      setSelectedEscalationUser(
                                        e.target.value
                                      )
                                    }
                                    disabled={isDropdownDisabled}
                                  >
                                    <option value="">Select Team User</option>

                                    {getUser.map((teamUser) => (
                                      <option
                                        key={teamUser._id}
                                        value={teamUser._id}
                                      >
                                        {teamUser.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </TabPanel>
                    {/* Quotational history */}
                    <TabPanel value="2" onClick={() => handleAddChangeTab(1)}>
                      {/* Products */}
                      {shouldShowButton && (
                        <div className="col-md-4">
                          <div className="form-group w-100">
                            <label>Search Product</label>
                            <input
                              type="text"
                              placeholder="Search by product"
                              value={searchProduct}
                              onChange={(e) => setSearchProduct(e.target.value)}
                              className="form-control"
                            />
                            {searchProduct && (
                              <select
                                className="js-example-basic-multiple"
                                multiple
                                style={{ width: '100%' }}
                              >
                                {filteredProductType.map((product) => {
                                  const productCategory = getProductCategory.find(
                                    (cat) => cat._id === product.product.productCategory
                                  )?.productCategory || 'Unknown';

                                  return (
                                    <option
                                      key={product._id}
                                      value={product._id}
                                      onClick={() => handleProductSelect(product._id)}
                                    >
                                      {productCategory} - {product.product.product} - {product.productTypes} - {product.brand.brand}
                                    </option>
                                  );
                                })}
                              </select>
                            )}
                          </div>
                        </div>
                      )}
                      {shouldShowButton && (
                        <div className="">
                          {selectedProductList.length > 0 && (
                            <div className="">
                              {selectedProductList.map((product, index) => (
                                <div key={index} className="row">
                                  <div className="form-group col-md-3">
                                    <label>Product Category</label>
                                    <input
                                      type="text"
                                      readOnly
                                      className="form-control"
                                      value={product}
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    <label htmlFor="workNature">Unit</label>
                                    <select
                                      className="form-select"
                                      value={selectedUnit[index]}
                                      onChange={(e) => handleUnitChange(index, e.target.value)}
                                    >
                                      <option value="">Select a unit</option>
                                      {getUnit.map((unit) => (
                                        <option key={unit._id} value={unit._id}>
                                          {unit.unit}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="form-group col-md-2">
                                    <label>Quantity</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={quantity[index]}
                                      onChange={(e) => handleQuantityChange(index, e.target.value)}
                                    />
                                  </div>
                                  <div className="form-group col-md-2">
                                    <label>Given Price</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={givenPrice[index]}
                                      onChange={(e) => handleGivePrice(index, e.target.value)}
                                    />
                                  </div>
                                  {/* expected price */}
                                  <div className="form-group col-md-2">
                                    <label>Asked Price</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={expectedPrice[index]}
                                      onChange={(e) => handleExpectedPrice(index, e.target.value)}
                                    />
                                  </div>

                                  <div className="form-group col-md-1">
                                    <button
                                      onClick={() => removeDropdown(index)}
                                      className="btn btn-sm badge-gradient-danger"
                                      aria-label="Close"
                                      style={{ marginTop: "30px" }}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                </div>
                              ))}

                              {/* Add Quotational Button */}
                              <div className="col-md-6">
                                <button
                                  type="button"
                                  onClick={handleCreateQuotationalHistory}
                                  className="btn btn-gradient-success btn-rounded btn-icon"
                                >
                                  <i className="fa fa-calendar"></i>
                                </button>
                                &nbsp; Create Quotation
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      <h4 className="text-danger" style={{ marginBottom: "10px" }}>
                        Quotational History
                      </h4>
                      {/* Quotational history */}
                      <div className="col-md-12">
                        {quotationalHistories && quotationalHistories.map((quotationalHistory, index) => {
                          let approvalStatus;

                          if (editingLead && editingLead.quotationApproval && editingLead.quotationApproval.length > 0) {
                            let history = 0;
                            let lastIndex = 0;
                            for (let i = 0; i < editingLead.quotationApproval.length; i++) {
                              const quotationApproval = editingLead.quotationApproval[i];
                              if (
                                quotationApproval?.approvalStatus !== "transfer" &&
                                quotationApproval?.approvalStatus !== "forAdminApproval" &&
                                i >= lastIndex
                              ) {
                                if (index === history) {
                                  approvalStatus = quotationApproval?.approvalStatus ?? "pending";
                                  lastIndex = i;
                                  console.log("i", i);
                                  console.log("history", history);
                                  console.log("history & Index", history, index);
                                  console.log("approvalStatus", approvalStatus);
                                  break;
                                }
                                history++;
                              }
                            }
                          } else {
                            approvalStatus = 'pending';
                          }


                          return (
                            <div key={index} className="mt-4">
                              <div className="card follow-cards shadow">
                                <div className="card-header">
                                  <div className="row">
                                    <div className="col">
                                      <label className="badge badge-info me-4">
                                        Quotation {index + 1}
                                      </label>
                                      <label className="badge badge-info me-4">
                                        {new Date().toLocaleString()}
                                      </label>
                                      <label className="badge badge-info me-4">
                                        {adminName}
                                      </label>
                                    </div>
                                    <div className="col text-end">
                                      {/* Generate button */}
                                      {approvalStatus === "approval" ? (
                                        <button
                                          type="button"
                                          onClick={() => {
                                            generateQuotation(editingLead, index);
                                          }}
                                          className="btn btn-sm btn-success"
                                        >
                                          Generate Quotation
                                        </button>
                                      ) : (
                                        <p className="ps-2 fw-2" style={{ color: "green" }}>
                                          {approvalStatus}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Product Category</th>
                                      <th>Unit</th>
                                      <th>Quantity</th>
                                      <th>Given Price</th>
                                      <th>Expected Price</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {quotationalHistory.map((product, idx) => (
                                      <tr key={idx}>
                                        <td>{product.productType}</td>
                                        <td>{product.unit}</td>
                                        <td>{product.quantity}</td>
                                        <td>{product.givenPrice}</td>
                                        <td>{product.expectedPrice}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
                <div className="fixed-alerts-bottom-right">
                  <div className="alert-container">
                    {alertMessages.length > 0 && (
                      alertMessages.map((alert, index) => (
                        <div key={index} className="container-fluid text-start mb-2">
                          <div className="alert alert-info alert-dismissible fade show" role="alert">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h6 className="d-inline">Product - </h6>
                                <p className="d-inline">{alert.product}</p>
                                <br />
                                <h6 className="d-inline">Description - </h6>
                                <p className="d-inline">{alert.description}</p>
                              </div>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="alert"
                                aria-label="Close"
                              ></button>
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <Loader isLoading={isLoading} />
                <div className="row text-start w-75">
                  <div className="col-md-3 text-start">
                    {userRole && userRole.pageList.find(page => page.pageName === "Lead Online").access[2] === 1 && (
                      <button type="submit" className="btn btn-sm btn-primary">
                        {" "}
                        {editingLead
                          ? "Update Lead"
                          : "Add Lead"}
                      </button>
                    )}
                  </div>
                  {/* product suggestion button */}
                  <div className="col-md-3 ">
                    <button
                      type="button"
                      onClick={handleProductSuggest}
                      className="btn btn-sm btn-success ms-3"
                    >
                      Product Suggest
                    </button>
                  </div>
                  <div className="col-md-3 align-items-center">
                    {userRole?.pageList.some(page => page.pageName === "Lead Online" && page.access[3] === 1) && editingLead && (
                      <button
                        type="button"
                        className="btn btn-sm btn-danger"
                        onClick={handleDeleteLead}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>

                {/* model for product suggestion */}
                {showProdSuggestModal && (
                  <div className="modal fade show d-block modelbox" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <div className="modal-dialog modal-lg" role="document">
                      <div className="modal-content" style={{ boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)' }}>
                        <div className="modal-header">
                          <h5 className="modal-title text-danger" style={{ marginLeft: "45px" }}>Product Suggestion</h5>
                          <button
                            type="button"
                            className="btn btn-sm badge-gradient-danger"
                            onClick={handleClose}
                            style={{ marginTop: "6px" }}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="container">
                            <div className="row g-2 ">
                              {selectedProdCatProduct && selectedProdCatProduct.length > 0 &&
                                selectedProdCatProduct.map((catProd, idx) => {
                                  const productCategoryValue = getProductCategory.find(
                                    (productCat) => productCat._id === catProd.product.productCategory
                                  );
                                  return (
                                    <div key={`${idx}-${catProd?._id}`} className="col-12 col-md-4 mb-3">
                                      <div className="card product-card">
                                        <div className="card-body d-flex">
                                          <div className="">
                                            {catProd?.productImage ? (
                                              <img
                                                src={`${process.env.REACT_APP_API_SERVER}/${catProd.productImage}`}
                                                width="100px"
                                                height="100px"
                                                alt="Product"
                                                className="product-image"
                                              />
                                            ) : (
                                              <h6 className="no-image-text">No image</h6>
                                            )}
                                          </div>
                                          <div className="product-info ms-2">
                                            <p className="card-text">
                                              <strong>Product Category:</strong> {productCategoryValue?.productCategory || 'N/A'}
                                            </p>
                                            <p className="card-text">
                                              <strong>Product Code:</strong> {catProd?.productCode || 'N/A'}
                                            </p>
                                            <p className="card-text">
                                              <strong>Product:</strong> {catProd?.product?.product || 'N/A'}
                                            </p>
                                            <p className="card-text">
                                              <strong>GST:</strong> {catProd?.productGST || 'N/A'}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer py-4">

                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>

      {showAlert && (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1050
          }}
        >
          <strong>Submitted successfully</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      )}


      {/* download quotation */}
      {QModal && (
        <div
          className="modal quotation-generator"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-body bg-white">
                <button
                  type="button"
                  className="btn-close float-end"
                  onClick={closeQModal}
                  aria-label="Close"
                ></button>
                <QuotationGenerator quotation_Data={editingLead} />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Tab */}
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={getTabValue}>
          <Box
            sx={{
              display: 'flex',
              overflowX: 'auto',
              width: '100%',
            }}
          >
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="secondary"
              indicatorColor="secondary"
              sx={{ display: 'flex', minWidth: 'max-content' }}
            >
              <Tab label="Open Leads" value="1" />
              <Tab label="Quotation" value="2" />
              <Tab label="Esclation" value="3" />
              <Tab label="Converted" value="4" />
              <Tab label="Cancelled" value="5" />
            </TabList>
          </Box>
          <TabPanel value="1" onClick={() => handleChangeTab(0)}>
            <div className="container">
              <div className="col-12">
                {userRole && userRole.pageList.find(page => page.pageName === "Lead Online").access[1] === 1 && (
                  <button
                    onClick={() => {
                      toggleLeadForm();
                      addAdditionalField();
                    }}
                    className="btn btn-gradient-primary mb-2"
                    type="button"
                  >
                    Create Lead
                  </button>
                )}
              </div>
              {/* collapse table */}

              <div className="row">
                <div className="col-md-4">
                  <label>From Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate1}
                    onChange={(e) => handleFromDateChange(0, e)}
                  />
                </div>
                <div className="col-md-4">
                  <label>To Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate1}
                    onChange={(e) => handleToDateChange(0, e)}
                  />
                </div>
                <div className="col-md-3">
                  <button className="btn btn-primary mt-4" onClick={() => handleSearchClick(0)}>Search date</button>
                  {searchClicked1 && (
                    <button
                      onClick={() => handleClearFilter(0)}
                      className="btn btn-sm badge-gradient-danger"
                      aria-label="Close"
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      &times;
                    </button>
                  )}
                </div>
              </div>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Quotation Status</TableCell>
                      <TableCell>Customer Name</TableCell>
                      <TableCell>Lead Source</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Pincode</TableCell>
                      <TableCell>City</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {searchClicked1 ? (
                      filteredRows1.map((row) => (
                        <Row key={row.name} row={row} />
                      ))
                    ) : (
                      rows.map((row) => {
                        const { status } = row.item;

                        if (getFollowUp && Array.isArray(getFollowUp.pipeLine)) {
                          const followUp = getFollowUp.pipeLine.find((follow) => follow.leadId === row.item._id);
                          if (followUp && followUp.leadAssignedTo === adminId && status.leadStatus === "open") {
                            return <Row key={row.name} row={row} />;
                          }
                        }

                        return null;
                      })
                    )}
                  </TableBody>

                </Table>
              </TableContainer>
            </div>
          </TabPanel>
          <TabPanel value="2" onClick={() => handleChangeTab(1)}>
            <div className="row">
              <div className="col-md-4">
                <label>From Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={fromDate2}
                  onChange={(e) => handleFromDateChange(1, e)}
                />
              </div>
              <div className="col-md-4">
                <label>To Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={toDate2}
                  onChange={(e) => handleToDateChange(1, e)}
                />
              </div>
              <div className="col-md-3">
                <button className="btn btn-primary mt-4" onClick={() => handleSearchClick(1)}>Search date</button>
                {searchClicked2 && (
                  <button
                    onClick={() => handleClearFilter(1)}
                    className="btn btn-sm badge-gradient-danger"
                    aria-label="Close"
                    style={{ marginLeft: "10px", marginTop: "30px" }}
                  >
                    &times;
                  </button>
                )}
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Quotation Status</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Lead Source</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Pincode</TableCell>
                    <TableCell>City</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchClicked2 ? (
                    filteredRows2.map((row) => (
                      <Row key={row.name} row={row} />
                    ))
                  ) : (
                    rows.map((row) => {
                      const { status } = row.item;
                      if (getFollowUp && Array.isArray(getFollowUp.pipeLine)) {
                        const followUp = getFollowUp.pipeLine.find((follow) => follow.leadId === row.item._id);
                        if (followUp && followUp.leadAssignedTo === adminId && status.leadStatus === "quotation") {
                          return <Row key={row.name} row={row} />;
                        }
                      }
                      return null;
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value="3" onClick={() => handleChangeTab(2)}>
            <div className="row">
              <div className="col-md-4">
                <label>From Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={fromDate3}
                  onChange={(e) => handleFromDateChange(2, e)}
                />
              </div>
              <div className="col-md-4">
                <label>To Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={toDate3}
                  onChange={(e) => handleToDateChange(2, e)}
                />
              </div>
              <div className="col-md-3">
                <button className="btn btn-primary mt-4" onClick={() => handleSearchClick(2)}>Search date</button>
                {searchClicked3 && (
                  <button
                    onClick={() => handleClearFilter(2)}
                    className="btn btn-sm badge-gradient-danger"
                    aria-label="Close"
                    style={{ marginLeft: "10px", marginTop: "30px" }}
                  >
                    &times;
                  </button>
                )}
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Quotation Status</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Lead Source</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Pincode</TableCell>
                    <TableCell>City</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchClicked3 ? (
                    filteredRows3.map((row) => (
                      <Row key={row.name} row={row} />
                    ))
                  ) : (
                    rows.map((row) => {
                      const { status } = row.item;

                      if (getFollowUp && Array.isArray(getFollowUp.pipeLine)) {
                        const followUp = getFollowUp.pipeLine.find((follow) => follow.leadId === row.item._id);
                        if (followUp && followUp.leadAssignedTo === adminId && status.leadStatus === "escalation") {
                          return <Row key={row.name} row={row} />;
                        }
                      }

                      return null;
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value="4" onClick={() => handleChangeTab(3)}>
            <div className="row">
              <div className="col-md-4">
                <label>From Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={fromDate4}
                  onChange={(e) => handleFromDateChange(3, e)}
                />
              </div>
              <div className="col-md-4">
                <label>To Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={toDate4}
                  onChange={(e) => handleToDateChange(3, e)}
                />
              </div>
              <div className="col-md-3">
                <button className="btn btn-primary mt-4" onClick={() => handleSearchClick(3)}>Search date</button>
                {searchClicked4 && (
                  <button
                    onClick={() => handleClearFilter(3)}
                    className="btn btn-sm badge-gradient-danger"
                    aria-label="Close"
                    style={{ marginLeft: "10px", marginTop: "30px" }}
                  >
                    &times;
                  </button>
                )}
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Quotation Status</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Lead Source</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Pincode</TableCell>
                    <TableCell>City</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchClicked4
                    ? filteredRows4.map((row) => (
                      <Row key={row.name} row={row} />
                    ))
                    : rows.map((row) => {
                      if (row.item) {
                        const { status } = row.item;
                        if (getFollowUp && Array.isArray(getFollowUp.pipeLine)) {
                          const followUp = getFollowUp.pipeLine.find(
                            (follow) => follow.leadId === row.item._id
                          );
                          if (followUp && followUp.leadAssignedTo === adminId && status.leadStatus === "order success") {
                            return <Row key={row.name} row={row} />;
                          }
                        }
                      }
                      return null;
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value="5" onClick={() => handleChangeTab(4)}>
            <div className="row">
              <div className="col-md-4">
                <label>From Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={fromDate5}
                  onChange={(e) => handleFromDateChange(4, e)}
                />
              </div>
              <div className="col-md-4">
                <label>To Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={toDate5}
                  onChange={(e) => handleToDateChange(4, e)}
                />
              </div>
              <div className="col-md-3">
                <button className="btn btn-primary mt-4" onClick={() => handleSearchClick(4)}>Search date</button>
                {searchClicked5 && (
                  <button
                    onClick={() => handleClearFilter(4)}
                    className="btn btn-sm badge-gradient-danger"
                    aria-label="Close"
                    style={{ marginLeft: "10px", marginTop: "30px" }}
                  >
                    &times;
                  </button>
                )}
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Quotation Status</TableCell>
                    <TableCell>Customer Name</TableCell>
                    <TableCell>Lead Source</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Pincode</TableCell>
                    <TableCell>City</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchClicked5
                    ? filteredRows5.map((row) => (
                      <Row key={row.name} row={row} />
                    ))
                    : rows.map((row) => {
                      if (row.item) {
                        const { status } = row.item;
                        if (getFollowUp && Array.isArray(getFollowUp.pipeLine)) {
                          const followUp = getFollowUp.pipeLine.find(
                            (follow) => follow.leadId === row.item._id
                          );
                          if (followUp && followUp.leadAssignedTo === adminId && status.leadStatus === "cancel") {
                            return <Row key={row.name} row={row} />;
                          }
                        }
                      }
                      return null;
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </TabContext>
      </Box>
    </React.Fragment>
  );
};
function createData(
  cus_name,
  lead_source,
  lead_date,
  area,
  pincode,
  quotationalHistories,
  item
) {
  return {
    cus_name,
    lead_source,
    lead_date,
    area,
    pincode,
    quotationalHistories,
    item,
  };
}

export default LeadCreationOnline;
