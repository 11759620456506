import React, { useState, useEffect, useCallback } from "react";
import api from "../Api";
import moment from "moment";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const MissingLead = () => {
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [searchClicked, setSearchClicked] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [selectedReport, setSelectedReport] = useState("FollowUp");
    const [statusFilter, setStatusFilter] = useState(["escalation", "quotation"]);
    const [getMissedLeadReport, setGetMissedLeadReport] = useState([]);
    const [getCustomerCreation, setGetCustomerCreation] = useState([]);
    const [getLeadStatus, setGetLeadStatus] = useState([]);
    const [getUser, setGetUser] = useState([]);

    useEffect(() => {
        const fetchLeadEscalationReport = async () => {
            try {
                const response = await api.get('/missedLeadReport');
                setGetMissedLeadReport(response.data);
            } catch (error) {
                //-console.error('Error fetching lead escalation:', error);
            }
        };
        fetchLeadEscalationReport();
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api.get("/user");
                setGetUser(response.data);
            } catch (error) {
                //-console.error("Error fetching User:", error);
            }
        };
        fetchUser();
    }, []);

    useEffect(() => {
        const fetchCustomerCreation = async () => {
            try {
                const response = await api.get("/customerCreation");
                setGetCustomerCreation(response.data);
            } catch (error) {
                //-console.error('Error fetching customer creation data:', error);
            }
        };
        fetchCustomerCreation();
    }, []);

    useEffect(() => {
        const fetchLeadStatus = async () => {
            try {
                const response = await api.get("/leadStatus");
                setGetLeadStatus(response.data);
            } catch (error) {
                //-console.error("Error fetching lead status:", error);
            }
        };
        fetchLeadStatus();
    }, []);

    // Handle date changes
    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    // Handle search click
    const handleSearchClick = () => {
        setSearchClicked(true);
        filterRowsByDate(fromDate, toDate);
    };

    const filterRowsByDate = (fromDate, toDate) => {
        const filtered = getMissedLeadReport.filter((row) => {
            const createdAt = moment(row.createdAt, "YYYY-MM-DD hh:mm A");
            const isBetween = createdAt.isBetween(
                moment(fromDate).startOf("day"),
                moment(toDate).endOf("day"),
                null,
                "[]"
            );

            return isBetween;
        });
        setFilteredRows(filtered);
    };

    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DDTHH:mm");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };

        setDefaultFromToDates();
    }, []);

    // Handle clear filter
    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DDTHH:mm");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        filterRowsByDate(currentDateFormatted, currentDateFormatted);
    };

    useEffect(() => {
        const determineStatusFilter = () => {
            switch (selectedReport) {
                case "FollowUp":
                    return ["open", "escalation", "quotation"];
                case "Non Converted Leads":
                    return ["cancel"];
                case "Converted":
                    return ["order success"];
                default:
                    return [];
            }
        };

        setStatusFilter(determineStatusFilter());
    }, [selectedReport]);

    const getReportFilteredRows = () => {
        if (!searchClicked) return [];

        return filteredRows.filter((row) => {
            const leadStatus = getLeadStatus.find(status => status._id === row.status)?.leadStatus;
            const statusMatches = statusFilter.includes(leadStatus);
            return statusMatches;
        });
    };

    const MissedLeadFilteredRows = getReportFilteredRows();
    //-console.log("MissedLeadFilteredRows:", MissedLeadFilteredRows);

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Missed Lead
                </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                            <span></span>Overview{" "}
                            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* Table */}
            <div className="row">
                {/* From Date */}
                <div className="col-md-3">
                    <label>From Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                </div>
                <div className="col-md-3">
                    <label>To Date</label>
                    <input
                        type="datetime-local"
                        className="form-control"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                </div>
                <div className="col-md-3 mb-2">
                    <div className="form-group">
                        {/* Form */}
                        <label htmlFor="inlineFormInputName2">Status Category</label>
                        <select
                            className="form-select form-control"
                            value={selectedReport}
                            onChange={(e) => setSelectedReport(e.target.value)}
                            required
                        >
                            <option value="FollowUp">FollowUp</option>
                            <option value="Non Converted Leads">Non Converted Leads</option>
                            <option value="Converted">Converted Leads</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-3">
                    <button
                        className="btn btn-primary mt-3"
                        onClick={handleSearchClick}
                    >
                        Search Date
                    </button>
                    {searchClicked && (
                        <button
                            onClick={handleClearFilter}
                            className="btn btn-sm badge-gradient-danger mt-3"
                            aria-label="Close"
                            style={{ marginLeft: '10px' }}
                        >
                            &times;
                        </button>
                    )}
                </div>

                {/* Table display */}
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Number</TableCell>
                                <TableCell>Customer Code</TableCell>
                                <TableCell>Customer Name</TableCell>
                                <TableCell>Mobile</TableCell>
                                <TableCell>Created By</TableCell>
                                <TableCell>Transfer To</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {MissedLeadFilteredRows.length > 0 &&
                                MissedLeadFilteredRows.map((row, index) => {
                                    if (selectedReport) {
                                        const customer = getCustomerCreation.find(
                                            (customer) => customer._id === row.name
                                        );
                                        const createdBy = getUser.find(
                                            (customer) => customer._id === row.createdBy
                                        );
                                        //-console.log("createdBy", createdBy);

                                        const transfer = row.followup.map((followup) => followup?.transferTo || '');

                                        return (
                                            <TableRow key={row._id}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{customer?.customerCode}</TableCell>
                                                <TableCell>{customer?.name}</TableCell>
                                                <TableCell>{customer?.mobile}</TableCell>
                                                <TableCell>{createdBy?.name || ''}</TableCell>
                                                {transfer.map((transferId, idx) => {
                                                    const transferCustomer = getUser.find(
                                                        (customer) => customer._id === transferId
                                                    );
                                                    return (
                                                        <TableCell key={idx}>
                                                            {transferCustomer?.name || ''}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    }

                                    return null;
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </React.Fragment>
    );
};

export default MissingLead;
