import React, { useState, useEffect, useCallback } from "react";
import api from "../Api";
import moment from "moment";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

const RepeatedCustomer = () => {
    const [data, setData] = useState({
        repeatedCustomers: [],
        liveMarketingData: [],
        leadOnlineData: [],
        gridRows: [],
    });
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);
    const [searchClicked, setSearchClicked] = useState(false);

    // Fetch data from API
    const fetchData = useCallback(async () => {
        try {
            const [repeatedResponse, liveResponse, leadResponse] = await Promise.all([
                api.get("/repeatedCustomer"),
                api.get("/liveMarketing"),
                api.get("/leadCreationData"),
            ]);

            const fetchedData = {
                repeatedCustomers: repeatedResponse.data,
                liveMarketingData: liveResponse.data,
                leadOnlineData: leadResponse.data,
                gridRows: [],
            };

            setData(fetchedData);

        } catch (error) {
            //-console.error("Error fetching data:", error);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const setDefaultFromToDates = () => {
            const currentDateFormatted = moment().format("YYYY-MM-DDTHH:mm");
            setFromDate(currentDateFormatted);
            setToDate(currentDateFormatted);
        };

        setDefaultFromToDates();
    }, []);

    // Filter rows based on date range
    const filterRowsByDate = useCallback(
        (data, start, end) => {
            const filtered = data.gridRows.filter((row) => {
                const createdAt = moment(row.createdAt, "YYYY-MM-DD hh:mm A");
                return createdAt.isBetween(
                    moment(start).startOf("day"),
                    moment(end).endOf("day"),
                    null,
                    "[]"
                );
            });
            setFilteredRows(filtered);
        },
        []
    );

    // Create grid rows from combined data
    useEffect(() => {
        const combinedData = [...data.liveMarketingData, ...data.leadOnlineData];
        const uniqueCustomers = new Map();

        data.repeatedCustomers.forEach((customer) => {
            const relevantItems = combinedData.filter(
                (item) => item.name?._id === customer.name
            );

            relevantItems.forEach((item) => {
                const uniqueKey = `${item.name?.name}_${item.altermobile || "N/A"}`;

                if (!uniqueCustomers.has(uniqueKey)) {
                    uniqueCustomers.set(uniqueKey, {
                        ...item,
                        frequency: customer.frequency,
                    });
                }
            });
        });

        const tempRows = Array.from(uniqueCustomers.values()).map((item) =>
            createData(item.name?.name || "", item, item.createdAt)
        );

        setData((prevData) => ({ ...prevData, gridRows: tempRows }));
    }, [data.liveMarketingData, data.leadOnlineData, data.repeatedCustomers]);

    // Handle date changes
    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    // Handle search click
    const handleSearchClick = () => {
        setSearchClicked(true);
        filterRowsByDate(data, fromDate, toDate);
    };

    // Handle clear filter
    const handleClearFilter = () => {
        const currentDateFormatted = moment().format("YYYY-MM-DDTHH:mm");
        setFromDate(currentDateFormatted);
        setToDate(currentDateFormatted);
        setSearchClicked(false);
        filterRowsByDate(data, currentDateFormatted, currentDateFormatted);
    };

    // Create data helper function
    const createData = (cus_name, item, createdAt) => ({
        cus_name,
        item,
        createdAt,
    });

    useEffect(() => {
        if (searchClicked) {
            filterRowsByDate(data, fromDate, toDate);
        }
    }, [searchClicked, data, fromDate, toDate, filterRowsByDate]);

    return (
        <React.Fragment>
            <div className="page-header">
                <h3 className="page-title">
                    <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <i className="fa fa-bullseye"></i>
                    </span>{" "}
                    Repeated Customers
                </h3>
                <nav aria-label="breadcrumb">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                            <span></span>Overview{" "}
                            <i className="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* Table */}
            <div className="container">
                <div className="row">
                    {/* From Date */}
                    <div className="col-md-3">
                        <label>From Date</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            name="fromDate"
                            value={fromDate}
                            onChange={handleFromDateChange}
                        />
                    </div>
                    <div className="col-md-3">
                        <label>To Date</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            name="toDate"
                            value={toDate}
                            onChange={handleToDateChange}
                        />
                    </div>
                    <div className="col-md-3">
                        <button className="btn btn-primary mt-4" onClick={handleSearchClick}>
                            Search Date
                        </button>
                        {searchClicked && (
                            <button
                                onClick={handleClearFilter}
                                className="btn btn-sm badge-gradient-danger"
                                aria-label="Close"
                                style={{ marginLeft: "10px", marginTop: "30px" }}
                            >
                                &times;
                            </button>
                        )}
                    </div>
                </div>
                <TableContainer component={Paper} className="mt-4">
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Number</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Mobile</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Lead Count</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{row.cus_name}</TableCell>
                                    <TableCell>{row.item.name.mobile || "N/A"}</TableCell>
                                    <TableCell>{row.item.name.email || "N/A"}</TableCell>
                                    <TableCell>{row.item.frequency || "N/A"}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </React.Fragment>
    );
};

export default RepeatedCustomer;
